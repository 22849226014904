import React, { useState, useRef, useEffect } from "react";
import useReactRouter from "use-react-router";
import Image from "react-bootstrap/Image";
import ImageLogo from "../../../image/profile.png";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import axios from "axios";
import * as Yup from "yup";

import {
  faFileAlt,
  faUser,
  faUserGraduate,
} from "@fortawesome/free-solid-svg-icons";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { PRESINGED_URL, CREATE_USER } from "../apollo";
import Swal from "sweetalert2";

import {
  TITLE_HEADER,
  HEADER,
  BODY_DISCOUNT_SHOPDETAIL,
} from "../../../consts";
import { USER_LIST } from "../../../consts/router";
import { Formik } from "formik";
import { ErrorValidationBox } from "../../../common/ErrorValidationBox";
import { DialerSipRounded, ThreeSixtyTwoTone } from "@material-ui/icons";
import { disableFragmentWarnings } from "@apollo/client";
export default function UserAdd() {
  const { history, location, match } = useReactRouter();
  const [selectedRole, setSelectedRole] = useState("ADMIN");
  const [CreateUser, setCreateUser] = useMutation(CREATE_USER);
  const [uploadPhoto, setUploadPhoto] = useMutation(PRESINGED_URL);
  const [genderData, setGenderData] = useState("NO_GENDER");
  // history
  const _userList = () => {
    history.push(`${USER_LIST}/limit/20/skip/0`);
  };
  const _onChangeRole = (e) => {
    setSelectedRole(e.target.value);
  };
  // year
  let newYear = [];
  let year = new Date();
  for (let i = 1950; i < year.getFullYear() + 1; i++) {
    newYear.push(i);
  }
  //month
  let newMonth = [];
  for (let i = 1; i < 13; i++) {
    newMonth.push(i);
  }
  //day
  let newDay = [];
  for (let i = 1; i < 32; i++) {
    newDay.push(i);
  }

  const _createUser = async (data) => {
    const resCreateUser = await CreateUser({ variables: { data } })
    if (resCreateUser?.data?.createUser?.id) {
      Swal.fire({
        icon: "success",
        title: "ການບັນທຶກສຳເລັດ",
        showConfirmButton: false,
        timer: 1500,
      }).then(function () {
        history.push(`${USER_LIST}/limit/20/skip/0`);
        window.location.reload(true);
      })
    } else {
      Swal.fire({
        icon: "failed",
        title: "ການບັນທຶກບໍ່ສຳເລັດ",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  // get gender
  const handleChangeGender = async (gender) => {
    setGenderData(gender);
  };
  // upload pic
  const [resPhoto, setResPhoto] = useState();
  const [file, setFile] = useState("");
  const [imageLoading, setImageLoading] = useState();
  const [getNewRole, setGetNewRole] = useState("");
  const handleUpload = async (event) => {
    setImageLoading("");
    try {
      setFile(event.target.files[0]);
      let formData = new FormData();
      let fileData = event.target.files[0].name;
      let fileDataBoole = event.target.files[0];
      const resUploadPhoto = await uploadPhoto({
        variables: { fileName: fileData },
      });
      let afterUpload = await axios({
        method: "put",
        url: resUploadPhoto.data.preSignedUrl.url,
        data: fileDataBoole,
        headers: {
          "Content-Type": " file/*; image/*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      });

      setResPhoto(afterUpload.config.data.name);
    } catch (error) { }
  };
  // lung jak upload leo pic ja ma so u nee
  const ImageThumb = ({ image }) => {
    return (
      <img
        src={URL.createObjectURL(image)}
        alt={image.name}
        style={{
          height: 300,
          width: 300,
          borderRadius: "5%",
        }}
      />
    );
  };
  const CounselingSchema = Yup.object().shape({
    birthday: Yup.string().required("ກະລຸນາປ້ອນ!"),
    workingStatus: Yup.string().required("ກະລຸນາເລືອກ!"),
    educationLevel: Yup.string().required("ກະລຸນາເລືອກ!"),
    phone: Yup.string().required("ກະລຸນາປ້ອນ!"),
    firstName: Yup.string().required("ກະລຸນາປ້ອນ!"),
    province: Yup.string().required("ກະລຸນາປ້ອນ!"),
  });
  return (
    <div style={TITLE_HEADER}>
      <h6 style={{ marginLeft: 27, marginBottom: 25 }}>
        <a href="" onClick={() => _userList()} style={{ color: "#766AF0" }}>
          ຜູ້ໃຊ້ບໍລິການທັງໝົດ »{" "}
        </a>
        ເພີ່ມຜູ້ໃຊ້ບໍລິການ
      </h6>
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: 8,
          marginLeft: 25,
          marginRight: 25,
        }}
      >
        <div style={{ padding: 20 }}>
          <Formik
            initialValues={{}}
            validationSchema={CounselingSchema}
            onSubmit={(values, { setSubmitting }) => {
              let RamdomS = Math.floor(Math.random() * 9465465447205669157);
              let rendomUserId =
                values.birthday +
                RamdomS +
                "/" +
                values.firstName;
              let data = {
                userId: String(rendomUserId),
                role: "USER",
                firstName: values.firstName,
                lastName: values.lastName,
                phone: String(values.phone),
                birthday: values.birthday,
                gender: genderData,
                tribe: values.tribe,
                address: {
                  village: values.village,
                  district: values.district,
                  province: values.province,
                  detail: values.detaildAddress,
                  note: values.noteAddress,
                },
                province: values.province,
                provinceBorn: values.provinceBorn,
                image: resPhoto,
                workingStatus: values.workingStatus,
                accessibility: values.accessibility,
                religion: values.religion,
                educationLevel: values.educationLevel,
                branch: values.branch,
                detail: values.detail,
                note: values.note,
              };
              _createUser(data);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div style={{ padding: 5, marginLeft: 5, fontSize: "18px" }}>
                  <a style={{ marginRight: 25 }}>ເພີ່ມຜູ້ໃຊ້ບໍລິການ</a>
                </div>
                <div className="row" style={{ padding: 20 }}>
                  <div className="col-md-4">
                    <center>
                      <input
                        type="file"
                        id="file-upload"
                        onChange={handleUpload}
                        hidden
                      />
                      <label for="file-upload">
                        <div
                          style={{
                            backgroundColor: "#E4E4E4E4",
                            height: 300,
                            width: 300,
                            borderRadius: "5%",
                            cursor: "pointer",
                            display: "flex",
                          }}
                        >
                          {file ? (
                            <ImageThumb image={file} />
                          ) : (
                              <div
                                style={{
                                  display: "flex",
                                  height: 300,
                                  width: 300,
                                  borderRadius: "5%",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <p
                                  style={{
                                    color: "#fff",
                                    fontSize: 80,
                                    fontWeight: "bold",
                                  }}
                                >
                                  +
                              </p>
                              </div>
                            )}
                        </div>
                      </label>
                      {/* progass */}
                      {imageLoading ? (
                        <div class="progress" style={{ height: 20 }}>
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style={{
                              width: `${imageLoading}%`,
                              backgroundColor: "#816aae",
                            }}
                            aria-valuenow={imageLoading}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            {imageLoading}%
                          </div>
                        </div>
                      ) : (
                          <div style={{ height: 20 }} />
                        )}
                    </center>
                  </div>
                  <div className="col-md-8">
                    <div style={{ color: "#766AF0" }}>
                      <FontAwesomeIcon
                        icon={faUser}
                        style={{ marginRight: 10 }}
                      />
                      ຂໍ້ມູນສ່ວນຕົວ
                    </div>
                    <div class="form-row">
                      <div class="col">
                        <label>
                          ຊື່ <a style={{ color: "red" }}>*</a>
                        </label>
                        <div style={{ height: 10 }}></div>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="First name"
                          name="firstName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.firstName}
                        />
                        <ErrorValidationBox
                          errors={errors}
                          fieldName={"firstName"}
                        />
                      </div>
                      <div class="col">
                        <label>ນາມສະກຸນ</label>
                        <div style={{ height: 10 }}></div>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Last name"
                          name="lastName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.lastName}
                        />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col">
                        <label>
                          ເບີໂທ <a style={{ color: "red" }}>*</a>
                        </label>
                        <div style={{ height: 10 }}></div>
                        <input
                          type="number"
                          class="form-control"
                          placeholder="-"
                          name="phone"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone}
                        />
                        <ErrorValidationBox
                          errors={errors}
                          fieldName={"phone"}
                        />
                      </div>
                    </div>
                    <label>
                      ວັນເດືອນປີເກີດ <a style={{ color: "red" }}>*</a>
                    </label>
                    <div style={{ height: 10 }}></div>
                    <div class="form-row">
                      <div class="col">
                        <div class="form-group mb-12">
                          <input
                            type="date"
                            class="form-control col-12"
                            style={{ marginRight: 20 }}
                            type="date"
                            name="birthday"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.birthday}
                          />
                        </div>
                        <ErrorValidationBox errors={errors} fieldName={"birthday"} />
                      </div>
                    </div>
                    <label>ເພດ</label>
                    <div style={{ height: 10 }}></div>
                    <div class="form-row">
                      <div class="col">
                        <div class="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            id="MALE"
                            name="gender"
                            class="custom-control-input"
                            onChange={() => handleChangeGender("MALE")}
                          />
                          <label class="custom-control-label" for="MALE">
                            ຊາຍ
                          </label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            id="FEMALE"
                            name="gender"
                            class="custom-control-input"
                            onChange={() => handleChangeGender("FEMALE")}
                          />
                          <label class="custom-control-label" for="FEMALE">
                            ຍິງ
                          </label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            id="OTHER"
                            name="gender"
                            class="custom-control-input"
                            onChange={() => handleChangeGender("OTHER")}
                          />
                          <label class="custom-control-label" for="OTHER">
                            ເພດອື່ນ
                          </label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            id="NO_GENDER"
                            name="gender"
                            class="custom-control-input"
                            onChange={() => handleChangeGender("NO_GENDER")}
                            defaultChecked
                          />
                          <label class="custom-control-label" for="NO_GENDER">
                            ບໍ່ລະບຸ
                          </label>
                        </div>
                      </div>
                    </div>
                    <div style={{ height: 10 }}></div>
                    <div class="form-row">
                      <div class="col">
                        <label>
                          ສະຖານະການເຮັດວຽກ <a style={{ color: "red" }}>*</a>
                        </label>
                        <div style={{ height: 10 }}></div>
                        <select
                          class="custom-select"
                          id="inputGroupSelect02"
                          name="workingStatus"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.workingStatus}
                        >
                          <option disabled={true} selected={true}>
                            ກາລຸນາເລືອກ...
                          </option>
                          <option value="WORK">ເຮັດວຽກ</option>
                          <option value="STUDYING">ຮຽນ</option>
                          <option value="UNEMPLOYED">ຫ່ວາງງານ</option>
                          <option value="OTHER">ອື່ນໆ</option>
                        </select>
                        <ErrorValidationBox
                          errors={errors}
                          fieldName={"workingStatus"}
                        />
                      </div>
                      <div class="col">
                        <label>ຄວາມບົກຜ່ອງທາງຮ່າງກາຍ(ພິການ)</label>
                        <div style={{ height: 10 }}></div>
                        <input
                          type="text"
                          class="form-control"
                          name="accessibility"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.accessibility}
                          placeholder="ປ້ອນຄວາມບົກຜ່ອງ..."
                        />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col">
                        <label>ຊົນເຜົ່າ</label>
                        <div style={{ height: 10 }}></div>
                        <input
                          type="text"
                          class="form-control"
                          name="tribe"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.tribe}
                          placeholder="ປ້ອນຊົນເຜົ່າ"
                        />
                      </div>
                      <div class="col">
                        <label>ສາດສະໜາ</label>
                        <div style={{ height: 10 }}></div>
                        <input
                          type="text"
                          class="form-control"
                          name="religion"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.religion}
                          placeholder="ສາດສະໜາ"
                        />
                      </div>
                    </div>
                    <label>ທີ່ຢູ່ປະຈຸບັນ</label>
                    <div class="form-row">
                      <div class="col-6">
                        <div style={{ height: 10 }}></div>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="ບ້ານຢູ່ປະຈຸບັນ"
                          name="village"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.village}
                        />
                      </div>
                      <div class="col-6">
                        <div style={{ height: 10 }}></div>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="ເມືອງຢູ່ປະຈຸບັນ"
                          name="district"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.district}
                        />
                      </div>
                    </div>
                    <label>ແຂວງປະຈຸບັນ <a style={{ color: "red" }}>*</a></label>
                    <div class="form-row">
                      <div class="col-12">
                        <div style={{ height: 10 }}></div>
                        <select
                          id="inputState"
                          className="form-control "
                          style={{ width: "100%" }}
                          type="text"
                          name="province"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.province}
                        >
                          <option value="-" disabled={true} selected={true}>ເລືອກແຂວງຢູ່ປະຈຸບັນ</option>
                          <option value="Phongsali">ຜົ້ງສາລີ</option>
                          <option value="LuangNamtha">ຫຼວງນ້ຳທາ</option>
                          <option value="Huoaphan">ຫົວພັນ</option>
                          <option value="Borkeo">ບໍ່ແກ້ວ</option>
                          <option value="Oudomxay">ອຸດົມໄຊ</option>
                          <option value="Sayaboury">ໄຊຍະບູລີ</option>
                          <option value="Xiengkhouang">ຊຽງຂວາງ</option>
                          <option value="LuangPrabang">ຫຼວງພະບາງ</option>
                          <option value="Vientiane">ວຽງຈັນ</option>
                          <option value="VientianeCapital">
                            ນະຄອນຫຼວງວຽງຈັນ
                          </option>
                          <option value="Borikhamsay">ບໍລິຄຳໄຊ</option>
                          <option value="Khammuoan">ຄຳມ່ວນ</option>
                          <option value="Savannakhet">ສະຫວັນນະເຂດ</option>
                          <option value="Salavanh">ສາລະວັນ</option>
                          <option value="Sekong">ເຊກອງ</option>
                          <option value="Attapue">ອັດຕະປື</option>
                          <option value="Champasak">ຈຳປາສັກ</option>
                          <option value="Saisomboun">ໄຊສົມບູນ</option>
                        </select>
                        <ErrorValidationBox
                          errors={errors}
                          fieldName={"province"}
                        />
                      </div>
                      <label style={{ marginLeft: 10 }}>ແຂວງເກີດ  </label>
                      <div class="col-12">
                        <div style={{ height: 10 }}></div>
                        <select
                          id="provinceBorn"
                          className="form-control "
                          style={{ width: "100%" }}
                          type="text"
                          name="provinceBorn"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.provinceBorn}
                        >
                          <option value="-" disabled={true} selected={true}>ເລືອກແຂວງເກີດ</option>
                          <option value="Phongsali">ຜົ້ງສາລີ</option>
                          <option value="LuangNamtha">ຫຼວງນ້ຳທາ</option>
                          <option value="Huoaphan">ຫົວພັນ</option>
                          <option value="Borkeo">ບໍ່ແກ້ວ</option>
                          <option value="Oudomxay">ອຸດົມໄຊ</option>
                          <option value="Sayaboury">ໄຊຍະບູລີ</option>
                          <option value="Xiengkhouang">ຊຽງຂວາງ</option>
                          <option value="LuangPrabang">ຫຼວງພະບາງ</option>
                          <option value="Vientiane">ວຽງຈັນ</option>
                          <option value="VientianeCapital">
                            ນະຄອນຫຼວງວຽງຈັນ
                          </option>
                          <option value="Borikhamsay">ບໍລິຄຳໄຊ</option>
                          <option value="Khammuoan">ຄຳມ່ວນ</option>
                          <option value="Savannakhet">ສະຫວັນນະເຂດ</option>
                          <option value="Salavanh">ສາລະວັນ</option>
                          <option value="Sekong">ເຊກອງ</option>
                          <option value="Attapue">ອັດຕະປື</option>
                          <option value="Champasak">ຈຳປາສັກ</option>
                          <option value="Saisomboun">ໄຊສົມບູນ</option>
                        </select>
                      </div>
                    </div>
                    <div style={{ height: 40 }}></div>
                    <div style={{ color: "#766AF0" }}>
                      <FontAwesomeIcon
                        icon={faUserGraduate}
                        style={{ marginRight: 10 }}
                      />
                      ຂໍ້ມູນການສຶກສາ
                    </div>
                    <div style={{ height: 10 }}></div>
                    <div class="form-row">
                      <div class="col-6">
                        <label>
                          ລະດັບການສືກສາ <a style={{ color: "red" }}>*</a>
                        </label>
                        <div style={{ height: 10 }}></div>
                        <select
                          class="custom-select"
                          id="inputGroupSelect02"
                          name="educationLevel"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.educationLevel}
                        >
                          <option disabled={true} selected={true}>
                            ກາລຸນາເລືອກ...
                          </option>
                          <option value="PRIMARY">ປະຖົມ</option>
                          <option value="LOWER_SECONDARY">ມັດທະຍົມຕົ້ນ</option>
                          <option value="UPPER_SECONDARY">ມັດທະຍົມປາຍ</option>
                          <option value="FIRST_FLOOR">ຊັ້ນຕົ້ນ</option>
                          <option value="MIDDLE_CLASS">ຊັ້ນກາງ</option>
                          <option value="HIGH_CLASS">ຊັ້ນສູງ</option>
                          <option value="BACHELOR">ປະລິນຍາຕີ</option>
                          <option value="MASTER">ປະລິນຍາໂທ</option>
                          <option value="NOT_STUDYING">ບໍ່ໄດ້ຮຽນ</option>
                        </select>
                        <ErrorValidationBox
                          errors={errors}
                          fieldName={"educationLevel"}
                        />
                      </div>
                      <div class="col-6">
                        <label>ສາຂາຮຽນ</label>
                        <div style={{ height: 10 }}></div>
                        <input
                          type="text"
                          class="form-control"
                          name="branch"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.branch}
                          placeholder="ສາຂາຮຽນ"
                        />
                      </div>
                    </div>
                    <div style={{ height: 40 }}></div>
                    <div style={{ color: "#766AF0" }}>
                      <FontAwesomeIcon
                        icon={faFileAlt}
                        style={{ marginRight: 10 }}
                      />
                      ຂໍ້ມູນເພີ່ມເຕີມ
                    </div>
                    <div style={{ height: 10 }}></div>
                    <div class="form-row">
                      <div class="col-12">
                        <label>ຄຳອະທິບາຍ</label>
                        <div style={{ height: 10 }}></div>
                        <textarea
                          type="textarea"
                          class="form-control"
                          name="detail"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.detail}
                        />
                      </div>
                      <div class="col-12">
                        <label>ໝາຍເຫດ</label>
                        <div style={{ height: 10 }}></div>
                        <textarea
                          type="textarea"
                          class="form-control"
                          name="note"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.note}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div
                    className="col-md-12"
                    style={{
                      textAlign: "right",
                      paddingRight: 30,
                      marginRight: 30,
                    }}
                  >
                    <button
                      type="button"
                      class="btn btn-outline-danger col-md-2"
                      style={{
                        textAlign: "center",
                        letterSpacing: 0,
                        opacity: 1,
                        paddingRight: 10,
                        marginRight: 10,
                      }}
                      onClick={() => _userList()}
                    >
                      {" "}
                      ຍົກເລິກ
                    </button>
                    <button
                      type="submit"
                      class="btn btn-secondary col-md-2"
                      style={{
                        background:
                          "transparent linear-gradient(269deg, #766AF0 0%, #978EF3 100%) 0% 0% no-repeat padding-box",
                        textAlign: "center",
                        letterSpacing: 0,
                        opacity: 1,
                        paddingRight: 5,
                        marginRight: 5,
                      }}
                    >
                      ຢືນຢັນ
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
