import { gql } from "apollo-boost"
export const UPDATE_USER = gql`
  mutation UpdateConsultingCategory(
    $data: UserWhereInput
    $where: UserWhereInputId!
  ) {
    updateUser(data: $data, where: $where) {
      id
    }
  }
`
export const UPDATE_MAINCONSOULTING = gql`
  mutation UpdateMainConsouting(
    $data: MainConsoutingWhereInput
    $where: MainConsoutingWhereInputId!
  ) {
    updateMainConsouting(data: $data, where: $where) {
      id
    }
  }
`
export const USERS = gql`
  query Users($where: UserWhereInput) {
    users(where: $where) {
      total
      data {
        id
        firstName
        lastName
        workingStatus
        phone
        note
        image
        createdAt
      }
    }
  }
`
export const USER = gql`
  query user($where: UserWhereInputId!) {
    user(where: $where) {
      id
      userId
      firstName
      lastName
      phone
      role
      birthday
      gender
      religion
      tribe
      image
      address {
        province
        district
        village
      }
      canCounseling {
        id
      }
      accessibility
      educationLevel
      branch
      workingStatus
      company
      detail
      note
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`
export const CREATE_USER = gql`
  mutation CreateUser($data: UserWhereInput) {
    createUser(data: $data) {
      id
    }
  }
`
export const PRESINGED_URL = gql`
  mutation PreSignedUrl($fileName: String) {
    preSignedUrl(fileName: $fileName) {
      url
    }
  }
`
export const DELETE_USER = gql`
  mutation DeleteUser($where: UserWhereInputId!) {
    deleteUser(where: $where) {
      id
    }
  }
`

export const MAINCONSUTING = gql`
  query MainConsoutings(
    $where: MainConsoutingWhereInput
    $skip: Int
    $limit: Int
  ) {
    mainConsoutings(where: $where, skip: $skip, limit: $limit) {
      data {
        id
        consultingCategoryId
        userId
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
        note
      }
    }
  }
`
export const CONSULTINGS = gql`
  query consultings($where: ConsultingWhereInput, $skip: Int, $limit: Int) {
    consultings(where: $where, skip: $skip, limit: $limit) {
      total
      data {
        id
        userId
        firstResponderId
        mentorId
        consultingCategoryId
        mainConsultingId
        status
        appointmentDate
        appointmentAddress
        followUp
        detail
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`
export const CONSULTING_CATEGORYS = gql`
  query ConsultingCategorys($where: ConsultingCategorysWhereInput, $skip: Int) {
    consultingCategorys(where: $where, skip: $skip) {
      total
      data {
        id
        name
        detail
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`
export const USERS_PRICE = gql`
  query Users_Price($where: UserPriceInput) {
    users_Price(where: $where) {
      AllTotal
        MaleTotal
        FeMaleTotal
        OtherTotal
        No_genderTotal
    }
  }
`
export const FLOWCHART_CONSULTINGS = gql`
  query Flowchart_consultings($where: FlowchatConsultings) {
    flowchart_consultings(where: $where) {
      _id
      count
    }
  }
`
export const PROVINE_FLOWCHART = gql`
  query Province_Flowchart($where: ProvinceFlowchartInput) {
    province_Flowchart(where: $where) {
      _id
      count
    }
  }
`
export const SATISFACTION = gql`
  query Satisfaction {
    satisfaction {
      countAll
      mainStatus{
        _id
      count
      }
    }
  }
`
export const GENDER_FLOWCHART = gql`
  query Consulting_and_Gender_Flowchart($gender: Gender, $where: ConsultingAndGenderFlowchat){
    consulting_and_Gender_Flowchart(gender:$gender, where: $where){
  	province
    consultingCategoryId
    count
    }
  }
`
export const AGE_FLOWCHART = gql`
  query Consulting_and_Age_Flowchart($age:Int, $where: ConsultingAndAgeFlowchartInput){
    consulting_and_Age_Flowchart(age:$age, where: $where){
     count
    consultingCategoryId
    province
    }
  }
`