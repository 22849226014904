import { gql } from "apollo-boost";
export const PRESINGED_URL = gql`
mutation PreSignedUrl($fileName: String){
  preSignedUrl(fileName: $fileName){
    url
    }
  }
`;
export const CREATE_COMPANY = gql`
mutation CreateCompany($data: CompanyWhereInput){
  createCompany(data: $data){
    id
    }
  }
`;
export const COMPANYS = gql`
query Companys(
  $where: CompanyWhereInput
){
  companys(where:$where){
    total
    data{
      id
      companyName
      image
      createdBy
      }
    }
  }
`;
export const COMPANY = gql`
query Company(
  $where: CompanyWhereInputId!
){
  company(where:$where){
    id
    companyName
    companyType
    affiliation
    addressCompany{
      province
      detail
      village
      district
      detail
    }
		contactChannel{
      phone
      name
      area
      email
      emailCompany
    }
    webSiteLink
    scholarships
    interns
    recrui
    image
    phoneOffice
    volunteers
    createdAt
    createdBy
    updatedAt
    updatedBy
    note
    }
  }
`;
export const USERS = gql`
query Users(
  $where: UserWhereInput
){
  users(where:$where){
    data{
      id
      firstName
      lastName
      }
    }
  }
`;
export const DELETE_COMPANY = gql`
mutation DeleteCompany($where: CompanyWhereInputId!){
  deleteCompany(where: $where){
    id
    }
  }
`;
export const UPDATE_COMPANY = gql`
mutation UpdateCompany(
  $data: CompanyWhereInput 
  $where: ConsultingWhereInputId!
  ){
    updateCompany(
      data: $data
     where: $where
     ){
    id
    }
  }
`;