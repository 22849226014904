import { gql } from "apollo-boost";
export const CREATE_CONSULTING_CATEGORYS = gql`
  mutation CreateConsultingCategory($data: ConsultingCategoryInput) {
    createConsultingCategory(data: $data) {
      id
      name
      detail
    }
  }
`;
export const DELETE_CONSULTING_CATEGORYS = gql`
  mutation DeleteConsultingCategory($where: ConsultingCategoryWhereUpdate!) {
    deleteConsultingCategory(where: $where) {
      id
      name
    }
  }
`;
export const UPDATE_CONSULTING_CATEGORYS = gql`
  mutation UpdateConsultingCategory(
    $data: ConsultingCategoryInput
    $where: ConsultingCategoryWhereUpdate!
  ) {
    updateConsultingCategory(data: $data, where: $where) {
      id
      name
    }
  }
`;
export const CONSULTING_CATEGORYS = gql`
  query ConsultingCategorys($where: ConsultingCategorysWhereInput, $skip: Int) {
    consultingCategorys(where: $where, skip: $skip) {
      total
      data {
        id
        name
        image
        detail
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const USERS = gql`
  query Users($where: UserWhereInput) {
    users(where: $where) {
      data {
        id
        firstName
        lastName
      }
    }
  }
`;
export const CONSULTING_CATEGORY = gql`
  query ConsultingCategory($where: ConsultingCategoryWhereInput!) {
    consultingCategory(where: $where) {
      id
      name
      detail
      image
      note
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const PRESINGED_URL = gql`
  mutation PreSignedUrl($fileName: String) {
    preSignedUrl(fileName: $fileName) {
      url
    }
  }
`;
