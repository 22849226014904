// ============================ STELLA 	KEY_USER ==================================
export const USER_KEY = "STELLA_ADMIN"
export const URL_FOR_SHOW_PHOTO =
  "https://stella-lailaolab-bucket.s3.amazonaws.com/images/"
// ============================ STELLA ==================================
export const BODY = {
  width: "95%",
  minHeight: "95%",
  backgroundColor: "white",
  marginRight: 32,
  marginLeft: 32,
  marginTop: 50,
  paddingLeft: 32,
  paddingTop: 50,
  paddingRight: 32,
  paddingBottom: 40
}
export const BODY_DISCOUNT_SHOPDETAIL = {
  minHeight: "100%",
  backgroundColor: "white",
  marginLeft: 30,
  width: "95%",
  padding: "2px",
  Opacity: "5px"
}
export const TITLE_HEADER = {
  paddingLeft: 50,
  paddingRight: 40,
  paddingTop: 25,
  width: "100%",
  height: "auto",
  margin: 25
}
export const HEADER = {
  color: "#5C5C5C",
  backgroundColor: "#F9F9F9",
  height: 60,
  width: "100%",
  paddingTop: 30,
  paddingLeft: 35,
  fontSize: 16
}
export const BODY11 = {
  width: "97%",
  minHeight: "80vh",
  backgroundColor: "white",
  marginRight: 32,
  paddingRight: 32,
  paddingBottom: 40
}
export const BODY_DSTAFF_DETAIL = {
  minHeight: "auto",
  backgroundColor: "white",
  marginLeft: 30
}
