import React, { useState, useRef, useEffect } from "react";
import useReactRouter from "use-react-router";
import Image from "react-bootstrap/Image";
import ImageLogo from "../../../image/profile.png";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faCheckCircle,
  faEdit,
  faBuilding,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import {
  CONSULTING_CATEGORY,
  USERS,
  DELETE_CONSULTING_CATEGORYS,
} from "../apollo";
import {
  TITLE_HEADER,
  HEADER,
  BODY_DISCOUNT_SHOPDETAIL,
  URL_FOR_SHOW_PHOTO,
} from "../../../consts";
import {
  CONSULTING_CATAGORY_DETAIL,
  CONSULTING_CATAGORY_EDIT,
  CONSULTING_CATAGORY_LIST,
  //  STORE
} from "../../../consts/router";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));

export default function CatagoryDetial() {
  const { history, location, match } = useReactRouter();

  const _id = match?.params?.id ?? "";

  const { loading, error, data } = useQuery(CONSULTING_CATEGORY, {
    variables: { where: { id: _id } },
  });
  const {
    loading: dataUserLoading,
    error: dataUserError,
    data: dataUser,
  } = useQuery(USERS);
  const [deleteCategory, setDeleteCategory] = useMutation(
    DELETE_CONSULTING_CATEGORYS
  );
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // history
  const _CatagoryList = () => {
    history.push(`${CONSULTING_CATAGORY_LIST}`);
  };
  const _CatagoryEdit = (id) => {
    // history.push(`${CONSULTING_CATAGORY_EDIT}/${id}`, id);
    history.push({
      pathname: `${CONSULTING_CATAGORY_EDIT}/${id}`,
      state: { data: location?.state?.data, id },
    });
  };
  const _renderName = (e) => {
    let res = dataUser?.users?.data?.filter((x) => x.id === e);
    if (res?.length > 0) return res[0]?.firstName + " " + res[0]?.lastName;
  };
  // confrime delete consulting category
  const _confrimeDelete = async () => {
    await deleteCategory({ variables: { where: { id: location.state } } }).then(
      Swal.fire({
        icon: "success",
        title: "ການລຶບສຳເລັດ",
        showConfirmButton: false,
        timer: 1500,
      }).then(function () {
        history.push(`${CONSULTING_CATAGORY_LIST}`);
        window.location.reload(true);
      })
    );
  };

  const _onGoBackIds = () => {
    let currentId = match?.params?.id;
    let allIds = location?.state?.data;
    let currentIndexInArray = allIds.indexOf(currentId);
    if (currentIndexInArray > 0) {
      let nextConsultingId = allIds[currentIndexInArray - 1];
      history.push({
        pathname: `${CONSULTING_CATAGORY_DETAIL}/${nextConsultingId}`,
        state: { data: allIds }
      });
    } else {
      let nextId = allIds.length - 1;
      history.push({
        pathname: `${CONSULTING_CATAGORY_DETAIL}/${allIds[nextId]}`,
        state: { data: allIds }
      });
    }
  }

  const _onGoNextIds = () => {
    let currentId = match?.params?.id;
    let allIds = location?.state?.data;
    let currentIndexInArray = allIds.indexOf(currentId);
    if ((currentIndexInArray + 1) < allIds.length) {
      let nextConsultingId = allIds[currentIndexInArray + 1];
      history.push({
        pathname: `${CONSULTING_CATAGORY_DETAIL}/${nextConsultingId}`,
        state: { data: allIds }
      });
    } else {
      let nextId = 0;
      history.push({
        pathname: `${CONSULTING_CATAGORY_DETAIL}/${allIds[nextId]}`,
        state: { data: allIds }
      });
    }
  }

  //  =========>>>>>>>>>>>>
  return (
    <div style={{ width: "100%", height: "100%" }}>
      {/* Next and Back b uttons are over here */}
      <div style={{
        position: 'fixed',
        left: 100,
        zIndex: 1,
        top: "50%",
        display: location?.state?.data ? 'flex' : 'none'
      }}>
        <button onClick={() => _onGoBackIds()} className="btn btn-outline-primary  btn-stella" style={{
          borderRadius: 50,
          width: 50,
          height: 50
        }}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
      </div>
      <div style={{
        position: 'fixed',
        right: 45,
        zIndex: 1,
        top: "50%",
        display: location?.state?.data ? 'flex' : 'none'

      }}>
        <button onClick={() => _onGoNextIds()} className="btn btn-outline-primary btn-stella" style={{
          borderRadius: 50,
          width: 50,
          height: 50
        }}>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
      <h6 style={{ width: "100%", height: "100%" }}>
        <div
          className="row"
          style={{
            marginLeft: 95,
            paddingRight: 42,
            marginTop: 20,
            paddingTop: 20,
          }}
        >
          <div className="col-6">
            <a
              href=""
              onClick={() => _CatagoryList()}
              style={{ color: "#766AF0" }}
            >
              {" "}
              ໝວດການປືກສາ |{" "}
              <i class="fa fa-users" aria-hidden="true">
                {" "}
                ລາຍຊື່ ໝວດການປືກສາ »{" "}
              </i>
            </a>
            ຂໍ້ມູນ ໝວດການປືກສາ
          </div>
          <div className="col-6" style={{ textAlign: "right" }}>
            <button
              type="button"
              class="btn btn-outline-danger col-md-3 dlt"
              onClick={handleShow}
              style={{ textAlign: "center" }}
            >
              <FontAwesomeIcon icon={faTrash} style={{ marginRight: 25 }} /> ລຶບ
            </button>
            <button
              type="button"
              className="btn  btn-edit col-md-3"
              style={{
                backgroundColor: "none",
                border: "solid 0px",
                color: "#FFFFF",
                marginRight: "2%",
              }}
              style={{ textAlign: "center" }}
              onClick={() => _CatagoryEdit(data?.consultingCategory?.id)}
            >
              <FontAwesomeIcon icon={faEdit} style={{ marginRight: 25 }} />{" "}
              ແກ້ໄຂ
            </button>
          </div>
        </div>
      </h6>
      <div
        style={{
          backgroundColor: "#FFF",
          boxShadow: "0px 0px 6px #F7F7F7",
          borderRadius: 8,
          opacity: 1,
          marginLeft: 90,
          marginTop: 20,
          paddingTop: 20,
          paddingLeft: 10,
          marginBottom: 20,
          paddingBottom: 5,
          width: "93%",
        }}
      >
        <div
          className="container"
          style={{
            paddingLeft: 25,
            marginLeft: 25,
            height: "100%",
            width: "100%",
          }}
        >

          <div className="row">
            {data?.consultingCategory?.image ? (
              <div className="col-4">
                <Image
                  src={URL_FOR_SHOW_PHOTO + data?.consultingCategory?.image}
                  alt="stellaIcon"
                  width="100"
                  height="100"
                  style={{
                    height: 150,
                    width: 150,
                    float: "left",
                    borderRadius: 5,
                  }}
                />
              </div>
            ) : (
              <div className="col-4">
                <Image
                  src={ImageLogo + data?.consultingCategory?.image}
                  alt="stellaIcon"
                  width="100"
                  height="100"
                  style={{
                    height: 150,
                    width: 150,
                    float: "left",
                    borderRadius: 5,
                  }}
                />
              </div>
            )}
            <div className="col-8">
              <div className="row">
                <idv className="col-3">
                  <label>ຊື່ໝວດປືກສາ: </label>
                </idv>
                <div className="col-8">
                  <label>
                    {data?.consultingCategory?.name
                      ? data?.consultingCategory?.name
                      : "-"}
                  </label>
                </div>
              </div>
              <div className="row">
                <idv className="col-3">
                  <label>ລາຍລະອຽດ: </label>
                </idv>
                <div className="col-8">
                  <label>
                    {data?.consultingCategory?.detail
                      ? data?.consultingCategory?.detail
                      : "-"}
                  </label>
                </div>
              </div>
              <div className="row">
                <idv className="col-3">
                  <label>ໝາຍເຫດ: </label>
                </idv>
                <div className="col-8">
                  <label>
                    {data?.consultingCategory?.note
                      ? data?.consultingCategory?.note
                      : "-"}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ paddingTop: 5, marginTop: 5 }}>
            <div className="col-2">
              <label for="exampleFormControlInput1" class="form-label">
                ສ້າງໂດຍ :
              </label>
            </div>
            <div className="col-10">
              <label for="exampleFormControlInput1" className="form-label">
                {_renderName(data?.consultingCategory?.createdBy)}
              </label>
            </div>
            <div className="col-2">
              <label for="exampleFormControlInput1" class="form-label">
                ອັບເດດໂດຍ :
              </label>
            </div>
            <div className="col-10">
              <label for="exampleFormControlInput1" className="form-label">
                {_renderName(data?.consultingCategory?.updatedBy)}
              </label>
            </div>
          </div>
          <div style={{ marginBottom: 20 }}></div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>ລຶບໝວດການປືກສາ</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row center">
              <div>ທ່ານຕ້ອງການລຶບ</div>
              <div
                style={{
                  color: "red",
                  fontWeight: "bold",
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                {data?.consultingCategory?.name}
              </div>
              <div>ນີ້ບໍ ?</div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              ຍົກເລິກ
            </Button>
            <Button
              style={{ backgroundColor: "#766AF0" }}
              onClick={() => _confrimeDelete()}
            >
              ຢືນຢັນການລຶບ
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}
