import React, { useState, useEffect } from "react"
import useReactRouter from "use-react-router"
import "../utils/index.css"
import Image from "react-bootstrap/Image"
import ImageLogo from "../../../image/stella.png"
import { Formik } from "formik"
import { useMutation } from "@apollo/react-hooks"
import { LOGIN_USER } from "../apollo"
import { USER_KEY } from "../../../consts"
import { DASHBOARD, COUNSELING_LIST } from "../../../consts/router"
export default function Login() {
  const { history, location, match } = useReactRouter()
  const [loginUser, loginUserData] = useMutation(LOGIN_USER)
  const [checkUser, setCheckUser] = useState(false)

  const _login = async (data) => {
    const _dataRespone = await loginUser({ variables: { data } })
      .then(async function (_dataRespone) {
        await localStorage.setItem(USER_KEY, JSON.stringify(_dataRespone.data))
        if (_dataRespone.data.loginUser.data.role === "ADMIN") {
          history.push(`${DASHBOARD}`)
        } else {
          history.push(`${COUNSELING_LIST}/limit/20/skip/0`)
        }
      })
      .catch(function (error) {
        setCheckUser(true)
      })
  }
  return (
    <div className="contain row">
      <div className="col-md-6">
        <Image
          src={ImageLogo}
          style={{
            width: "100%",
            height: "100%"
          }}
        />
      </div>
      <div className="col-md-6">
        <Formik
          initialValues={{
            userId: "",
            password: ""
          }}
          validate={(values) => { }}
          onSubmit={(values, { setSubmitting }) => {
            if (!values.userId) {
              setCheckUser(true)
            } else if (!values.password) {
              setCheckUser(true)
            } else {
              let data = {
                userId: values.userId,
                password: values.password
              }
              _login(data)
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div style={{ height: 150 }}></div>
              <div className="row">
                <div className="col-md-12" style={{ textAlign: "center" }}>
                  <h1
                    style={{ color: "#43425D", fontSize: 50, fontWeight: "bold" }}
                  >
                    PISCCA
                  </h1>
                  <h1 style={{ color: "#4D4F5C", fontSize: 20 }}>
                    Welcome back! Please login to your account.
                  </h1>
                </div>
                <div
                  className="container"
                  style={{ paddingLeft: 100, paddingRight: 100 }}
                >
                  <label style={{ fontWeight: "bold" }}>ID</label>
                  <input
                    type="text"
                    className="form-control"
                    style={{
                      border: 5,
                      backgroundColor: "#E4E4E4",
                      borderColor: checkUser ? "red" : ""
                    }}
                    name="userId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.userId}
                  />
                  <div style={{ height: 20 }}></div>
                  <label style={{ fontWeight: "bold" }}>Password</label>
                  <input
                    type="password"
                    className="form-control"
                    style={{ border: 0, backgroundColor: "#E4E4E4" }}
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  <div
                    style={{
                      paddingTop: 10,
                      fontWeight: "bold",
                      textAlign: "center",
                      color: "red",
                      display: checkUser ? "" : "none"
                    }}
                  >
                    ຂໍອະໄພ ໄອດີ ຫຼື ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ
                  </div>
                  <div style={{ height: 50 }}></div>
                  <div style={{ textAlign: "center" }}>
                    <button
                      type="submit"
                      class="btn col-6"
                      style={{ backgroundColor: "#43425D", color: "#ffff" }}
                    >
                      {" "}
                      Login{" "}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
}
