import React, { useEffect, useState } from "react";
import { USER_KEY } from "../consts"

import SideNav, {
  Toggle,
  NavItem,
  NavIcon,
  NavText,
} from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faHome,
  faList,
  faUser,
  faUsers,
  faPeoples,
  faBuilding,
  faPeopleCarry,
  faGraduationCap,
  faUserGraduate,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";
import "./sidenav.css";
import {
  DASHBOARD,
  PROMOTION_LIST,
  CUSTOMMER_LIST,
  MENTOR_LIST,
  NOTIFICATION_LIST,
  ISSUE_REPORT_LIST,
  USER_LIST,
  COMPANY_LIST,
  COUNSELING_LIST,
  DASH_BOARD_DETAIL,
} from "../consts/router";
const selectedTabBackgroundColor = "#606060";
const UN_SELECTED_TAB_TEXT = "#606060";
export default function Sidenav({ location, history }) {
  const [userData, setUserData] = useState({})
  useEffect(() => {
    const _resData = localStorage.getItem(USER_KEY)
    const _localJson = JSON.parse(_resData)
    setUserData(_localJson)
  }, [])
  const [selectStatus, setSelectStatus] = useState(
    location.pathname.split("/")[1].split("-")[0]
  );
  const [expandedStatus, setExpandedStatus] = useState(false);
  return (
    <SideNav
      style={{
        backgroundColor: "#fff",
        height: "100vh",
        display: "block",
        position: "fixed",
        zIndex: 1000,
      }}
      onSelect={(selected) => {
        setSelectStatus(selected.split("-")[0]);
        if (selected == MENTOR_LIST) {
          selected = selected;
        } else if (selected == DASHBOARD) {
          selected = selected;
        } else if (selected == USER_LIST) {
          selected = selected;
        } else if (selected == COMPANY_LIST) {
          selected = selected;
        } else if (selected == COUNSELING_LIST) {
          selected = selected;
        } else if (selected == DASH_BOARD_DETAIL) {
          selected = selected;
        }
        const to = "/" + selected;
        if (location.pathname !== to) {
          history.push(to);
        }
      }}
      style={{
        position: "fixed",
        backgroundColor: "#F5F5F5",
        zIndex: 10001,
      }}
      onToggle={(expanded) => {
        setExpandedStatus(expanded);
      }}
    >
      <Toggle />

      <SideNav.Nav defaultSelected={location.pathname.split("/")[1]}>
        {userData?.loginUser?.data?.role === "ADMIN" &&
          <NavItem eventKey="dashBoard">
            <NavIcon>
              <FontAwesomeIcon
                icon={faChartLine}
                style={{
                  fontSize: "18px",
                  color:
                    selectStatus === "dashBoard"
                      ? "#766AF0"
                      : selectedTabBackgroundColor,
                }}
              />
            </NavIcon>
            <NavText
              style={{
                color:
                  selectStatus === "dashBoard" ? "#766AF0" : UN_SELECTED_TAB_TEXT,
              }}
            >
              DASH BOARD
          </NavText>
          </NavItem>
        }
        <NavItem eventKey="counseling/limit/20/skip/0">
          <NavIcon>
            <FontAwesomeIcon
              icon={faCalendarCheck}
              style={{
                color:
                  location.pathname.split("/")[1] === "counseling"
                    ? "#766AF0"
                    : selectedTabBackgroundColor,
              }}
            />
          </NavIcon>
          <NavText
            style={{
              color:
                location.pathname.split("/")[1] === "counseling"
                  ? "#766AF0"
                  : UN_SELECTED_TAB_TEXT,
            }}
          >
            Counseling
          </NavText>
        </NavItem>
        <NavItem eventKey={userData?.loginUser?.data?.role === "ADMIN" ? "staff/limit/20/skip/0" : `staff/staffDetail/${userData?.loginUser?.data?.id}`}>
          <NavIcon>
            <FontAwesomeIcon
              icon={faUser}
              style={{
                color:
                  location.pathname.split("/")[1] === "staff"
                    ? "#766AF0"
                    : selectedTabBackgroundColor,
              }}
            />
          </NavIcon>
          <NavText
            style={{
              color:
                location.pathname.split("/")[1] === "staff"
                  ? "#766AF0"
                  : UN_SELECTED_TAB_TEXT,
            }}
          >
            Staff
          </NavText>
        </NavItem>
        {userData?.loginUser?.data?.role === "ADMIN" &&
          <NavItem eventKey="consultingCategory">
            <NavIcon>
              <FontAwesomeIcon
                icon={faList}
                style={{
                  color:
                    selectStatus === "consultingCategory"
                      ? "#766AF0"
                      : selectedTabBackgroundColor,
                }}
              />
            </NavIcon>
            <NavText
              style={{
                color:
                  selectStatus === "consultingCategory"
                    ? "#766AF0"
                    : UN_SELECTED_TAB_TEXT,
              }}
            >
              ConsultingCategory
          </NavText>
          </NavItem>
        }
        <NavItem eventKey="mentor/limit/20/skip/0">
          <NavIcon>
            <FontAwesomeIcon
              icon={faUsers}
              style={{
                color:
                  location.pathname.split("/")[1] === "mentor"
                    ? "#766AF0"
                    : selectedTabBackgroundColor,
              }}
            />
          </NavIcon>
          <NavText
            style={{
              color:
                location.pathname.split("/")[1] === "mentor"
                  ? "#766AF0"
                  : UN_SELECTED_TAB_TEXT,
            }}
          >
            Mentor
          </NavText>
        </NavItem>
        <NavItem eventKey="user/limit/20/skip/0">
          <NavIcon>
            <FontAwesomeIcon
              icon={faUserGraduate}
              style={{
                color:
                  location.pathname.split("/")[1] === "user"
                    ? "#766AF0"
                    : selectedTabBackgroundColor,
              }}
            />
          </NavIcon>
          <NavText
            style={{
              color:
                location.pathname.split("/")[1] === "user"
                  ? "#766AF0"
                  : UN_SELECTED_TAB_TEXT,
            }}
          >
            User
          </NavText>
        </NavItem>
        {userData?.loginUser?.data?.role === "ADMIN" &&
          <NavItem eventKey="company">
            <NavIcon>
              <FontAwesomeIcon
                icon={faBuilding}
                style={{
                  color:
                    selectStatus === "company"
                      ? "#766AF0"
                      : selectedTabBackgroundColor,
                }}
              />
            </NavIcon>
            <NavText
              style={{
                color:
                  selectStatus === "company" ? "#766AF0" : UN_SELECTED_TAB_TEXT,
              }}
            >
              Company
          </NavText>
          </NavItem>
        }
      </SideNav.Nav>
    </SideNav >
  );
}
