import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import Image from "react-bootstrap/Image";
import ImageLogo from "../../../image/profile.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import "../utils/index.css";
import {
  TITLE_HEADER,
  HEADER,
  BODY,
  URL_FOR_SHOW_PHOTO,
} from "../../../consts";
import { COMPANY_ADD, COMPANY_DETAIL } from "../../../consts/router";
import { COMPANYS, USERS } from "../apollo";

export default function CompanyList() {
  const { history, location, match } = useReactRouter();
  const [allCompanyIds, setAllCompanyIds] = useState([]);
  const [selectedName, setSelectedName] = useState();

  const { loading, error, data } = useQuery(COMPANYS, {
    variables: { where: { companyName: selectedName } },
  });

  const {
    loading: dataUserLoading,
    error: dataUserError,
    data: dataUser,
  } = useQuery(USERS);

  const _CompanyAdd = () => {
    history.push(`${COMPANY_ADD}`);
  };
  const _CompanyDetail = (id) => {
    // history.push(`${COMPANY_DETAIL}/${id}`, id);
    history.push({
      pathname: `${COMPANY_DETAIL}/${id}`,
      state: { data: allCompanyIds }
    });
  };
  const _renderName = (e) => {
    let res = dataUser?.users?.data?.filter((x) => x.id === e);
    if (res?.length > 0) return res[0]?.firstName + " " + res[0]?.lastName;
  };

  const _onNameChange = (e) => {
    let inputedName = e.target.value;

    if (!inputedName) inputedName = undefined;
    setSelectedName(inputedName);
  };
    /***
   * to get all consultings data
   */
     useEffect(() => {
      if (data?.companys?.data?.length > 0) {
        let dataId = data?.companys?.data.map(data => data.id);
        // console.log({data: data?.consultings?.data[200]})
        setAllCompanyIds([...dataId]);
      }
    }, [data]);
  return (
    <div style={TITLE_HEADER}>
      <div
        style={{
          marginTop: 25,
          marginLeft: 25,
          marginRight: 25,
          padding: 10,
          background: "#FFFFFF",
          borderRadius: 8,
          opacity: 1,
          width: "auto",
        }}
      >
        <div
          className="row col-md-12"
          style={{
            //boxShadow: "0px 0px 6px #F7F7F7",
            borderRadius: 8,
            padding: 5,
            //width: "100%"
          }}
        >
          <div className="col-6" style={{ fontSize: "20px" }}>
            ບໍລິສັດທັງໝົດ ({data?.companys?.total})
          </div>
          <div className="col-6" style={{ fontSize: "20px", float: "right" }}>
            <div className="row" style={{ float: "right" }}>
              <div className="col">
                <input
                  type="text"
                  className="form-control empty"
                  placeholder="&#xF002;  ຄົ້ນຫາຊື່ບໍລິສັດ"
                  onChange={(e) => _onNameChange(e)}
                />
              </div>
              <div className="col" style={{ float: "right" }}>
                <button
                  type="button"
                  className="btn btn-secondary btn-app "
                  onClick={() => _CompanyAdd()}
                  style={{ textAlign: "right" }}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{ textAlign: "left" }}
                    style={{ marginRight: 20 }}
                  />
                  ເພີ່ມບໍລິສັດ{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: 25,
          // marginLeft: 25,
          marginRight: 25,
          paddingLeft: 5,
          // background: "#FFFFFF",
          // borderRadius: 4,
          // opacity: 1,
          width: "auto",
        }}
      >
        <div className="row" style={{ paddingLeft: 20, paddingTop: 10 }}>
          {data?.companys?.data.map((data, index) => {
            return (
              <div key={index} className="col-md-2">
                <div
                  style={{
                    background: "#FFFFFF",
                    boxShadow: "0px 3px 6px #00000029",
                    borderRadius: 8,
                    marginBottom: 30,
                  }}
                >
                  {data?.image ? (
                    <Image
                      className="rounded"
                      alt="stellaIcon Cinque Terre"
                      src={URL_FOR_SHOW_PHOTO + data?.image}
                      style={{
                        width: "100%",
                        height: "160px",
                        padding: 10,
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <Image
                      className="rounded"
                      alt="stellaIcon Cinque Terre"
                      src={ImageLogo + data?.image}
                      style={{
                        width: "100%",
                        height: "160px",
                        padding: 10,
                        objectFit: "cover",
                      }}
                    />
                  )}

                  <div style={{ fontSize: 12, paddingLeft: 10 }}>
                    {data?.companyName}
                  </div>
                  <div style={{ fontSize: 12, paddingLeft: 10 }}>
                    ສ້າງໂດຍ : {_renderName(data?.createdBy)}
                  </div>
                  <button
                    style={{
                      width: "100%",
                      height: 30,
                      borderBottomRightRadius: 5,
                      borderBottomLeftRadius: 5,
                      backgroundColor: "#7D71F1",
                      border: "solid 0px",
                      color: "#fff",
                    }}
                    onClick={() => _CompanyDetail(data?.id)}
                  >
                    ລາຍລະອຽດ
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
