import React, { useState, useRef, useEffect } from "react";
import useReactRouter from "use-react-router";
import Image from "react-bootstrap/Image";
import ImageLogo from "../../../image/profile.png";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import axios from "axios";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import { ErrorValidationBox } from "../../../common/ErrorValidationBox";
import {
  faCheck,
  faEdit,
  faFileAlt,
  faPlus,
  faTimesCircle,
  faTrashAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  PRESINGED_URL,
  CONSULTING_CATEGORYS,
  COMPANYS,
  CREATE_USER,
} from "../apollo";
import { Formik } from "formik";
import {
  TITLE_HEADER,
  HEADER,
  BODY_DISCOUNT_SHOPDETAIL,
} from "../../../consts";
import { MENTOR_LIST, STORE } from "../../../consts/router";
export default function MentorAdd() {
  const { history, location, match } = useReactRouter();
  const { loading, error, data } = useQuery(CONSULTING_CATEGORYS);
  const {
    loading: companyLoading,
    error: companyErrCREATE_USERor,
    data: companyData,
  } = useQuery(COMPANYS);
  const [createUser, setCreateUser] = useMutation(CREATE_USER);

  // history
  const _MentorList = () => {
    history.push(`${MENTOR_LIST}/limit/20/skip/0`);
  };
  // useState
  const [canCounseling, setCanCounseling] = useState("xxx");
  const [arrayCanCounseling, setArrayCanCounseling] = useState([]);
  const [genderData, setGenderData] = useState("NO_GENDER");

  const [uploadPhoto, setUploadPhoto] = useMutation(PRESINGED_URL);
  // upload pic
  const [resPhoto, setResPhoto] = useState();
  const [file, setFile] = useState("");
  const [imageLoading, setImageLoading] = useState();
  const [getNewRole, setGetNewRole] = useState("");
  const handleUpload = async (event) => {
    setImageLoading("");
    try {
      setFile(event.target.files[0]);
      let formData = new FormData();
      let fileData = event.target.files[0].name;
      let fileDataBoole = event.target.files[0];
      const resUploadPhoto = await uploadPhoto({
        variables: { fileName: fileData },
      });
      let afterUpload = await axios({
        method: "put",
        url: resUploadPhoto.data.preSignedUrl.url,
        data: fileDataBoole,
        headers: {
          "Content-Type": " file/*; image/*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      });
      setResPhoto(afterUpload.config.data.name);
    } catch (error) { }
  };
  // lung jak upload leo pic ja ma so u nee
  const ImageThumb = ({ image }) => {
    return (
      <img
        src={URL.createObjectURL(image)}
        alt={image.name}
        style={{
          borderRadius: "50%",
          height: 300,
          width: 300,
        }}
      />
    );
  };
  // Select CanCounseling
  const _onChangeArrayCategory = (e) => {
    setCanCounseling(e.target.value);
  };
  const _onAddArrayCategory = async () => {
    if (canCounseling === "xxx") {
      Swal.fire({
        icon: "error",
        text: "ກາລຸນາເລືອກໝວດການປືກສາກ່ອນ!",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      let _arrayCanCounseling = [...arrayCanCounseling];
      let countArray = 0;
      for (var i = 0; i < _arrayCanCounseling.length; i++) {
        if (_arrayCanCounseling[i] === canCounseling) {
          countArray++;
        }
      }
      if (countArray > 0) {
        Swal.fire({
          icon: "error",
          text: "ໝວດການປືກສາ ນີ້ມີແລ້ວ!",
          showConfirmButton: false,
        });
      } else {
        _arrayCanCounseling.push(canCounseling);
      }
      setArrayCanCounseling(_arrayCanCounseling);
      setCanCounseling("");
    }
  };
  const _onDeleteArrayCanCounseling = async (item) => {
    const filterArray = await arrayCanCounseling.filter((x) => x != item);
    setArrayCanCounseling(filterArray);
  };
  const _renderCategory = (e) => {
    let res = data?.consultingCategorys?.data?.filter((x) => x.id === e);
    if (res?.length > 0) return res[0]?.name;
  };
  const handleChangeGender = async (gender) => {
    setGenderData(gender);
  };
  const _renderCompany = (e) => {
    let res = companyData?.companys?.data?.filter((x) => x.id === e);
    if (res?.length > 0) return res[0]?.companyName;
  };
  const _createUser = async (values) => {
    let redomNumber = Math.floor(Math.random() * 9878679657657);
    let category = [];
    for (let i = 0; i < arrayCanCounseling.length; i++) {
      category.push({
        id: arrayCanCounseling[i],
      });
    }
    try {
      let rendomUserId = String(
        values.birthday + "/" + redomNumber + "/" + values.firstName
      );
      let data = {
        userId: rendomUserId,
        role: "MENTOR",
        firstName: values.firstName,
        lastName: values.lastName,
        birthday: values.birthday,
        phone: String(values.phone),
        gender: genderData,
        tribe: values.tribe,
        image: resPhoto,
        address: {
          village: values.village,
          district: values.district,
          province: values.province,
        },
        province: values.province,
        workingStatus: values.workingStatus,
        accessibility: values.accessibility,
        educationLevel: values.educationLevel,
        company: values.company,
        canCounseling: category,
        branch: values.branch,
        religion: values.religion,
        detail: values.detail,
        note: values.note,
      };
      const resCreateUser = await createUser({ variables: { data } })
      if (resCreateUser?.data?.createUser?.id) {
        Swal.fire({
          icon: "success",
          title: "ການບັນທຶກສຳເລັດ",
          showConfirmButton: false,
          timer: 1500,
        }).then(function () {
          history.push(`${MENTOR_LIST}/limit/10/skip/0`);
          window.location.reload(true);
        });
      } else {
        Swal.fire({
          icon: "failed",
          title: "ການບັນທຶກບໍ່ສຳເລັດ",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (errors) {
      if (errors?.graphQLErrors[0]?.message === "USERID_IS_NOT_READY") {
        Swal.fire({
          icon: "error",
          text: "userId ນິ້ແມ່ນມີແລ້ວ",
          showConfirmButton: false,
        });
      }
    }
  };

  const StaffSchema = Yup.object().shape({
    firstName: Yup.string().required("ກະລຸນາປ້ອນ!"),
    lastName: Yup.string().required("ກະລຸນາປ້ອນ!"),
    phone: Yup.string().required("ກະລຸນາປ້ອນ!"),
  });
  return (
    <div style={TITLE_HEADER}>
      <h6 style={{ marginBottom: 25, marginLeft: 25 }}>
        <a href="#" onClick={() => _MentorList()} style={{ color: "#766AF0" }}>
          Mentor | <i class="fa fa-users" aria-hidden="true"></i> » ລາຍຊື່
          Mentor »{" "}
        </a>
        ເພີ່ມ Mentor
      </h6>
      <Formik
        initialValues={{}}
        validationSchema={StaffSchema}
        onSubmit={(values, { setSubmitting }) => {
          _createUser(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div
              style={{
                backgroundColor: "#fff",
                borderRadius: 8,
                marginLeft: 25,
                marginRight: 25,
              }}
            >
              <div style={{ padding: 25 }} className="bod-box-show">
                <div style={{ padding: 5, marginLeft: 5, fontSize: "18px" }}>
                  <a style={{ color: "#766AF0", marginRight: 40 }}>
                    ເພີ່ມ Mentor
                  </a>
                </div>
                <div className="row" style={{ padding: 20, height: "100%" }}>
                  <div className="col-md-4">
                    <div className="row center">
                      <input
                        type="file"
                        id="file-upload"
                        onChange={handleUpload}
                        hidden
                      />
                      <label for="file-upload">
                        <div
                          style={{
                            backgroundColor: "#E4E4E4E4",
                            height: 300,
                            width: 300,
                            borderRadius: "50%",
                            cursor: "pointer",
                            display: "flex",
                          }}
                        >
                          {file ? (
                            <ImageThumb image={file} />
                          ) : (
                              <div
                                style={{
                                  display: "flex",
                                  height: 300,
                                  width: 300,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "50%",
                                }}
                              >
                                <p
                                  style={{
                                    color: "#fff",
                                    fontSize: 80,
                                    fontWeight: "bold",
                                  }}
                                >
                                  +
                              </p>
                              </div>
                            )}
                        </div>
                      </label>
                      {/* progass */}
                      {imageLoading ? (
                        <div class="progress" style={{ height: 20 }}>
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style={{
                              width: `${imageLoading}%`,
                              backgroundColor: "#816aae",
                            }}
                            aria-valuenow={imageLoading}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            {imageLoading}%
                          </div>
                        </div>
                      ) : (
                          <div style={{ height: 20 }} />
                        )}
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div
                      style={{
                        fontWeight: "bold",
                        color: "#766AF0",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faUser}
                        style={{ color: "#766AF0", marginRight: 10 }}
                      />{" "}
                      ຂໍ້ມູນທົ່ວໄປ
                    </div>
                    <div style={{ height: 10 }}></div>
                    <div className="row">
                      <div className="col-6">
                        <div
                          style={{
                            paddingTop: 10,
                            textAlign: "left",
                            letterSpacing: 0,
                            opacity: 1,
                          }}
                        >
                          ຊື່ <a style={{ color: "#F91B1B" }}>*</a>
                        </div>
                        <input
                          className="form-control btn-block"
                          type="text"
                          name="firstName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.firstName}
                        />
                        <ErrorValidationBox
                          errors={errors}
                          fieldName={"firstName"}
                        />
                      </div>
                      <div className="col-6">
                        <div style={{ paddingTop: 10 }}>
                          ນາມສະກຸນ <a style={{ color: "#F91B1B" }}>*</a>
                        </div>
                        <input
                          className="form-control btn-block"
                          type="text"
                          name="lastName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.lastName}
                        />
                        <ErrorValidationBox
                          errors={errors}
                          fieldName={"lastName"}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div style={{ paddingTop: 10 }}>
                          ເບີໂທ <a style={{ color: "#F91B1B" }}>*</a>
                        </div>
                        <input
                          className="form-control btn-block"
                          type="number"
                          name="phone"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.phone}
                        />
                        <ErrorValidationBox
                          errors={errors}
                          fieldName={"phone"}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div style={{ paddingTop: 10 }}>
                          ເລືອກໝວດ <a style={{ color: "#F91B1B" }}>*</a>
                        </div>
                        <div className="col-12 row">
                          <select
                            class="form-control col-5"
                            type="text"
                            name="canCounseling"
                            onChange={(e) => _onChangeArrayCategory(e)}
                            value={canCounseling}
                          >
                            <option value="xxx" disabled={true} selected={true}>
                              -
                            </option>
                            {data?.consultingCategorys?.data.map(
                              (data, index) => {
                                return (
                                  <option value={data?.id}>{data?.name}</option>
                                );
                              }
                            )}
                          </select>

                          <button
                            className="button"
                            type="button"
                            onClick={() => _onAddArrayCategory()}
                          >
                            <FontAwesomeIcon
                              icon={faPlus}
                              style={{
                                marginRight: 10,
                                color: "#fff",
                                textAlign: "center",
                              }}
                            />
                            ເພີ່ມ
                          </button>
                        </div>
                      </div>
                    </div>
                    <div style={{ paddingTop: 20 }}></div>
                    <div className="row">
                      {arrayCanCounseling?.map((item, index) => (
                        <div style={{ padding: 5 }}>
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              textAlign: "center",
                              alignItems: "center",
                              border: "solid 1px #E4E4E4",
                              backgroundColor: "#E4E4E4",
                              borderRadius: 8,
                              padding: 5,
                              marginLeft: 10,
                            }}
                          >
                            <div>
                              {_renderCategory(item)}
                              {"\t\t"}
                            </div>
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              onClick={() => _onDeleteArrayCanCounseling(item)}
                              style={{ color: "red", marginLeft: 10 }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                    <div class="form-inline">
                      <div class="form-group mb-2">
                        <label for="staticEmail2" className="sr-only "></label>
                        <input
                          type="text"
                          readonly
                          className="form-control-plaintext"
                          id="staticEmail2"
                          value="ວັນເດືອນປີເກີດ"
                          disabled
                        />
                        <br />
                        <input
                          class="form-control"
                          style={{ marginRight: 20 }}
                          type="date"
                          name="birthday"
                          onChange={handleChange}
                          value={values.birthday}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="col-md-8"
                        style={{ marginTop: 10, paddingTop: 10 }}
                      >
                        ເພດ
                        <br />{" "}
                      </div>
                      <div
                        className="col-md-8"
                        style={{
                          justifyContent: "left",
                          marginBottom: 10,
                          paddingBottom: 10,
                          paddingRight: 40,
                        }}
                      >
                        <div
                          class="custom-control custom-radio custom-control-inline"
                          style={{ marginRight: 40 }}
                        >
                          <input
                            type="radio"
                            id="customRadioInline1"
                            name="gender"
                            class="custom-control-input"
                            style={{ color: "#766AF0" }}
                            onChange={() => handleChangeGender("MALE")}
                          />
                          <label
                            class="custom-control-label"
                            for="customRadioInline1"
                          >
                            ຊາຍ
                          </label>
                        </div>
                        <div
                          class="custom-control custom-radio custom-control-inline"
                          style={{ marginRight: 40 }}
                        >
                          <input
                            type="radio"
                            id="customRadioInline2"
                            name="gender"
                            class="custom-control-input"
                            onChange={() => handleChangeGender("FEMALE")}
                          />
                          <label
                            class="custom-control-label"
                            for="customRadioInline2"
                          >
                            ຍິງ
                          </label>
                        </div>
                        <div
                          class="custom-control custom-radio custom-control-inline"
                          style={{ marginRight: 40 }}
                        >
                          <input
                            type="radio"
                            id="customRadioInline3"
                            name="gender"
                            class="custom-control-input"
                            onChange={() => handleChangeGender("OTHER")}
                          />
                          <label
                            class="custom-control-label"
                            for="customRadioInline3"
                          >
                            ເພດອື່ນ
                          </label>
                        </div>
                        <div
                          class="custom-control custom-radio custom-control-inline"
                          style={{ marginRight: 40 }}
                        >
                          <input
                            type="radio"
                            id="customRadioInline4"
                            name="gender"
                            class="custom-control-input"
                            onChange={() => handleChangeGender("NO_GENDER")}
                            defaultChecked
                          />
                          <label
                            class="custom-control-label"
                            for="customRadioInline4"
                          >
                            ບໍ່ລະບຸ
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div
                          style={{
                            paddingTop: 10,
                            textAlign: "left",
                            letterSpacing: 0,
                            opacity: 1,
                          }}
                        >
                          ສະຖານະການເຮັດວຽກ
                        </div>
                        <div style={{ paddingTop: 10 }}></div>
                        <select
                          id="inputState"
                          className="form-control "
                          style={{ width: "100%" }}
                          type="text"
                          name="workingStatus"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.workingStatus}
                        >
                          <option value="" disabled={true} selected={true}>-</option>
                          <option value="WORK">ເຮັດວຽກ</option>
                          <option value="STUDYING">ຮຽນ</option>
                          <option value="UNEMPLOYED">ຫ່ວາງງານ</option>
                          <option value="OTHER">ອື່ນໆ</option>
                        </select>
                      </div>
                      <div className="col-6">
                        <div
                          style={{
                            paddingTop: 10,
                            marginBottom: 10,
                            textAlign: "left",
                            letterSpacing: 0,
                            opacity: 1,
                          }}
                        >
                          ຄວາມບົກຜ່ອງທາງຮ່າງກາຍ(ພິການ){" "}
                        </div>
                        <input
                          className="form-control btn-block"
                          name="Disable"
                          placeholder="-"
                          type="text"
                          name="accessibility"
                          onChange={handleChange}
                          value={values.accessibility}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div
                          style={{
                            paddingTop: 10,
                            textAlign: "left",
                            letterSpacing: 0,
                            opacity: 1,
                          }}
                        >
                          ຊົນເຜົ່າ
                        </div>
                        <input
                          className="form-control btn-block"
                          name="Ethnic"
                          placeholder="-"
                          type="text"
                          name="tribe"
                          onChange={handleChange}
                          value={values.tribe}
                        />
                      </div>
                      <div className="col-6">
                        <div
                          style={{
                            paddingTop: 10,
                            textAlign: "left",
                            letterSpacing: 0,
                            opacity: 1,
                          }}
                        >
                          ສາດສະໜາ
                        </div>
                        <input
                          className="form-control btn-block"
                          name="Religion"
                          placeholder="-"
                          type="text"
                          name="religion"
                          onChange={handleChange}
                          value={values.religion}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div
                          style={{
                            paddingTop: 10,
                            textAlign: "left",
                            letterSpacing: 0,
                            opacity: 1,
                          }}
                        >
                          ບ້ານປະຈຸບັນ
                        </div>
                        <input
                          className="form-control btn-block"
                          name="VillageBorn"
                          placeholder="-"
                          type="text"
                          name="village"
                          onChange={handleChange}
                          value={values.village}
                        />
                      </div>
                      <div className="col-6">
                        <div
                          style={{
                            paddingTop: 10,
                            textAlign: "left",
                            letterSpacing: 0,
                            opacity: 1,
                          }}
                        >
                          ເມືອງປະຈຸບັນ
                        </div>
                        <input
                          className="form-control btn-block"
                          name="DisrictBorn"
                          placeholder="-"
                          type="text"
                          name="district"
                          onChange={handleChange}
                          value={values.district}
                        />
                      </div>
                      <div className="col-6">
                        <div
                          style={{
                            paddingTop: 10,
                            textAlign: "left",
                            letterSpacing: 0,
                            opacity: 1,
                          }}
                        >
                          ແຂວງປະຈຸບັນ
                        </div>
                        <select
                          id="inputState"
                          className="form-control "
                          style={{ width: "100%" }}
                          type="text"
                          name="province"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.province}
                        >
                          <option value="-" disabled={true} selected={true}>ເລືອກແຂວງ</option>
                          <option value="Phongsali">ຜົ້ງສາລີ</option>
                          <option value="LuangNamtha">ຫຼວງນ້ຳທາ</option>
                          <option value="Huoaphan">ຫົວພັນ</option>
                          <option value="Borkeo">ບໍ່ແກ້ວ</option>
                          <option value="Oudomxay">ອຸດົມໄຊ</option>
                          <option value="Sayaboury">ໄຊຍະບູລີ</option>
                          <option value="Xiengkhouang">ຊຽງຂວາງ</option>
                          <option value="LuangPrabang">ຫຼວງພະບາງ</option>
                          <option value="Vientiane">ວຽງຈັນ</option>
                          <option value="VientianeCapital">
                            ນະຄອນຫຼວງວຽງຈັນ
                          </option>
                          <option value="Borikhamsay">ບໍລິຄຳໄຊ</option>
                          <option value="Khammuoan">ຄຳມ່ວນ</option>
                          <option value="Savannakhet">ສະຫວັນນະເຂດ</option>
                          <option value="Salavanh">ສາລະວັນ</option>
                          <option value="Sekong">ເຊກອງ</option>
                          <option value="Attapue">ອັດຕະປື</option>
                          <option value="Champasak">ຈຳປາສັກ</option>
                          <option value="Saisomboun">ໄຊສົມບູນ</option>
                        </select>
                      </div>
                      <div className="col-6">
                        <div
                          style={{
                            paddingTop: 10,
                            textAlign: "left",
                            letterSpacing: 0,
                            opacity: 1,
                          }}
                        >
                        </div>
                      </div>
                    </div>
                    <div style={{ height: 40 }}></div>
                    <div
                      style={{
                        fontWeight: "bold",
                        color: "#766AF0",
                      }}
                    >
                      <i
                        className="fa fa-graduation-cap"
                        style={{ paddingRight: 10 }}
                      ></i>
                      ຂໍ້ມູນການສຶກສາ
                    </div>
                    <div style={{ height: 10 }}></div>
                    <div className="row">
                      <div className="col-6">
                        <div
                          style={{
                            textAlign: "left",
                            letterSpacing: 0,
                            opacity: 1,
                          }}
                        >
                          ລະດັບການສືກສາ
                        </div>
                        <div style={{ paddingTop: 10 }}></div>
                        <select
                          id="inputState"
                          className="form-control "
                          style={{ width: "100%" }}
                          type="text"
                          name="educationLevel"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.educationLevel}
                        >
                          <option value="" disabled={true} selected={true}>-</option>
                          <option value="PRIMARY">ປະຖົມ</option>
                          <option value="LOWER_SECONDARY">ມັດທະຍົມຕົ້ນ</option>
                          <option value="UPPER_SECONDARY">ມັດທະຍົມປາຍ</option>
                          <option value="FIRST_FLOOR">ຊັ້ນຕົ້ນ</option>
                          <option value="MIDDLE_CLASS">ຊັ້ນກາງ</option>
                          <option value="HIGH_CLASS">ຊັ້ນສູງ</option>
                          <option value="BACHELOR">ປະລິນຍາຕີ</option>
                          <option value="MASTER">ປະລິນຍາໂທ</option>
                          <option value="NOT_STUDYING">ບໍ່ໄດ້ຮຽນ</option>
                        </select>
                      </div>
                      <div className="col-6">
                        <div
                          style={{
                            paddingTop: 10,
                            textAlign: "left",
                            letterSpacing: 0,
                            opacity: 1,
                          }}
                        >
                          ສາຂາຮຽນ
                        </div>
                        <input
                          className="form-control btn-block"
                          placeholder="-"
                          type="text"
                          name="branch"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.branch}
                        />
                      </div>
                    </div>
                    <div style={{ height: 40 }}></div>
                    <div
                      style={{
                        fontWeight: "bold",
                        color: "#766AF0",
                      }}
                    >
                      <i
                        className="fa fa-building"
                        style={{ paddingRight: 10 }}
                      ></i>
                      ຂໍ້ມູນບໍລິສັດ
                    </div>

                    <div className="row">
                      <div className="col-6">
                        <div
                          style={{
                            paddingTop: 10,
                            textAlign: "left",
                            letterSpacing: 0,
                            opacity: 1,
                          }}
                        >
                          ຊື່ບໍລິສັດ
                        </div>
                        <select
                          id="company"
                          class="form-control"
                          style={{ width: "100%" }}
                          name="company"
                          onChange={handleChange}
                          value={values.company}
                        >
                          <option value="" disabled={true} selected={true}> - </option>
                          {companyData?.companys?.data?.map((data, index) => {
                            return (
                              <option value={data?.id}>
                                {data?.companyName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div style={{ height: 40 }}></div>
                    <div
                      style={{
                        fontWeight: "bold",
                        color: "#766AF0",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faFileAlt}
                        style={{ color: "#766AF0", marginRight: 10 }}
                      />{" "}
                      ຂໍ້ມູນເພີ່ມເຕີມ
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div
                          style={{
                            paddingTop: 10,
                            textAlign: "left",
                            letterSpacing: 0,
                            opacity: 1,
                          }}
                        >
                          ຄຳອະທິບາຍ
                        </div>
                        <div class="form-group">
                          <textarea
                            class="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            placeholder="-"
                            name="detail"
                            onChange={handleChange}
                            value={values.detail}
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-12">
                        <div
                          style={{
                            paddingTop: 10,
                            textAlign: "left",
                            letterSpacing: 0,
                            opacity: 1,
                          }}
                        >
                          ໝາຍເຫດ
                        </div>
                        <div class="form-group">
                          <textarea
                            class="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            placeholder="-"
                            name="note"
                            onChange={handleChange}
                            value={values.note}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div
                    className="col-md-12"
                    style={{
                      textAlign: "right",
                      paddingRight: 30,
                      marginRight: 30,
                    }}
                  >
                    <button
                      type="button"
                      class="btn btn-outline-danger col-md-1"
                      style={{
                        textAlign: "center",
                        letterSpacing: 0,
                        opacity: 1,
                        paddingRight: 10,
                        marginRight: 10,
                      }}
                      onClick={() => _MentorList()}
                    >
                      {" "}
                      ຍົກເລິກ
                    </button>
                    <button
                      type="submit"
                      class="btn btn-secondary col-md-1"
                      style={{
                        background:
                          "transparent linear-gradient(269deg, #766AF0 0%, #978EF3 100%) 0% 0% no-repeat padding-box",
                        textAlign: "center",
                        letterSpacing: 0,
                        opacity: 1,
                        paddingRight: 5,
                        marginRight: 5,
                      }}
                    >
                      ຢືນຢັນ
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}
