import React from "react"
import moment from "moment"
import * as _ from "lodash"
import { USER_KEY } from "../consts"
// import 'moment/locale/lo'

// get user data from localStorage
export const getUserDataFromLCStorage = () => {
  const user = JSON.parse(localStorage.getItem(USER_KEY))
  return user
}

export const useAuth = () => {
  const userData = getUserDataFromLCStorage()
  const isAuthenticated = !_.isEmpty(userData)
  return isAuthenticated
}

// ລະດັບການສຶກສາ
export const educationLevel = (subject) => {
  if (subject === "PRIMARY") {
    return "ປະຖົມ"
  } else if (subject === "LOWER_SECONDARY") {
    return "ມັດທະຍົມຕົ້ນ"
  } else if (subject === "UPPER_SECONDARY") {
    return "ມັດທະຍົມປາຍ"
  } else if (subject === "FIRST_FLOOR") {
    return "ຊັ້ນຕົ້ນ"
  } else if (subject === "MIDDLE_CLASS") {
    return "ຊັ້ນກາງ"
  } else if (subject === "HIGH_CLASS") {
    return "ຊັ້ນສູງ"
  } else if (subject === "BACHELOR") {
    return "ປະລິນຍາຕີ"
  } else if (subject === "MASTER") {
    return "ປະລິນຍາໂທ"
  } else if (subject === "NOT_STUDYING") {
    return "ບໍ່ໄດ້ຮຽນ"
  }
}
// WorkingStatus
export const workingStatus = (work) => {
  if (work === "WORK") {
    return "ເຮັດວຽກ"
  } else if (work === "STUDYING") {
    return "ຮຽນ"
  } else if (work === "UNEMPLOYED") {
    return "ຫ່ວາງງານ"
  } else if (work === "OTHER") {
    return "ອື່ນໆ"
  }
}

// ກຳນົດ ເພດ
export const setGender = (SetGender) => {
  let res = ""
  switch (SetGender) {
    case "MALE":
      res = "ຊາຍ"
      break
    case "FEMALE":
      res = "ຍິງ"
      break
    case "OTHER":
      res = "ອື່ນໆ"
      break
  }
  return res
}
export const setGenders = (SetGender) => {
  let res = ""
  switch (SetGender) {
    case "FEMALE":
      res = "ຍິງ"
      break
    case "MALE":
      res = "ຊາຍ"
      break
    case "OTHER":
      res = "ອື່ນໆ"
      break
    case "NO_GENDER":
      res = "ບໍ່ລະບຸ"
      break
  }
  return res
}
// ກຳນົດ ສະຖານະປິດເປີດ
export const setSwich = (string) => {
  let res = ""
  switch (string) {
    case true:
      res = "ເປີດ"
      break
    case false:
      res = "ປິດ"
      break
  }
  return res
}

// ກຳນົດ ຄ່າເລີ່ມຕົ້ນ(ເພດ)
export const checkgender = (gender) => {
  let gd = ""
  switch (gender) {
    case "FEMALE":
      gd = "checked"
      break
    case "MALE":
      gd = "checked"
      break
    case "OTHER":
      gd = "checked"
      break
  }
  return gd
}

// ກຳນົດ ສະຖານະພາບ
export const noticeStatus = (notice) => {
  let status = ""
  switch (notice) {
    case "IMPORTANT":
      status = "ເອກະສານທີ່ສຳຄັນ"
      break
    case "NORMAL":
      status = "ທົ່ວໄປ"
      break
    case "NOTIC":
      status = "ແຈ້ງການ"
      break
    case "AGREEMENT":
      status = "ຂໍ້ຕົກລົງ"
      break
    case "REGULATION":
      status = "ລະບຽບການ"
      break
  }
  return status
}
// ກຳນົດ ສະຖານະພາບ
export const MaritualStatus = (maritualStatus) => {
  let status = ""
  switch (maritualStatus) {
    case "SINGLE":
      status = "ໂສດ"
      break
    case "MARRIAGE":
      status = "ເເຕ່ງງານ"
      break
  }
  return status
}
// ກຳນົດ ສະຖານະການລົງທະບຽນ
export const RegStatus = (reStatus) => {
  let status = "ຍັງ"
  switch (reStatus) {
    case 1:
      status = "ແລ້ວ"
      break
  }
  return status
}

export const bracket = (tag) => {
  let st = "("
  let en = ")"
  let i = st + tag + en
  return i
}

//  ກຳນົດ ອາຍຸ
export const age = (age) => {
  let today = new Date()
  let y = today.getFullYear()
  let dob = moment(age).format("YYYY")
  return y - dob
}

// ກຳນົດ ຟໍແມັດເງິນ
export const currency = (value) => {
  let currencys = new Intl.NumberFormat("en-CA").format(value)
  if (value != 0) return currencys
  else if (value == 0) return "0"
  else return ""
}

// ກຳນົດ ເວລາປັດຈຸບັນ(ພາສາລາວ)
export const formatDateTime = (dateTime) => {
  moment.locale("lo")
  let resp = moment(dateTime).format("DD MMMM YYYY, HH:mm")
  // new Date().toISOString()
  return resp
}

// ກຳນົດ ວັນທີປັດຈຸບັນ(ພາສາລາວ)
export const formatDate = (dateTime) => {
  moment.locale("lo")
  let resp = moment(dateTime).format("DD MMMM YYYY")
  if (dateTime) return resp
  else return "-"
}

// ກຳນົດ ວັນທີປັດຈຸບັນ(/)
export const formateDateSlash = (slashData) => {
  let resp = moment(slashData).format("YYYY/MM/DD")
  return resp
}

// ກຳນົດ ວັນທີປັດຈຸບັນ(-)
export const formatDateDash = (dashDate) => {
  let resp = moment(dashDate).format("YYYY-MM-DD")
  return resp
}

// ກຳນົດ ວັນທີປັດຈຸບັນ(ຖັດໄປ 1 ອາທິດ)
export const nextSevenDay = () => {
  var nextSenvenDay = moment().add(7, "days")
  var nextSevenDays = moment(nextSenvenDay).format("YYYY-MM-DD")
  return nextSevenDays
}

// ກຳນົດ ວັນທີປັດຈຸບັນ(ຖັດໄປ 1 ມື້)
export const nextOneDay = () => {
  var nextOneDay = moment().add(1, "days")
  var nextOneDays = moment(nextOneDay).format("YYYY-MM-DD")
  return nextOneDays
}

// ກຳນົດ ວັນທີ ແລະ ເວລາປັດຈຸບັນ(ພາສາລາວ)
export const toDay = () => {
  moment.locale("lo")
  var today = new Date()
  var todays = moment(today).format("DD/MM/YY, HH:mm")
  return todays
}

// ກຳນົດ ວັນທີປັດຈຸບັນ(-)
export const toDayDash = () => {
  var today = new Date()
  var todays = moment(today).format("YYYY-MM-DD")
  return todays
}
export const reconvertDay = (day) => {
  let result = ""
  switch (day) {
    case "MONDAY":
      result = "ຈັນ"
      break
    case "TUESDAY":
      result = "ອັງຄານ"
      break
    case "WEDNESDAY":
      result = "ພຸດ"
      break
    case "THURSDAY":
      result = "ພະຫັດ"
      break
    case "FRIDAY":
      result = "ສຸກ"
      break
    case "SATURDAY":
      result = "ເສົາ"
      break
    case "SUNDAY":
      result = "ອາທິດ"
      break
    default:
      result = "ຈັນ"
  }
  return result
}

export const convertLetterGrade = (numericGrade) => {
  if (numericGrade >= 80) return "A"
  else if (numericGrade >= 75) return "B+"
  else if (numericGrade >= 70) return "B"
  else if (numericGrade >= 65) return "C+"
  else if (numericGrade >= 60) return "C"
  else if (numericGrade >= 55) return "D+"
  else if (numericGrade >= 50) return "D"
  else if (numericGrade >= 0) return "F"
  else if (numericGrade.toString() == "") return "I"
  else return ""
}

// ກຳນົດ ເຄື່ອງໝາຍ ບັງຄັບໃຫ້ປ້ອນຂໍ້ມູນ
export const valiDate = () => {
  return <font style={{ color: "red" }}> * </font>
}

// ກຳນົດ ການຈັດລຽງຕາມຄ່າຕ່າງໆ
export const SortBy = ({ sort }) => {
  return (
    <div
      onClick={() => {
        sort()
      }}
      style={{
        float: "right",
        marginRight: 5,
        cursor: "pointer"
      }}
    >
      <i className="fa fa-sort"></i>
    </div>
  )
}
// ແຂວງ
export const _provincelevel = (province) => {
  if (province === "Phongsali") {
    return "ຜົ້ງສາລີ"
  } else if (province === "LuangNamtha") {
    return "ຫຼວງນ້ຳທາ"
  } else if (province === "Huoaphan") {
    return "ຫົວພັນ"
  } else if (province === "Borkeo") {
    return "ບໍ່ແກ້ວ"
  } else if (province === "Oudomxay") {
    return "ອຸດົມໄຊ"
  } else if (province === "Sayaboury") {
    return "ໄຊຍະບູລີ"
  } else if (province === "Xiengkhouang") {
    return "ຊຽງຂວາງ"
  } else if (province === "LuangPrabang") {
    return "ຫຼວງພະບາງ"
  } else if (province === "Vientiane") {
    return "ວຽງຈັນ"
  } else if (province === "VientianeCapital") {
    return "ນະຄອນຫຼວງວຽງຈັນ"
  } else if (province === "Borikhamsay") {
    return "ບໍລິຄຳໄຊ"
  } else if (province === "Khammuoan") {
    return "ຄຳມ່ວນ"
  } else if (province === "Savannakhet") {
    return "ສະຫວັນນະເຂດ"
  } else if (province === "Salavanh") {
    return "ສາລະວັນ"
  } else if (province === "Sekong") {
    return "ເຊກອງ"
  } else if (province === "Attapue") {
    return "ອັດຕະປື"
  } else if (province === "Champasak") {
    return "ຈຳປາສັກ"
  } else if (province === "Saisomboun") {
    return "ໄຊສົມບູນ"
  } else {

    return " - "
  }
}
