import React, { useState, useRef, useEffect } from "react";
import useReactRouter from "use-react-router";
import Image from "react-bootstrap/Image";
import ImageLogo from "../../../image/profile.png";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import {
  faHistory,
  faSync,
  faUser,
  faUserGraduate,
  faUserTie,
  faFileAlt,
  faEdit,
  faPlus,
  faTrash,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { URL_FOR_SHOW_PHOTO, USER_KEY } from "../../../consts";
import {
  formatDate,
  setGenders,
  educationLevel,
  workingStatus,
  formatDateTime,
  _provincelevel,
} from "../../../helper/super";
import {
  USER_EDIT,
  USER_LIST,
  USER_DETAIL,
  COUNSELING_LIST,
  COUNSELING_DETAIL,
} from "../../../consts/router";
import {
  USERS,
  USER,
  DELETE_USER,
  CONSULTINGS,
  CONSULTING_CATEGORYS,
  UPDATE_STATUS_USER,
} from "../apollo";
export default function UserDetail() {
  const { history, location, match } = useReactRouter();
  const { loading, error, data } = useQuery(USERS);
  const {
    loading: dataUserLoading,
    error: dataUserError,
    data: dataUser,
  } = useQuery(USER, {
    variables: { where: { id: match?.params?.id } },
  });
  const {
    loading: consultingSLoading,
    error: consultingSError,
    data: consultingSData,
  } = useQuery(CONSULTINGS, {
    variables: { where: { userId: match?.params?.id } },
  });
  const {
    loading: consultingLoading,
    error: consultingError,
    data: consultingData,
  } = useQuery(CONSULTING_CATEGORYS);
  const [deleteUser, setDeleteUser] = useMutation(DELETE_USER);
  const [updateStatusUser, setUpdateMainConsultingStatus] = useMutation(
    UPDATE_STATUS_USER
  );

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [updateStatut, setUpdateStatut] = useState(false);
  const handleCloseUpdate = () => setUpdateStatut(false);
  const handleShowUpdate = () => setUpdateStatut(true);
  const _userAdd = () => {
    history.push(`${COUNSELING_LIST}/limit/20/skip/0`, match?.params?.id);
  };
  const _userList = () => {
    history.push(`${USER_LIST}/limit/20/skip/0`);
  };
  const _userEdit = (id) => {
    // history.push(`${USER_EDIT}/${id}`, id);
    history.push({
      pathname: `${USER_EDIT}/${id}`,
      state: { data: location?.state?.data, id },
    });
  };
  const _conseliDetail = (id) => {
    history.push(`${COUNSELING_DETAIL}/${id}`, id);
  };
  const _renderName = (e) => {
    let res = data?.users?.data?.filter((x) => x.id === e);
    if (res?.length > 0) return res[0]?.firstName + " " + res[0]?.lastName;
  };
  const _confrimeDelete = async () => {
    await deleteUser({ variables: { where: { id: match?.params?.id } } }).then(
      Swal.fire({
        icon: "success",
        title: "ການລຶບສຳເລັດ",
        showConfirmButton: false,
        timer: 1500,
      }).then(function () {
        history.push(`${USER_LIST}/limit/20/skip/0`);
        window.location.reload(true);
      })
    );
  };
  // update status
  const _updateConsultingStatus = async (values) => {
    let allIds = location?.state?.data;
    let status = "";
    if (values.status === "-") {
      status = null;
    } else {
      status = values.status;
    }
    const resUpdateConsulting = await updateStatusUser({
      variables: {
        data: {
          status: status,
        },
        where: { id: match?.params?.id },
      },
    }).then(
      Swal.fire({
        icon: "success",
        title: "ການອັບເດດສຳເລັດ",
        showConfirmButton: false,
        timer: 1500,
      }).then(function () {
        // history.push(`${USER_DETAIL}/${match?.params?.id}`, match?.params?.id);
        history.push({
          pathname: `${USER_DETAIL}/${match?.params?.id}`,
          state: { data: allIds }
        });
        window.location.reload(true);
      })
    );
  };
  const _renderCategory = (e) => {
    let res = consultingData?.consultingCategorys?.data?.filter(
      (x) => x.id === e
    );
    if (res?.length > 0) return res[0]?.name;
  };
  const _status = (e) => {
    if (e === "ONGOING_FR") {
      return <div style={{ color: "#F29702" }}>ONGOING_FR</div>;
    } else if (e === "ONGOING_MT") {
      return <div style={{ color: "#F29702" }}>ONGOING_MT</div>;
    } else if (e === "COMPLETED") {
      return <div style={{ color: "#48BB78" }}>COMPLETED</div>;
    } else if (e === "CANCELED") {
      return <div style={{ color: "red" }}>CANCELED</div>;
    } else if (e === null) {
      return "-";
    }
  };
  const _statusMain = (e) => {
    if (e === "UNSATISFIED") {
      return <div style={{ color: "#F29702" }}>ຍັງບໍ່ພຶງພໍໃຈ</div>;
    } else if (e === "SATISFIED") {
      return <div style={{ color: "green" }}>ພຶງພໍໃຈແລ້ວ</div>;
    } else if (e === "CANCELED") {
      return <div style={{ color: "red" }}>ຍົກເລິກ</div>;
    }
  };
  const [userData, setUserData] = useState({});
  useEffect(() => {
    const _resData = localStorage.getItem(USER_KEY);
    const _localJson = JSON.parse(_resData);
    setUserData(_localJson);
  }, []);


  const _onGoBackIds = () => {
    let currentId = match?.params?.id;
    let allIds = location?.state?.data;
    let currentIndexInArray = allIds.indexOf(currentId);
    if (currentIndexInArray > 0) {
      let nextConsultingId = allIds[currentIndexInArray - 1];
      history.push({
        pathname: `${USER_DETAIL}/${nextConsultingId}`,
        state: { data: allIds }
      });
    } else {
      let nextId = allIds.length - 1;
      history.push({
        pathname: `${USER_DETAIL}/${allIds[nextId]}`,
        state: { data: allIds }
      });
    }
  }

  const _onGoNextIds = () => {
    let currentId = match?.params?.id;
    let allIds = location?.state?.data;
    let currentIndexInArray = allIds.indexOf(currentId);
    if ((currentIndexInArray + 1) < allIds.length) {
      let nextConsultingId = allIds[currentIndexInArray + 1];
      history.push({
        pathname: `${USER_DETAIL}/${nextConsultingId}`,
        state: { data: allIds }
      });
    } else {
      let nextId = 0;
      history.push({
        pathname: `${USER_DETAIL}/${allIds[nextId]}`,
        state: { data: allIds }
      });
    }
  }
  let check = userData?.loginUser?.data?.role;
  return (
    <div
      style={{
        paddingLeft: 50,
        backgroundColor: "#F9F9F9",
        width: "100%",
        height: "100vh",
        overflowY: "scroll",
      }}
    >
      {/* Next and Back b uttons are over here */}
      <div style={{
        position: 'fixed',
        left: 100,
        zIndex: 1,
        top: "50%",
        display: location?.state?.data ? 'flex' :'none'
      }}>
        <button onClick={() => _onGoBackIds()} className="btn btn-outline-primary btn-stella" style={{
          borderRadius: 50,
          width: 50,
          height: 50
        }}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
      </div>
      <div style={{
        position: 'fixed',
        right: 45,
        zIndex: 1,
        top: "50%",
        display: location?.state?.data ? 'flex' :'none'
      }}>
        <button onClick={() => _onGoNextIds()} className="btn btn-outline-primary btn-stella" style={{
          borderRadius: 50,
          width: 50,
          height: 50
        }}>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
      <div
        style={{
          color: "#5C5C5C",
          backgroundColor: "#F9F9F9",
          height: 60,
          width: "100%",
          paddingTop: 25,
        }}
      >
        <div
          className="row"
          style={{
            paddingRight: 12,
            marginRight: 25,
            marginLeft: 25,
          }}
        >
          <div className="col" style={{ float: "left" }}>
            <a href="" onClick={() => _userList()} style={{ color: "#766AF0" }}>
              ຜູ້ໃຊ້ບໍລິການທັງໝົດ »{" "}
            </a>
            ຂໍ້ມູນຜູ້ໃຊ້ບໍລິການ »{" "}
            {dataUser?.user?.firstName ? dataUser?.user?.firstName : "-"}{" "}
            {dataUser?.user?.lastName ? dataUser?.user?.lastName : "-"}
          </div>
          <div className="col" style={{ textAlign: "right" }}>
            {check === "ADMIN" &&
              <button
                type="button"
                class="btn btn-outline-danger col-md-3 dlt"
                onClick={handleShow}
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  style={{ marginRight: 10 }}
                ></FontAwesomeIcon>{" "}
                ລຶບ
              </button>
            }
            <button
              type="button"
              className="btn btn-edit col-md-3"
              style={{ border: "solid 0px", outline: "none" }}
              onClick={() => _userEdit(dataUser?.user?.id)}
            >
              <FontAwesomeIcon
                icon={faEdit}
                style={{ marginRight: 10 }}
              ></FontAwesomeIcon>{" "}
              ແກ້ໄຂ
            </button>
            <button
              type="button"
              class="btn btn-secondary appColor"
              style={{ border: "solid 0px" }}
              onClick={() => _userAdd()}
            >
              <FontAwesomeIcon
                icon={faPlus}
                style={{ marginRight: 10 }}
              ></FontAwesomeIcon>{" "}
              ເພີ່ມການປືກສາ
            </button>
          </div>
        </div>
        <div
          className="row"
          style={{ paddingTop: 25, paddingLeft: 50, marginRight: 12 }}
        >
          <div
            style={{
              backgroundColor: "#FFFFFF",
              height: 300,
              width: "51%",
              borderRadius: 8,
            }}
          >
            <p style={{ padding: 20, marginLeft: 40, fontWeight: "bold" }}>
              ຂໍ້ມູນຜູ້ໃຊ້ບໍລິການ
            </p>
            <div className="row">
              {dataUser?.user?.image ? (
                <div className="col-md-5 center">
                  <Image
                    src={URL_FOR_SHOW_PHOTO + dataUser?.user?.image}
                    alt="stellaIcon"
                    style={{
                      height: 200,
                      width: 200,
                      padding: 5,
                      border: "solid 1px #766AF0",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              ) : (
                <div className="col-md-5 center">
                  <Image
                    src={ImageLogo + dataUser?.user?.image}
                    alt="stellaIcon"
                    style={{
                      height: 200,
                      width: 200,
                      padding: 5,
                      border: "solid 1px #766AF0",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              )}

              <div className="col-md-7">
                <div className="row">
                  <div className="col-6" style={{ color: "#646464" }}>
                    ຊື່ ແລະ ນາມສະກຸນ
                  </div>
                  <div className="col-6" style={{ color: "#868686" }}>
                    {dataUser?.user?.firstName
                      ? dataUser?.user?.firstName
                      : "-"}{" "}
                    <a>
                      {dataUser?.user?.lastName
                        ? dataUser?.user?.lastName
                        : "-"}
                    </a>
                  </div>
                </div>
                <div style={{ height: 10 }}></div>
                <div className="row">
                  <div className="col-6" style={{ color: "#646464" }}>
                    ເບີໂທ:
                  </div>
                  <div className="col-6" style={{ color: "#868686" }}>
                    {dataUser?.user?.phone ? dataUser?.user?.phone : "-"}
                  </div>
                </div>
                <div style={{ height: 10 }}></div>
                <div className="row">
                  <div className="col-6" style={{ color: "#646464" }}>
                    ສະຖານະ:
                  </div>
                  <div className="col-6" style={{ color: "#F29702" }}>
                    {_statusMain(dataUser?.user?.status)}
                  </div>
                </div>
                <div style={{ height: 30 }}></div>
                <button
                  className="btn appColor"
                  onClick={() => handleShowUpdate()}
                >
                  <FontAwesomeIcon icon={faSync} style={{ marginRight: 10 }} />
                  ອັບເດດສະຖານະ
                </button>
              </div>
              <div
                style={{
                  paddingTop: 40,
                  paddingLeft: 15,
                  width: "98%",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#FFFF",
                    padding: 20,
                    borderRadius: 8,
                  }}
                >
                  <div className="col-md-12">
                    <div className="row">
                      <div
                        className="col-12"
                        style={{ color: "#646464", fontWeight: "bold" }}
                      >
                        <FontAwesomeIcon
                          icon={faUser}
                          style={{ marginRight: 10, color: "#766AF0" }}
                        />
                        ຂໍ້ມູນທົ່ວໄປ
                      </div>
                    </div>
                    <div style={{ height: 10 }}></div>
                    <div style={{ paddingLeft: 30 }}>
                      <div className="row">
                        <div className="col-6" style={{ color: "#646464" }}>
                          ວັນເກີດ
                        </div>
                        <div className="col-6" style={{ color: "#868686" }}>
                          {formatDate(dataUser?.user?.birthday)
                            ? formatDate(dataUser?.user?.birthday)
                            : "-"} ( {dataUser?.user?.fixAge} )
                        </div>
                      </div>
                      <div style={{ height: 20 }}></div>
                      <div className="row">
                        <div className="col-6" style={{ color: "#646464" }}>
                          ເພດ
                        </div>
                        <div className="col-6" style={{ color: "#868686" }}>
                          {setGenders(
                            dataUser?.user?.gender
                              ? dataUser?.user?.gender
                              : "-"
                          )}
                        </div>
                      </div>
                      <div style={{ height: 20 }}></div>
                      <div className="row">
                        <div className="col-6" style={{ color: "#646464" }}>
                          ຊົນເຜົ່າ
                        </div>
                        <div className="col-6" style={{ color: "#868686" }}>
                          {dataUser?.user?.tribe ? dataUser?.user?.tribe : "-"}
                        </div>
                      </div>
                      <div style={{ height: 20 }}></div>
                      <div className="row">
                        <div className="col-6" style={{ color: "#646464" }}>
                          ສາດສະໜາ
                        </div>
                        <div className="col-6" style={{ color: "#868686" }}>
                          {dataUser?.user?.religion
                            ? dataUser?.user?.religion
                            : "-"}
                        </div>
                      </div>
                      <div style={{ height: 20 }}></div>
                      <div className="row">
                        <div className="col-6" style={{ color: "#646464" }}>
                          ທີ່ຢູ່ປະຈຸບັນ
                        </div>
                        <div className="col-6" style={{ color: "#868686" }}>
                          {dataUser?.user?.address?.village
                            ? dataUser?.user?.address?.village
                            : "-"}{" "}
                          {dataUser?.user?.address?.district
                            ? dataUser?.user?.address?.district
                            : "-"}{" "}
                          {_provincelevel(dataUser?.user?.address?.province)}
                        </div>
                      </div>
                      <div style={{ height: 20 }}></div>
                      <div className="row">
                        <div className="col-6" style={{ color: "#646464" }}>
                          ແຂວງເກີດ
                        </div>
                        <div className="col-6" style={{ color: "#868686" }}>
                          {_provincelevel(dataUser?.user?.provinceBorn)}
                        </div>
                      </div>
                      <div style={{ height: 20 }}></div>
                      <div className="row">
                        <div className="col-6" style={{ color: "#646464" }}>
                          ຄວາມບົກຜ່ອງ ທາງຮ່າງກາຍ
                        </div>
                        <div className="col-6" style={{ color: "#868686" }}>
                          {dataUser?.user?.accessibility
                            ? dataUser?.user?.accessibility
                            : "-"}
                        </div>
                      </div>
                    </div>
                    <div style={{ height: 20 }}></div>
                    <div className="row">
                      <div
                        className="col-12"
                        style={{ color: "#646464", fontWeight: "bold" }}
                      >
                        <FontAwesomeIcon
                          icon={faUserGraduate}
                          style={{ marginRight: 10, color: "#766AF0" }}
                        />
                        ຂໍ້ມູນການສຶກສາ
                      </div>
                    </div>
                    <div style={{ height: 10 }}></div>
                    <div style={{ paddingLeft: 30 }}>
                      <div className="row">
                        <div className="col-6" style={{ color: "#646464" }}>
                          ລະດັບການສຶກສາ
                        </div>
                        <div className="col-6" style={{ color: "#868686" }}>
                          {educationLevel(
                            dataUser?.user?.educationLevel
                              ? dataUser?.user?.educationLevel
                              : "-"
                          )}
                        </div>
                      </div>
                      <div style={{ height: 20 }}></div>
                      <div className="row">
                        <div className="col-6" style={{ color: "#646464" }}>
                          ສາຂາຮຽນ
                        </div>
                        <div className="col-6" style={{ color: "#868686" }}>
                          {dataUser?.user?.branch
                            ? dataUser?.user?.branch
                            : "-"}
                        </div>
                      </div>
                    </div>
                    <div style={{ height: 20 }}></div>
                    <div className="row">
                      <div
                        className="col-12"
                        style={{ color: "#646464", fontWeight: "bold" }}
                      >
                        <FontAwesomeIcon
                          icon={faUserTie}
                          style={{ marginRight: 10, color: "#766AF0" }}
                        />
                        ຂໍ້ມູນການເຮັດວຽກ
                      </div>
                    </div>
                    <div style={{ height: 10 }}></div>
                    <div style={{ paddingLeft: 30 }}>
                      <div className="row">
                        <div className="col-6" style={{ color: "#646464" }}>
                          ສະຖານະ
                        </div>
                        <div className="col-6" style={{ color: "#868686" }}>
                          {workingStatus(
                            dataUser?.user?.workingStatus
                              ? dataUser?.user?.workingStatus
                              : "-"
                          )}
                        </div>
                      </div>
                    </div>
                    <div style={{ height: 20 }}></div>
                    <div className="row">
                      <div
                        className="col-12"
                        style={{ color: "#646464", fontWeight: "bold" }}
                      >
                        <FontAwesomeIcon
                          icon={faFileAlt}
                          style={{ marginRight: 10, color: "#766AF0" }}
                        />
                        ຂໍ້ມູນເພີ່ມເຕີມ
                      </div>
                    </div>
                    <div style={{ height: 10 }}></div>
                    <div style={{ paddingLeft: 30 }}>
                      <div className="row">
                        <div className="col-6" style={{ color: "#646464" }}>
                          ຄຳອະທິບາຍ
                        </div>
                        <div className="col-6" style={{ color: "#868686" }}>
                          {dataUser?.user?.detail
                            ? dataUser?.user?.detail
                            : "-"}
                        </div>
                      </div>
                      <div style={{ height: 20 }}></div>
                      <div className="row">
                        <div className="col-6" style={{ color: "#646464" }}>
                          ໝາຍເຫດ
                        </div>
                        <div className="col-6" style={{ color: "#868686" }}>
                          {dataUser?.user?.note ? dataUser?.user?.note : "-"}
                        </div>
                      </div>
                      <div style={{ height: 20 }}></div>
                      <div className="row">
                        <div className="col-6" style={{ color: "#646464" }}>
                          ສ້າງໂດຍ
                        </div>
                        <div className="col-6" style={{ color: "#868686" }}>
                          <a>
                            {_renderName(
                              dataUser?.user?.createdBy
                                ? dataUser?.user?.createdBy
                                : "-"
                            )}
                          </a>
                          {formatDate(
                            dataUser?.user?.createdAt
                              ? dataUser?.user?.createdAt
                              : "-"
                          )}
                        </div>
                      </div>
                      <div style={{ height: 30 }}></div>
                      <div className="row">
                        <div className="col-6" style={{ color: "#646464" }}>
                          ແກ້ໄຂໂດຍ
                        </div>
                        <div className="col-6" style={{ color: "#868686" }}>
                          {_renderName(
                            dataUser?.user?.updatedBy
                              ? dataUser?.user?.updatedBy
                              : "-"
                          )}
                          <label style={{ margin: "10" }}>
                            {formatDate(
                              dataUser?.user?.updatedAt
                                ? dataUser?.user?.updatedAt
                                : "-"
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ height: 100, width: "2%" }}></div>
          <div
            style={{
              backgroundColor: "#FFFFFF",
              width: "45%",
              borderRadius: 8,
              padding: 32,
            }}
          >
            <div className="row">
              <div
                className="col-sm-7"
                style={{ marginLeft: 20, paddingTop: 20, fontWeight: "bold" }}
              >
                {" "}
                <FontAwesomeIcon
                  icon={faHistory}
                  style={{ marginRight: 10, color: "#766AF0" }}
                />
                ປະຫວັດການປືກສາ
              </div>
            </div>
            <div className="col-md-12" style={{ marginTop: 30 }}>
              <table class="table" style={{ color: "#646464" }}>
                <thead>
                  <tr>
                    <th>FirstResponder</th>
                    <th>Mentor</th>
                    <th>ໝວດ</th>
                    <th>ສະຖານະ</th>
                    <th>ມື້ນັດໝາຍ</th>
                  </tr>
                </thead>
                <tbody>
                  {consultingSData?.consultings?.data?.map((item, index) => {
                    return (
                      <tr onClick={() => _conseliDetail(item?.id)}>
                        <th>{_renderName(item?.firstResponderId)}</th>
                        <td> {_renderName(item?.mentorId)} </td>
                        <td>
                          <div
                            style={{
                              border: "solid 1px",
                              backgroundColor: "#EC6869",
                              color: "#FFFF",
                              textAlign: "center",
                              borderRadius: 5,
                            }}
                          >
                            {_renderCategory(item?.consultingCategoryId)}
                          </div>
                        </td>
                        <td style={{ color: "#F29702" }}>
                          {_status(item?.status)}
                        </td>
                        <td>{formatDateTime(item?.appointmentDate)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>ລຶບ ຜູ້ໃຊ້ບໍລິການ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row center">
            <div>ທ່ານຕ້ອງການລຶບ</div>
            <div
              style={{
                color: "red",
                fontWeight: "bold",
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              {dataUser?.user?.firstName}
            </div>
            <div>ນີ້ບໍ ?</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            ຍົກເລິກ
          </Button>
          <Button
            style={{ backgroundColor: "#766AF0" }}
            onClick={() => _confrimeDelete()}
          >
            ຢືນຢັນການລຶບ
          </Button>
        </Modal.Footer>
      </Modal>
      {/* update setup */}
      <Modal show={updateStatut} onHide={handleCloseUpdate}>
        <Modal.Header closeButton>
          <Modal.Title>ອັບເດດສະຖານະ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{}}
            validate={(values) => { }}
            onSubmit={(values, { setSubmitting }) => {
              _updateConsultingStatus(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div
                  class="row"
                  style={{ paddingBottom: 40, marginBottom: 40 }}
                >
                  <div class="col">
                    <div style={{ margin: 5, padding: 5 }}>
                      ສະຖານະ <a style={{ color: "red" }}> *</a>
                    </div>
                    <div class="input-group" style={{ margin: 0 }}>
                      <select
                        class="custom-select col-12"
                        id="status"
                        style={{
                          float: "center",
                          fontWeight: "bold",
                          margin: "5",
                        }}
                        type="text"
                        name="status"
                        onChange={handleChange}
                        value={values.status}
                      >
                        <option value="-">ເລືອກການອັບເດດສະຖານະ</option>
                        <option value="UNSATISFIED">ຍັງບໍ່ພຶງພໍໃຈ</option>
                        <option value="SATISFIED">ພຶງພໍໃຈແລ້ວ</option>
                        <option value="CANCELED">ຍົກເລິກ</option>
                      </select>
                    </div>
                  </div>
                </div>
                <Form>
                  <Row>
                    <Col>
                      <Button
                        style={{ marginLeft: 300, marginRight: 10 }}
                        variant="btn btn-outline-danger col-2"
                        onClick={handleCloseUpdate}
                      >
                        {" "}
                        ຍົກເລິກ
                      </Button>
                      <Button
                        type="submit"
                        variant="btn btn-secondary btn-app col-2"
                      >
                        ບັນທຶກ
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
}
