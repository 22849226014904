import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";
import Image from "react-bootstrap/Image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faBook,
  faBookmark,
  faList,
  faUser,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import {
  TITLE_HEADER,
  HEADER,
  BODY,
  URL_FOR_SHOW_PHOTO,
} from "../../../consts";
import { USER_DETAIL, USER_ADD } from "../../../consts/router";
import profileImage from "../../../image/profile.png";
import { formatDate, workingStatus } from "../../../helper/super";
import { USERS } from "../apollo";
import { Instagram } from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core";

export default function UserList() {
  const { history, location, match } = useReactRouter();
  const _limit = match.params.limit;
  const _skip = match.params.skip;

  // const [pages, setPages] = useState([]);
  const [totalPage, setTotalPage] = useState([]);

  const [selectedRole, setSelectedRole] = useState(["USER"]);
  const [selectedPhone, setSelectedPhone] = useState();
const [FilltterStatus, setFilltterStatus] = useState()
  const { loading, error, data } = useQuery(USERS, {
    variables: {
      where: {
        role_in: selectedRole,
        phone: selectedPhone,
        status: FilltterStatus
      },
      limit: parseInt(_limit),
      skip: parseInt(_skip) * parseInt(_limit),
    },
  });

  useEffect(() => {
    if (data?.users?.data?.length > 0) {
      _getArrayPageNumber();
    }
  }, [data]);

  const {
    loading: mainStatusLoading,
    error: mainStatusRrror,
    data: mainStatusData,
  } = useQuery(USERS);
  const _userDetail = (id) => {
    // history.push(`${USER_DETAIL}/${id}`, id);
    history.push({
      pathname: `${USER_DETAIL}/${id}`,
      state: { data: allUsersId }
    });
  };
  const _userAdd = () => {
    history.push(`${USER_ADD}`);
  };

  const _onPhoneChange = (e) => {
    const inputedPhone = e.target.value;
    setSelectedPhone(inputedPhone);
  };
  const _statusMain = (e) => {
    if (e === "UNSATISFIED") {
      return <div style={{ color: "#F29702" }}>ຍັງບໍ່ພຶງພໍໃຈ</div>;
    } else if (e === "SATISFIED") {
      return <div style={{ color: "green" }}>ພຶງພໍໃຈແລ້ວ</div>;
    } else if (e === "CANCELED") {
      return <div style={{ color: "red" }}>ຍົກເລິກ</div>;
    } else if (e === null) {
      return "-";
    }
  };
  const _renderMian = (e) => {
    let res = mainStatusData?.users?.data?.filter((x) => x?.status === e);
    if (res?.length > 0) return _statusMain(res[0]?.status);
  };
  const _getArrayPageNumber = () => {
    let rowPage = [];
    let _total = 0;
    if (data?.users?.total % parseInt(_limit) != 0) {
      _total = parseInt(data?.users?.total / parseInt(_limit)) + 1;
    } else {
      _total = parseInt(data?.users?.total / parseInt(_limit));
    }

    for (let i = 1; i <= _total; i++) {
      rowPage.push(i);
    }
    setTotalPage(rowPage);
  };

  const _changePage = (index) => {
    history.push("/user/limit/" + parseInt(_limit) + "/skip/" + index);
  };
  const _fillterStatus = (item) => {
    let status = item.target.value;
    if (!status) status = undefined;
    setFilltterStatus(status);
  }
  /***
  * to get all consultings data
  */
   const [allUsersId, setAllUsersId] = useState([]);
   useEffect(() => {
     if (data?.users?.data?.length > 0) {
       let dataId = data?.users?.data?.map(data => data.id);
       // console.log({data: data?.consultings?.data[200]})
       setAllUsersId([...dataId]);
     }
   }, [data]);
  return (
    <div style={TITLE_HEADER}>
      {/* EDIT Bosly */}
      <div
        style={{
          marginTop: 25,
          marginLeft: 25,
          marginRight: 25,
          padding: 32,
          background: "#FFFFFF",
          borderRadius: 4,
          opacity: 1,
          width: "auto",
        }}
      >
        <div className="row" style={{ padding: 10 }}>
          <div className="col-md-12" style={{ fontSize: "20px" }}>
            ຜູ້ໃຊ້ບໍລິການທັງໝົດ ({data?.users?.total})
          </div>
        </div>
        <div style={{ paddingBottom: 20, marginRight: 25 }}>
          
          <button
            type="button"
            className="btn btn-secondary btn-app"
            style={{ float: "right", margin: "5" }}
            onClick={() => _userAdd()}
          >
            <FontAwesomeIcon
              icon={faPlus}
              style={{ textAlign: "left" }}
              style={{ marginRight: 20 }}
            />{" "}
            ເພີ່ມຜູ້ໃຊ້ບໍລິການ{" "}
          </button>
          <input
            type="text"
            style={{ float: "right", marginRight: "5px", marginLeft: "5px" }}
            className="form-control col-3 empty"
            placeholder="&#xF002;  ຄົ້ນຫາເບີໂທ"
            onChange={(e) => _onPhoneChange(e)}
          />
          <select className="form-control col-3 right"
            
            onChange={(e) => _fillterStatus(e)}
          >
            <option value="">ທັງໝົດ</option>
            <option value="UNSATISFIED">ຍັງບໍ່ພຶງພໍໃຈ</option>
            <option value="SATISFIED">ພຶງພໍໃຈແລ້ວ</option>
            <option value="CANCELED">ຍົກເລິກ</option>
          </select>
        </div>

        <div className="col-md-12" style={{ marginTop: 30 }}>
          <table className="table table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>ຮູບພາບ</th>
                <th>ຊື່</th>
                <th>ສະຖານະການເຮັດວຽກ</th>
                <th>ເບີໂທຕິດຕໍ່</th>
                <th>ສະຖານະ</th>
                <th>ໝາຍເຫດ</th>
                <th>ວັນທີ່ສ້າງ</th>
              </tr>
            </thead>
            <tbody style={{ border: "1px solid #F7F7F7", opacity: 1 }}>
              {data?.users?.data?.map((data, index) => {
                return (
                  <tr key={index} onClick={() => _userDetail(data?.id)}>
                    <th>{index + 1 + parseInt(_limit) * parseInt(_skip)}</th>
                    {data?.image ? (
                      <td>
                        <Image
                          src={URL_FOR_SHOW_PHOTO + data?.image}
                          alt="stellaImage"
                          style={{
                            height: 40,
                            width: 40,
                            borderRadius: "50%",
                          }}
                        />
                      </td>
                    ) : (
                        <td>
                          <Image
                            src={profileImage + data?.image}
                            alt="stellaImage"
                            style={{
                              height: 40,
                              width: 40,
                              borderRadius: "50%",
                            }}
                          />
                        </td>
                      )}
                    <td>{data?.firstName ? data?.firstName : "-"}</td>
                    <td>{workingStatus(data?.workingStatus)}</td>
                    <td>{data?.phone ? data?.phone : "-"}</td>
                    <td>{_renderMian(data?.status)}</td>
                    <td>{data?.note ? data?.note : "-"}</td>
                    <td>{formatDate(data?.createdAt)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="row center">
            {totalPage?.map((numbersPages, index) => {
              return (
                <button
                  key={index}
                  onClick={() => _changePage(index)}
                  style={{
                    width: 30,
                    height: 30,
                    border: "1px solid #9999ff",
                    borderRadius: "10%",
                    justifyContent: "center",
                    textAlign: "center",
                    marginRight: 2,
                    backgroundColor:
                      parseInt(_skip) == index ? "#9999ff" : "#fff",
                    color: parseInt(_skip) == index ? "#fff" : "#000",
                  }}
                >
                  {numbersPages}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
//
