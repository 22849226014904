import React, { useState, useRef, useEffect } from "react";
import useReactRouter from "use-react-router";
import Image from "react-bootstrap/Image";
import ImageLogo from "../../../image/profile.png";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faPlus,
  faHistory,
  faUserTie,
  faSync,
  faUser,
  faUserGraduate,
  faFileAlt,
  faUserEdit,
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { URL_FOR_SHOW_PHOTO, USER_KEY } from "../../../consts";
import {
  STAFF_LIST,
  STAFF_EDIT,
  STAFF_ADD,
  COUNSELING_LIST,
  COUNSELING_DETAIL,
  STAFF_DETAIL,
} from "../../../consts/router";
import {
  USER,
  USERS,
  DELETE_USER,
  CONSULTING_CATEGORYS,
  CONSULTINGS,
  UPDATE_USER
} from "../apollo";
import {
  formatDate,
  setGenders,
  educationLevel,
  workingStatus,
  formatDateTime,
  _provincelevel,
} from "../../../helper/super";
import Modal from "react-bootstrap/Modal";
import { Button, Card, CardDeck, Col, Container } from "react-bootstrap";
import Swal from "sweetalert2";

const _colorRole = (role) => {
  if (role === "ADMIN") {
    return (
      <div className="btn btn-adm" style={{ fontSize: 15 }}>
        {" "}
        Admin{" "}
      </div>
    );
  } else if (role === "FIRST_RESPONDER") {
    return (
      <div
        className="btn"
        style={{ fontSize: 15, backgroundColor: "#FFD9D1", border: 0 }}
      >
        {" "}
        FirstResponder{" "}
      </div>
    );
  }
};

export default function StaffDetail() {
  const [userData, setUserData] = useState({});
  const [allUsersId, setAllUsersId] = useState([]);
  const [staffStatus, setStaffStatus] = useState(null);

  useEffect(() => {
    const _resData = localStorage.getItem(USER_KEY);

    const _localJson = JSON.parse(_resData);
    setUserData(_localJson);
  }, []);

  const { history, location, match } = useReactRouter();
  const [selectStatus, setSelectStatus] = useState();
  const {
    loading: ConsultingLoading,
    error: ConsultingError,
    data: ConsultingData,
  } = useQuery(CONSULTINGS, {
    variables: {
      where: {
        firstResponderId: match?.params?.id,
        status: selectStatus,
      },
    },
  });
  const { loading, error, data } = useQuery(USER, {
    variables: { where: { id: match?.params?.id } },
  });
  // console.log({data})
  const {
    loading: dataUserLoading,
    error: dataUserError,
    data: dataUser,
  } = useQuery(USERS);
  const [deteleUser, setDeteleUser] = useMutation(DELETE_USER);
  const {
    loading: consultingLoading,
    error: consultingError,
    data: consultingData,
  } = useQuery(CONSULTING_CATEGORYS);
  const _renderCategory = (e) => {
    let res = consultingData?.consultingCategorys?.data?.filter(
      (x) => x.id === e
    );
    if (res?.length > 0) return res[0]?.name;
  };

  // modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  /**
   * variables for editing modal
   * 
   */
  const [currentStaffWorkingStatus, setCurrentStaffWorkingStatus] = useState(null);
  const [editShow, setEditShow] = useState(false);
  const handleEditShown = () => {
    setCurrentStaffWorkingStatus(data?.user?.workingStatus);
    setEditShow(!editShow);
    setSelectStatus(null);
  };

  // history

  const _staffList = () => {
    history.push(`${STAFF_LIST}/limit/20/skip/0`);
  };
  const _staffEdit = () => {
    // history.push(`${STAFF_EDIT}/${match?.params?.id}`, match?.params?.id);
    history.push({
      pathname: `${STAFF_EDIT}/${match?.params?.id}`,
      state: { data: location?.state?.data, id: match?.params?.id },
    });
  };
  const _staffAdd = () => {
    history.push(`${STAFF_ADD}`);
  };
  const _consultingAdd = () => {
    history.push(`${COUNSELING_LIST}/limit/20/skip/0`, match?.params?.id);
  };
  const _conseliDetail = (id) => {
    history.push(`${COUNSELING_DETAIL}/${id}`, id);
  };

  const _renderName = (e) => {
    let res = dataUser?.users?.data?.filter((x) => x.id === e);
    if (res?.length > 0) {
      return res[0]?.firstName + " " + res[0]?.lastName;
    } else {
      return "-";
    }
  };
  // delete User
  const _confrimeDelete = async () => {
    await deteleUser({ variables: { where: { id: match?.params?.id } } }).then(
      Swal.fire({
        icon: "success",
        title: "ການລຶບສຳເລັດ",
        showConfirmButton: false,
        timer: 1500,
      }).then(function () {
        history.push(`${STAFF_LIST}/limit/20/skip/0`);
        window.location.reload(true);
      })
    );
  };
  const _status = (e) => {
    if (e === "ONGOING_FR") {
      return <div style={{ color: "#F29702" }}>ONGOING_FR</div>;
    } else if (e === "ONGOING_MT") {
      return <div style={{ color: "#F29702" }}>ONGOING_MT</div>;
    } else if (e === "COMPLETED") {
      return <div style={{ color: "#48BB78" }}>COMPLETED</div>;
    } else if (e === "CANCELED") {
      return <div style={{ color: "red" }}>CANCELED</div>;
    }
  };
  const STATUS = [
    { code: "ONGOING_FR", show: "ONGOING_FR" },
    { code: "ONGOING_MT", show: "ONGOING_MT" },
    { code: "COMPLETED", show: "COMPLETED" },
    { code: "CANCELED", show: "CANCELED" },
  ];
  const _onStatusChange = (e) => {
    let status = e.target.value;
    if (!status) status = undefined;
    setSelectStatus(status);
  };
  useEffect(() => {
    setAllUsersId(location?.state?.data)
  }, [location]);
  const [updateUser, setUpdateUser] = useMutation(UPDATE_USER);
  const _onUpdateStaffStatus = async () => {
    let allIds = location?.state?.data;
    if (staffStatus) {
      const resUpdateUser = await updateUser({
        variables: {
          data: {
            workingStatus: staffStatus
          },
          where: { id: match?.params?.id },
        },
      });
      if (resUpdateUser?.data?.updateUser?.id) {
        Swal.fire({
          icon: "success",
          title: "ການອັບເດດສຳເລັດ",
          showConfirmButton: false,
          timer: 1500,
        }).then(function () {
          // history.push(`${STAFF_DETAIL}/${match?.params?.id}`, match?.params?.id);
          history.push({
            pathname: `${STAFF_DETAIL}/${match?.params?.id}`,
            state: { data: allIds }
          });
          window.location.reload(true);
        });
      } else {
        Swal.fire({
          icon: "failed",
          title: "ການອັບເດດລົ້ມເຫລວ",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  }

  const _onGoBackIds = () => {
    let currentId = match?.params?.id;
    let allIds = location?.state?.data;
    let currentIndexInArray = allIds.indexOf(currentId);
    if (currentIndexInArray > 0) {
      let nextConsultingId = allIds[currentIndexInArray - 1];
      history.push({
        pathname: `${STAFF_DETAIL}/${nextConsultingId}`,
        state: { data: allIds }
      });
    } else {
      let nextId = allIds.length - 1;
      history.push({
        pathname: `${STAFF_DETAIL}/${allIds[nextId]}`,
        state: { data: allIds }
      });
    }
  }

  const _onGoNextIds = () => {
    let currentId = match?.params?.id;
    let allIds = location?.state?.data;
    let currentIndexInArray = allIds.indexOf(currentId);
    if ((currentIndexInArray + 1) < allIds.length) {
      let nextConsultingId = allIds[currentIndexInArray + 1];
      history.push({
        pathname: `${STAFF_DETAIL}/${nextConsultingId}`,
        state: { data: allIds }
      });
    } else {
      let nextId = 0;
      history.push({
        pathname: `${STAFF_DETAIL}/${allIds[nextId]}`,
        state: { data: allIds }
      });
    }
  }

  let checkRole = userData?.loginUser?.data?.role;
  return (
    <div
      style={{
        paddingLeft: 50,
        backgroundColor: "#F9F9F9",
        width: "100%",
        height: "200vh",
      }}
    >
      {/* Next and Back b uttons are over here */}
      <div style={{
        position: 'fixed',
        left: 100,
        zIndex: 1,
        top: "50%",
        display: location?.state?.data ? 'flex' :'none'
        
      }}>
        <button onClick={() => _onGoBackIds()} className="btn btn-outline-primary  btn-stella" style={{
          borderRadius: 50,
          width: 50,
          height: 50
        }}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
      </div>
      <div style={{
        position: 'fixed',
        right: 45,
        zIndex: 1,
        top: "50%",
        display: location?.state?.data ? 'flex' :'none'

      }}>
        <button onClick={() => _onGoNextIds()} className="btn btn-outline-primary  btn-stella" style={{
          borderRadius: 50,
          width: 50,
          height: 50
        }}>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
      {data?.user?.role === "ADMIN" && (
        <div
          style={{
            color: "#5C5C5C",
            backgroundColor: "#F9F9F9",
            height: 60,
            padding: 40,
            // paddingLeft: 45,

            fontSize: 16,
          }}
        >
          {/* <div className="container-fluid"> */}
          <div className="row">
            <div className="col" style={{ float: "left" }}>
              <a href="" onClick={() => _staffList()}>
                ພະນັກງານທັງໝົດ »{" "}
              </a>
              ລາຍລະອຽດ » {data?.user?.firstName + " " + data?.user?.lastName}
            </div>
            <div className="col" style={{ textAlign: "right" }}>
              {checkRole === "ADMIN" && (
                <button
                  type="button"
                  className="btn btn-outline-danger col-md-3 dlt"
                  onClick={handleShow}
                >
                  <FontAwesomeIcon
                    icon={faTrash}
                    style={{ marginRight: 10 }}
                  ></FontAwesomeIcon>{" "}
                  ລຶບ
                </button>
              )}
              {checkRole === "ADMIN" && (
                <button
                  type="button"
                  className="btn btn-edit col-md-3"
                  style={{
                    border: "solid 0px",
                    outline: "none",
                  }}
                  onClick={() => _staffEdit()}
                >
                  <FontAwesomeIcon
                    icon={faEdit}
                    style={{ marginRight: 10 }}
                  ></FontAwesomeIcon>{" "}
                  ແກ້ໄຂ
                </button>
              )}

              {checkRole === "ADMIN" && (
                <button
                  type="button"
                  className="btn btn-secondary appColor col-md-3"
                  style={{ border: "solid 0px" }}
                  onClick={() => _staffAdd()}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{ marginRight: 10 }}
                  ></FontAwesomeIcon>{" "}
                  ເພີ່ມພະນັກງານ
                </button>
              )}
            </div>
          </div>
          {/* </div> */}
          <div style={{ height: 10 }}></div>
          <CardDeck>
            <Card>
              <Card.Body>
                <div className="row">
                  <div className="col-4">
                    <div
                      style={{ fontWeight: "bold", padding: 10, fontSize: 20 }}
                    >
                      ລາຍລະອຽດ
                    </div>

                    <div className=" center">
                      {data?.user?.image ? (
                        <div className="col-md-5 center">
                          <Image
                            src={URL_FOR_SHOW_PHOTO + data?.user?.image}
                            alt="stellaIcon"
                            style={{
                              height: 200,
                              width: 200,
                              padding: 5,
                              border: "solid 1px #766AF0",
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                      ) : (
                        <div className="col-md-5 center">
                          <Image
                            src={ImageLogo + data?.user?.image}
                            alt="stellaIcon"
                            style={{
                              height: 200,
                              width: 200,
                              padding: 5,
                              border: "solid 1px #766AF0",
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                      )}
                    </div>

                    <div style={{ height: 20 }}></div>
                  </div>
                  <div className="col-4 row" style={{ alignContent: "center" }}>
                    <div className="col-6" style={{ padding: 10 }}>
                      User ID :
                    </div>
                    <div
                      className="col-6"
                      style={{ padding: 10 }}
                      style={{ color: "#868686" }}
                    >
                      {data?.user?.userId ? data?.user?.userId : "-"}
                    </div>
                    <div style={{ height: 20 }}></div>
                    <div className="col-6" style={{ padding: 10 }}>
                      ຊື່ ແລະ ນາມສະກຸນ :
                    </div>
                    <div
                      className="col-6"
                      style={{ padding: 10 }}
                      style={{ color: "#868686" }}
                    >
                      {data?.user?.firstName ? data?.user?.firstName : "-"}{" "}
                      {data?.user?.lastName ? data?.user?.lastName : "-"}
                    </div>
                    <div style={{ height: 10 }}></div>
                    <div className="col-6" style={{ padding: 10 }}>
                      ເບີໂທ :
                    </div>
                    <div
                      className="col-6"
                      style={{ padding: 10 }}
                      style={{ color: "#868686" }}
                    >
                      {data?.user?.phone ? data?.user?.phone : "-"}
                    </div>
                  </div>
                  <div className="col-4 row" style={{ alignContent: "center" }}>
                    <div className="col-6" style={{ padding: 10 }}>
                      Role :
                    </div>
                    <div
                      className="col-6"
                      style={{ padding: 10, color: "#868686" }}
                    >
                      {_colorRole(data?.user?.role ? data?.user?.role : "-")}
                    </div>
                    <div style={{ height: 10 }}></div>
                    <div className="col-6" style={{ padding: 10 }}>
                      ສ້າງໂດຍ :
                    </div>
                    <div
                      className="col-6"
                      style={{ padding: 10 }}
                      style={{ color: "#868686" }}
                    >
                      {_renderName(
                        data?.user?.createdBy ? data?.user?.createdBy : "-"
                      )}
                    </div>
                    <div style={{ height: 10 }}></div>
                    <div className="col-6" style={{ padding: 10 }}>
                      ອັບໂດຍ :
                    </div>
                    <div
                      className="col-6"
                      style={{ padding: 10 }}
                      style={{ color: "#868686" }}
                    >
                      {_renderName(
                        data?.user?.updatedBy ? data?.user?.updatedBy : "-"
                      )}
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </CardDeck>
          {/* <div style={{ height: 10 }}></div> */}
          <div className="row" style={{ padding: 16 }}>
            {/* ============================>>>>>>>>> */}
          </div>
          <CardDeck>
            <Card col-6>
              <Card.Body>
                <div style={{ fontWeight: "bold", fontSize: 18 }}>
                  {" "}
                  <FontAwesomeIcon
                    icon={faUser}
                    style={{ marginRight: 10, color: "#766AF0" }}
                  />
                  ຂໍ້ມູນທົ່ວໄປ
                </div>
                <div className="row" style={{ padding: 20 }}>
                  <div className="col-6" style={{ padding: 5 }}>
                    ວັນເກີດ :
                  </div>
                  <div
                    className="col-6"
                    style={{ padding: 5 }}
                    style={{ color: "#868686" }}
                  >
                    {formatDate(data?.user?.birthday)} ( {data?.user?.fixAge} )
                  </div>
                  <div className="col-6" style={{ padding: 5 }}>
                    ເພດ :
                  </div>
                  <div
                    className="col-6"
                    style={{ padding: 5 }}
                    style={{ color: "#868686" }}
                  >
                    {setGenders(data?.user?.gender)}
                  </div>
                  <div className="col-6" style={{ padding: 5 }}>
                    ຊົນເຜົ່າ :
                  </div>
                  <div
                    className="col-6"
                    style={{ padding: 5 }}
                    style={{ color: "#868686" }}
                  >
                    {data?.user?.tribe}
                  </div>
                  <div className="col-6" style={{ padding: 5 }}>
                    ສາດສະໜາ :
                  </div>
                  <div
                    className="col-6"
                    style={{ padding: 5 }}
                    style={{ color: "#868686" }}
                  >
                    {data?.user?.religion}
                  </div>
                  <div className="col-6" style={{ padding: 5 }}>
                    ທີ່ຢູ່ປະຈຸບັນ :
                  </div>
                  <div
                    className="col-6"
                    style={{ padding: 5 }}
                    style={{ color: "#868686", fontSize: 14 }}
                  >
                    {data?.user?.address?.village
                      ? data?.user?.address?.village
                      : ""}{" "}
                    {data?.user?.address?.district
                      ? data?.user?.address?.district
                      : ""}{" "}
                    {_provincelevel(data?.user?.address?.province)}
                  </div>
                  {/* <div className="col-6" style={{ padding: 5 }}>
                    ແຂວງເກີດ :
                  </div>
                  <div
                    className="col-6"
                    style={{ padding: 5 }}
                    style={{ color: "#868686" }}
                  >
                    {_provincelevel(data?.user?.provinceBorn)}
                  </div> */}
                  <div className="col-6" style={{ padding: 5 }}>
                    ຄວາມບົກຜ່ອງ ທາງຮ່າງກາຍ :
                  </div>
                  <div
                    className="col-6"
                    style={{ padding: 5 }}
                    style={{ color: "#868686" }}
                  >
                    {data?.user?.accessibility}
                  </div>
                </div>
              </Card.Body>
            </Card>
            <div style={{ height: 10 }}></div>
            {/* =========================>>> */}
            <Card col-6>
              <Card.Body>
                <div style={{ fontWeight: "bold", fontSize: 18 }}>
                  {" "}
                  <FontAwesomeIcon
                    icon={faUserGraduate}
                    style={{ marginRight: 10, color: "#766AF0" }}
                  />
                  ຂໍ້ມູນທົ່ວໄປ
                </div>
                <div className="row" style={{ padding: 20 }}>
                  <div className="col-6" style={{ padding: 5 }}>
                    ລະດັບການສຶກສາ :
                  </div>
                  <div
                    className="col-6"
                    style={{ padding: 5 }}
                    style={{ color: "#868686" }}
                  >
                    {educationLevel(data?.user?.educationLevel)}
                  </div>
                  <div className="col-6" style={{ padding: 5 }}>
                    ສາຂາຮຽນ :
                  </div>
                  <div
                    className="col-6"
                    style={{ padding: 5 }}
                    style={{ color: "#868686" }}
                  >
                    {data?.user?.branch}
                  </div>
                </div>
                <div style={{ fontWeight: "bold", fontSize: 18 }}>
                  {" "}
                  <FontAwesomeIcon
                    icon={faUserTie}
                    style={{ marginRight: 10, color: "#766AF0" }}
                  />
                  ຂໍ້ມູນການເຮັດວຽກ
                </div>
                <div className="row" style={{ padding: 20 }}>
                  <div className="col-6" style={{ padding: 5 }}>
                    ສະຖານະ :
                  </div>
                  <div
                    className="col-6"
                    style={{ padding: 5 }}
                    style={{ color: "#868686" }}
                  >
                    {workingStatus(data?.user?.workingStatus)}
                  </div>
                </div>
                <div style={{ fontWeight: "bold", fontSize: 18 }}>
                  {" "}
                  <FontAwesomeIcon
                    icon={faFileAlt}
                    style={{ marginRight: 10, color: "#766AF0" }}
                  />
                  ບໍລິສັດ
                </div>
                <div className="row" style={{ padding: 20 }}>
                  <div className="col-6" style={{ padding: 5 }}>
                    ຄຳອະທິບາຍ :
                  </div>
                  <div
                    className="col-6"
                    style={{ padding: 5 }}
                    style={{ color: "#868686" }}
                  >
                    {data?.user?.detail}
                  </div>
                  <div className="col-6" style={{ padding: 5 }}>
                    ໝາຍເຫດ :
                  </div>
                  <div
                    className="col-6"
                    style={{ padding: 5 }}
                    style={{ color: "#868686" }}
                  >
                    {data?.user?.note}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </CardDeck>
          {/* <div style={{ height: 10 }}></div>
          <div className="row col-md-12" style={{ paddingTop: 40, paddingLeft: 50, backgroundColor: "red" }}>

          </div> */}
        </div>
      )}

      {/* ========================================= FIRST_RESPONDER ================================================= */}
      {data?.user?.role === "FIRST_RESPONDER" && (
        <div
          style={{
            color: "#5C5C5C",
            backgroundColor: "#F9F9F9",
            height: 60,
            width: "100%",
            paddingTop: 30,
            paddingLeft: 5,
            fontSize: 16,
          }}
        >
          <div
            className="row"
            style={{
              paddingRight: 12,
              marginRight: 12,
              marginLeft: 1,
              paddingLeft: 30,
            }}
          >
            <div className="col-4" style={{ float: "left", }}>
              <a href="" onClick={() => _staffList()}>
                ພະນັກງານທັງໝົດ »{" "}
              </a>
              ລາຍລະອຽດ » {data?.user?.firstName + " " + data?.user?.lastName}
            </div>
            <div className="col-8" style={{ textAlign: "right" }}>
              {checkRole === "ADMIN" && (
                <button
                  type="button"
                  className="btn btn-outline-primary col-md-2 dlt"
                  onClick={handleEditShown}
                  style={{
                    minWidth: 200
                  }}
                >
                  <FontAwesomeIcon
                    icon={faUserEdit}
                    style={{ marginRight: 0 }}
                  ></FontAwesomeIcon>{" "}
                  ປ່ຽນສະຖານະ
                </button>
              )}
              {checkRole === "ADMIN" && (
                <button
                  type="button"
                  className="btn btn-outline-danger col-md-2 dlt"
                  onClick={handleShow}
                  style={{
                    minWidth: 200
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTrash}
                    style={{ marginRight: 10 }}
                  ></FontAwesomeIcon>{" "}
                  ລຶບ
                </button>
              )}
              {userData?.loginUser?.data?.id === data?.user?.id ? (
                <button
                  type="button"
                  className="btn btn-edit col-md-2"
                  style={{ border: "solid 0px", outline: "none" }}
                  onClick={() => _staffEdit()}
                  style={{
                    minWidth: 200
                  }}
                >
                  <FontAwesomeIcon
                    icon={faEdit}
                    style={{ marginRight: 10 }}
                  ></FontAwesomeIcon>{" "}
                  ແກ້ໄຂ
                </button>
              ) : userData?.loginUser?.data?.role === "ADMIN" ? (
                <button
                  type="button"
                  className="btn btn-edit col-md-2"
                  style={{ border: "solid 0px", outline: "none", minWidth: 200 }}
                  onClick={() => _staffEdit()}
                >
                  <FontAwesomeIcon
                    icon={faEdit}
                    style={{ marginRight: 10 }}
                  ></FontAwesomeIcon>{" "}
                  ແກ້ໄຂ
                </button>
              ) : (
                ""
              )}
              {checkRole === "ADMIN" && (
                <button
                  type="button"
                  className="btn btn-secondary appColor col-md-3"
                  style={{ border: "solid 0px", minWidth: 200 }}
                  onClick={() => _consultingAdd()}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{ marginRight: 10 }}
                  ></FontAwesomeIcon>{" "}
                  ເພີ່ມນັດການປຶກສາ
                </button>
              )}
            </div>
          </div>

          <div className="row" style={{ paddingTop: 20, paddingLeft: 50 }}>
            <div
              style={{
                backgroundColor: "#FFFFFF",
                height: 330,
                width: "50%",
                borderRadius: 8,
              }}
            >
              <p style={{ padding: 32, fontWeight: "bold" }}>ລາຍລະອຽດ</p>
              <div className="row">
                {data?.user?.image ? (
                  <div className="col-md-5 center">
                    <Image
                      src={URL_FOR_SHOW_PHOTO + data?.user?.image}
                      alt="stellaIcon"
                      style={{
                        height: 200,
                        width: 200,
                        padding: 5,
                        border: "solid 1px #766AF0",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                ) : (
                  <div className="col-md-5 center">
                    <Image
                      src={ImageLogo + data?.user?.image}
                      alt="stellaIcon"
                      style={{
                        height: 200,
                        width: 200,
                        padding: 5,
                        border: "solid 1px #766AF0",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                )}
                <div className="col-md-7">
                  <div style={{ height: 20 }}></div>
                  <div className="row">
                    <div className="col-5" style={{ color: "#646464" }}>
                      ຊື່ ແລະ ນາມສະກຸນ
                    </div>
                    <div className="col-7" style={{ color: "#868686" }}>
                      {/* {data?.user?.firstName + " " + data?.user?.lastName} */}
                      {data?.user?.firstName ? data?.user?.firstName : "-"}{" "}
                      {data?.user?.lastName ? data?.user?.lastName : "-"}
                    </div>
                  </div>
                  <div style={{ height: 10 }}></div>
                  <div className="row">
                    <div className="col-6" style={{ color: "#646464" }}>
                      UserId:
                    </div>
                    <div className="col-6" style={{ color: "#868686" }}>
                      {data?.user?.userId ? data?.user?.userId : "-"}
                    </div>
                  </div>
                  <div style={{ height: 10 }}></div>
                  <div className="row">
                    <div className="col-6" style={{ color: "#646464" }}>
                      ເບີໂທ:
                    </div>
                    <div className="col-6" style={{ color: "#868686" }}>
                      {data?.user?.phone ? data?.user?.phone : "-"}
                    </div>
                  </div>
                  <div style={{ height: 10 }}></div>
                  <div className="row">
                    <div className="col-6" style={{ color: "#646464" }}>
                      Role:
                    </div>
                    <div className="col-6" style={{ color: "#F29702" }}>
                      {_colorRole(data?.user?.role)}
                    </div>
                  </div>
                  <div style={{ height: 10 }}></div>
                  <div className="row">
                    <div className="col-6" style={{ color: "#646464" }}>
                      ສະຖານະການເຮັດວຽກ:
                    </div>
                    <div className="col-6" style={{ color: "#F29702" }}>
                      {(data?.user?.workingStatus)}
                    </div>
                  </div>
                  <div style={{ marginTop: 100 }}></div>
                  <div style={{ height: 10 }}></div>
                </div>
                <div
                  style={{
                    backgroundColor: "#fff",
                    marginLeft: 18,
                    padding: 32,
                    borderRadius: 8,
                    width: "96%",
                  }}
                >
                  <div className="row">
                    <div
                      className="col-12"
                      style={{ color: "#646464", fontWeight: "bold" }}
                    >
                      <FontAwesomeIcon
                        icon={faUser}
                        style={{ marginRight: 10, color: "#766AF0" }}
                      />
                      ຂໍ້ມູນທົ່ວໄປ
                    </div>
                  </div>
                  <div style={{ height: 10 }}></div>
                  <div style={{ paddingLeft: 30 }}>
                    <div className="row">
                      <div className="col-6" style={{ color: "#646464" }}>
                        ວັນເກີດ
                      </div>
                      <div className="col-6" style={{ color: "#868686" }}>
                        {formatDate(data?.user?.birthday)} ( {data?.user?.fixAge} )
                      </div>
                    </div>
                    <div style={{ height: 20 }}></div>
                    <div className="row">
                      <div className="col-6" style={{ color: "#646464" }}>
                        ເພດ
                      </div>
                      <div className="col-6" style={{ color: "#868686" }}>
                        {setGenders(data?.user?.gender)}
                      </div>
                    </div>
                    <div style={{ height: 20 }}></div>
                    <div className="row">
                      <div className="col-6" style={{ color: "#646464" }}>
                        ຊົນເຜົ່າ
                      </div>
                      <div className="col-6" style={{ color: "#868686" }}>
                        {data?.user?.tribe ? data?.user?.tribe : "-"}
                      </div>
                    </div>
                    <div style={{ height: 20 }}></div>
                    <div className="row">
                      <div className="col-6" style={{ color: "#646464" }}>
                        ສາດສະໜາ
                      </div>
                      <div className="col-6" style={{ color: "#868686" }}>
                        {data?.user?.religion ? data?.user?.religion : "-"}
                      </div>
                    </div>
                    <div style={{ height: 20 }}></div>
                    <div className="row">
                      <div className="col-6" style={{ color: "#646464" }}>
                        ທີ່ຢູ່ປະຈຸບັນ
                      </div>
                      <div className="col-6" style={{ color: "#868686" }}>
                        {data?.user?.address?.village
                          ? data?.user?.address?.village
                          : ""}{" "}
                        {data?.user?.address?.district
                          ? data?.user?.address?.district
                          : ""}{" "}
                        {_provincelevel(data?.user?.address?.province)}
                      </div>
                    </div>
                    <div style={{ height: 20 }}></div>
                    <div className="row">
                      <div className="col-6" style={{ color: "#646464" }}>
                        ແຂວງເກີດ
                      </div>
                      <div className="col-6" style={{ color: "#868686" }}>
                        {_provincelevel(data?.user?.provinceBorn)}
                      </div>
                    </div>
                    <div style={{ height: 20 }}></div>
                    <div className="row">
                      <div className="col-6" style={{ color: "#646464" }}>
                        ຄວາມບົກຜ່ອງ ທາງຮ່າງກາຍ
                      </div>
                      <div className="col-6" style={{ color: "#868686" }}>
                        {data?.user?.accessibility
                          ? data?.user?.accessibility
                          : "-"}
                      </div>
                    </div>
                  </div>
                  <div style={{ height: 20 }}></div>
                  <div className="row">
                    <div
                      className="col-12"
                      style={{ color: "#646464", fontWeight: "bold" }}
                    >
                      <FontAwesomeIcon
                        icon={faUserGraduate}
                        style={{ marginRight: 10, color: "#766AF0" }}
                      />
                      ຂໍ້ມູນການສຶກສາ
                    </div>
                  </div>
                  <div style={{ height: 10 }}></div>
                  <div style={{ paddingLeft: 30 }}>
                    <div className="row">
                      <div className="col-6" style={{ color: "#646464" }}>
                        ລະດັບການສຶກສາ
                      </div>
                      <div className="col-6" style={{ color: "#868686" }}>
                        {educationLevel(data?.user?.educationLevel)}
                      </div>
                    </div>
                    <div style={{ height: 20 }}></div>
                    <div className="row">
                      <div className="col-6" style={{ color: "#646464" }}>
                        ສາຂາຮຽນ
                      </div>
                      <div className="col-6" style={{ color: "#868686" }}>
                        {data?.user?.branch ? data?.user?.branch : "-"}
                      </div>
                    </div>
                  </div>
                  <div style={{ height: 20 }}></div>
                  <div className="row">
                    <div
                      className="col-12"
                      style={{ color: "#646464", fontWeight: "bold" }}
                    >
                      <FontAwesomeIcon
                        icon={faUserTie}
                        style={{ marginRight: 10, color: "#766AF0" }}
                      />
                      ຂໍ້ມູນການເຮັດວຽກ
                    </div>
                  </div>
                  <div style={{ height: 10 }}></div>
                  <div style={{ paddingLeft: 30 }}>
                    <div className="row">
                      <div className="col-6" style={{ color: "#646464" }}>
                        ສະຖານະ
                      </div>
                      <div className="col-6" style={{ color: "#868686" }}>
                        {workingStatus(data?.user?.workingStatus)}
                      </div>
                    </div>
                  </div>
                  <div style={{ height: 20 }}></div>
                  <div className="row">
                    <div
                      className="col-12"
                      style={{ color: "#646464", fontWeight: "bold" }}
                    >
                      <FontAwesomeIcon
                        icon={faFileAlt}
                        style={{ marginRight: 10, color: "#766AF0" }}
                      />
                      ຂໍ້ມູນເພີ່ມເຕີມ
                    </div>
                  </div>
                  <div style={{ height: 10 }}></div>
                  <div style={{ paddingLeft: 30 }}>
                    <div className="row">
                      <div className="col-6" style={{ color: "#646464" }}>
                        ຄຳອະທິບາຍ
                      </div>
                      <div className="col-6" style={{ color: "#868686" }}>
                        {data?.user?.detail ? data?.user?.detail : "-"}
                      </div>
                    </div>
                    <div style={{ height: 20 }}></div>
                    <div className="row">
                      <div className="col-6" style={{ color: "#646464" }}>
                        ໝາຍເຫດ
                      </div>
                      <div className="col-6" style={{ color: "#868686" }}>
                        {data?.user?.note ? data?.user?.note : "-"}
                      </div>
                    </div>
                    <div style={{ height: 20 }}></div>
                    <div className="row">
                      <div className="col-6" style={{ color: "#646464" }}>
                        ສ້າງໂດຍ
                      </div>
                      <div className="col-6" style={{ color: "#868686" }}>
                        {_renderName(data?.user?.createdBy)}
                      </div>
                    </div>
                    <div style={{ height: 20 }}></div>
                    <div className="row">
                      <div className="col-6" style={{ color: "#646464" }}>
                        ແກ້ໄຂໂດຍ
                      </div>
                      <div className="col-6" style={{ color: "#868686" }}>
                        {_renderName(data?.user?.updatedBy)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ height: 100, width: "2%" }}></div>
            <div
              style={{
                backgroundColor: "#fff",
                width: "45%",
                padding: 32,
                borderRadius: 8,
              }}
            >
              <div className="row">
                <div className="col-sm-7">
                  {" "}
                  <FontAwesomeIcon
                    icon={faHistory}
                    style={{ marginRight: 10, color: "#766AF0" }}
                  />
                  ປະຫວັດການປືກສາ
                </div>
                <div style={{ height: 40, padding: 20, width: 200 }}>
                  <select
                    name="cars"
                    id="cars"
                    onChange={(e) => _onStatusChange(e)}
                    className="btn btn-block appColorUserDetail"
                  >
                    <option value="">ເລືອກສະຖານະ</option>
                    {STATUS.map((st, index) => (
                      <option key={index} value={st.code}>
                        {st.show}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-12" style={{ marginTop: 30 }}>
                <table className="table" style={{ color: "#646464" }}>
                  <thead>
                    <tr>
                      <th>User</th>
                      <th>ໝວດ</th>
                      <th>ສະຖານະ</th>
                      <th>ມື້ນັດໝາຍ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ConsultingData?.consultings?.data?.map((item, index) => {
                      return (
                        <tr onClick={() => _conseliDetail(item?.id)} key={index}>
                          <th>{_renderName(item?.userId)}</th>
                          <td>
                            <div
                              style={{
                                border: "solid 1px",
                                backgroundColor: "#EC6869",
                                color: "#FFFF",
                                textAlign: "center",
                                borderRadius: 5,
                              }}
                            >
                              {_renderCategory(item?.consultingCategoryId)}
                            </div>
                          </td>
                          <td style={{ color: "#F29702" }}>
                            {_status(item?.status)}
                          </td>
                          <td> {formatDateTime(item?.appointmentDate)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* modal for deleting user */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="row center">
            <div>ທ່ານຕ້ອງການລຶບ User_id </div>
            <div style={{ color: "red", paddingLeft: 10, paddingRight: 10 }}>
              {data?.user?.userId}
            </div>
            <div>ນິ້ບໍ ?</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            ຍົກເລິກ
          </Button>
          <Button
            style={{ backgroundColor: "#766AF0" }}
            onClick={() => _confrimeDelete()}
          >
            ຢຶນຢັນການລຶບ
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for edit user status */}
      <Modal show={editShow} onHide={handleEditShown}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div style={{ fontWeight: 'bold', display: 'flex', flexDirection: 'row', justifyContent: 'left' }}>ອັບເດດ ສະຖານະການເຮັດວຽກ, {data?.user?.userId} </div>
          <div style={{
            paddingLeft: 30
          }}>
            <label onClick={() => setStaffStatus("STOPPED")}>
              <input
                type="radio"
                name="status"
                checked={(currentStaffWorkingStatus === "STOPPED" && staffStatus === null) ? true : staffStatus === "STOPPED" ? true : false} />
              ຢຸດພັກຊົ່ວຄາວ</label>
            <br />
            <label onClick={() => setStaffStatus("WORK")}>
              <input
                type="radio"
                name="status"
                checked={(currentStaffWorkingStatus !== "STOPPED" && staffStatus === null) ? true : staffStatus === "WORK" ? true : false} />
              ເຮັດປົກກະຕິ</label>
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            paddingTop: 50
          }}>
            <button
              type="button"
              className="btn btn-outline-danger col-md-2 dlt"
              onClick={handleEditShown}
            >ຍົກເລິກ</button>
            {" "}
            <button
              type="button"
              className="btn btn-primary col-md-2 dlt"
              onClick={_onUpdateStaffStatus}
            >ອັບເດດ</button>
          </div>
        </Modal.Body>

      </Modal>
    </div>
  );
}
