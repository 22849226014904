import React, { useState, useRef, useEffect } from "react";
import useReactRouter from "use-react-router";
import Image from "react-bootstrap/Image";
import ImageLogo from "../../../image/profile.png";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faUser,
  faUserGraduate,
} from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { ErrorValidationBox } from "../../../common/ErrorValidationBox";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { CONSULTING_CATEGORY, UPDATE_CONSULTING_CATEGORYS } from "../apollo";
import { Formik } from "formik";
import Swal from "sweetalert2";
import moment from "moment";
import axios from "axios";
import {
  TITLE_HEADER,
  HEADER,
  BODY_DISCOUNT_SHOPDETAIL,
  URL_FOR_SHOW_PHOTO,
} from "../../../consts";
import { USER_LIST, USER_DETAIL } from "../../../consts/router";
import { USERS, USER, UPDATE_USER, PRESINGED_URL } from "../apollo";
import { formatDateDash } from "../../../helper/super";

export default function UserEdit() {
  const { history, location, match } = useReactRouter();
  const { loading, error, data } = useQuery(USERS);
  const {
    loading: dataUserLoading,
    error: dataUserError,
    data: dataUser,
  } = useQuery(USER, {
    variables: { where: { id: match?.params?.id } },
  });
  const [genderData, setGenderData] = useState();
  const [selectedRole, setSelectedRole] = useState("ADMIN");
  const _userList = () => {
    history.push(`${USER_LIST}/limit/20/skip/0`);
  };
  const _userDetail = () => {
    // history.push(`${USER_DETAIL}/${match?.params?.id}`, match?.params?.id);
    history.push({
      pathname: `${USER_DETAIL}/${match?.params?.id}`,
      state: { data: location?.state?.data, id: match?.params?.id },
    });
  };
  const [updateUser, setUpdateUser] = useMutation(UPDATE_USER);
  const [uploadPhoto, setUploadPhoto] = useMutation(PRESINGED_URL);

  // year
  let newYear = [];
  let year = new Date();
  for (let i = 1990; i < year.getFullYear() + 1; i++) {
    newYear.push(i);
  }
  //month
  let newMonth = [];
  for (let i = 1; i < 13; i++) {
    newMonth.push(i);
  }
  //day
  let newDay = [];
  for (let i = 1; i < 32; i++) {
    newDay.push(i);
  }
  // get gender
  const [onChecked, setOnchecked] = useState(dataUser?.user?.gender);
  const handleChangeGender = async (gender) => {
    setGenderData(gender);
  };
  useEffect(() => {
    if (dataUser) {
      setGenderData(dataUser?.user?.gender);
    }
  }, [dataUser]);
  // upload pic
  const [resPhoto, setResPhoto] = useState();
  const [file, setFile] = useState("");
  const [imageLoading, setImageLoading] = useState();
  const handleUpload = async (event) => {
    setImageLoading("");
    try {
      setFile(event.target.files[0]);
      let formData = new FormData();
      let fileData = event.target.files[0].name;
      let fileDataBoole = event.target.files[0];
      const resUploadPhoto = await uploadPhoto({
        variables: { fileName: fileData },
      });
      let afterUpload = await axios({
        method: "put",
        url: resUploadPhoto.data.preSignedUrl.url,
        data: fileDataBoole,
        headers: {
          "Content-Type": " file/*; image/*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      });
      setResPhoto(afterUpload.config.data.name);
    } catch (error) { }
  };
  // lung jak upload leo pic ja ma so u nee
  const ImageThumb = ({ image }) => {
    return (
      <img
        src={URL.createObjectURL(image)}
        alt={image.name}
        style={{
          borderRadius: "50%",
          height: 300,
          width: 300,
        }}
      />
    );
  };
  const _updateUser = async (values) => {
    const newData = {
      firstName: values.firstName,
      lastName: values.lastName,
      phone: String(values.phone),
      birthday: values.birthday,
      gender: genderData,
      tribe: values.tribe,
      address: {
        village: values.village,
        district: values.district,
        province: values.province,
        detail: values.detaildAddress,
        note: values.noteAddress,
      },
      province: values.province,
      provinceBorn: values.provinceBorn,
      image: resPhoto,
      workingStatus: values.workingStatus,
      accessibility: values.accessibility,
      religion: values.religion,
      educationLevel: values.educationLevel,
      branch: values.branch,
      detail: values.detail,
      note: values.note,
    };
    const resUpdateUser = await updateUser({
      variables: {
        data: newData,
        where: { id: match?.params?.id },
      },
    });
    // Success OR Error popup
    if (resUpdateUser?.data?.updateUser?.id) {
      Swal.fire({
        icon: "success",
        title: "ການອັບເດດສຳເລັດ",
        showConfirmButton: false,
        timer: 1500,
      }).then(function () {
        // history.push(`${USER_DETAIL}/${match?.params?.id}`, match?.params?.id);
        history.push({
          pathname: `${USER_DETAIL}/${match?.params?.id}`,
          state: { data: location?.state?.data, id: match?.params?.id },
        });
        window.location.reload();
      });
    } else {
      Swal.fire({
        icon: "failed",
        title: "ການອັບເດດລົ້ມເຫລວ",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const _onChangeRole = (e) => {
    setSelectedRole(e.target.value);
  };
  const CounselingSchema = Yup.object().shape({
    birthday: Yup.string().required("ກະລຸນາປ້ອນ!"),
    workingStatus: Yup.string().required("ກະລຸນາເລືອກ!"),
    educationLevel: Yup.string().required("ກະລຸນາເລືອກ!"),
    phone: Yup.string().required("ກະລຸນາປ້ອນ!"),
    firstName: Yup.string().required("ກະລຸນາປ້ອນ!"),
    province: Yup.string().required("ກະລຸນາປ້ອນ!"),
  });
  return (
    <div style={TITLE_HEADER}>
      <h6 style={{ marginLeft: 25, marginBottom: 25 }}>
        <a href="" onClick={() => _userList()} style={{ color: "#766AF0" }}>
          ຜູ້ໃຊ້ບໍລິການທັງໝົດ »{" "}
        </a>
        <a href="" onClick={() => _userDetail()} style={{ color: "#766AF0" }}>
          ຂໍ້ມູນຜູ້ໃຊ້ບໍລິການ »{" "}
        </a>
        ແກ້ໄຂຂໍ້ມູນຜູ້ໃຊ້ບໍລິການ {dataUser?.user?.firstName}
      </h6>
      <div style={{ marginRight: 25, marginLeft: 25 }}>
        <div
          style={{
            padding: 20,
            backgroundColor: "#fff",
            borderRadius: 8,
          }}
        >
          {dataUser && (
            <Formik
              initialValues={{
                firstName: dataUser?.user?.firstName,
                lastName: dataUser?.user?.lastName,
                phone: dataUser?.user?.phone,
                detail: dataUser?.user?.detail,
                note: dataUser?.user?.note,
                birthday: formatDateDash(dataUser?.user?.birthday),
                gender: genderData,
                tribe: dataUser?.user?.tribe,
                provinceBorn: dataUser?.user?.provinceBorn,
                village: dataUser?.user?.address?.village,
                district: dataUser?.user?.address?.district,
                province: dataUser?.user?.address?.province,
                detaildAddress: dataUser?.user?.address?.detail,
                noteAddress: dataUser?.user?.address?.note,
                workingStatus: dataUser?.user?.workingStatus,
                accessibility: dataUser?.user?.accessibility,
                religion: dataUser?.user?.religion,
                educationLevel: dataUser?.user?.educationLevel,
                branch: dataUser?.user?.branch,
              }}
              validate={(values) => { }}
              onSubmit={(values, { setSubmitting }) => {
                _updateUser(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div style={{ padding: 5, marginLeft: 5, fontSize: "18px" }}>
                    <a style={{ marginRight: 40 }}>ແກ້ໄຂຂໍ້ມູນຜູ້ໃຊ້ບໍລິການ</a>
                  </div>
                  <div className="row" style={{ padding: 20 }}>
                    <div className="col-md-4">
                      <div className="row center">
                        <input
                          type="file"
                          id="file-upload"
                          onChange={handleUpload}
                          hidden
                        />
                        <label for="file-upload">
                          <div
                            style={{
                              backgroundColor: "#E4E4E4E4",
                              height: 300,
                              width: 300,
                              borderRadius: "50%",
                              cursor: "pointer",
                              display: "flex",
                            }}
                          >
                            {file ? (
                              <ImageThumb image={file} />
                            ) : (
                              <Image
                                src={URL_FOR_SHOW_PHOTO + dataUser?.user?.image}
                                alt="AeonIcon"
                                width="100"
                                height="100"
                                style={{
                                  height: 300,
                                  width: 300,
                                  borderRadius: "50%",
                                  cursor: "pointer",
                                  display: "flex",
                                }}
                              />
                            )}
                          </div>
                        </label>
                        {/* progass */}
                        {imageLoading ? (
                          <div class="progress" style={{ height: 20 }}>
                            <div
                              class="progress-bar"
                              role="progressbar"
                              style={{
                                width: `${imageLoading}%`,
                                backgroundColor: "#816aae",
                              }}
                              aria-valuenow={imageLoading}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              {imageLoading}%
                            </div>
                          </div>
                        ) : (
                          <div style={{ height: 20 }} />
                        )}
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div style={{ color: "#766AF0" }}>
                        <FontAwesomeIcon
                          icon={faUser}
                          style={{ marginRight: 10 }}
                        />
                        ຂໍ້ມູນສ່ວນຕົວ
                      </div>
                      <div class="form-row">
                        <div class="col">
                          <label>
                            ຊື່ <a style={{ color: "red" }}>*</a>
                          </label>
                          <div style={{ height: 10 }}></div>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="-"
                            name="firstName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.firstName}
                          />
                          <ErrorValidationBox
                            errors={errors}
                            fieldName={"firstName"}
                          />
                        </div>
                        <div class="col">
                          <label>ນາມສະກຸນ</label>
                          <div style={{ height: 10 }}></div>
                          <input
                            type="text"
                            class="form-control"
                            name="lastName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.lastName}
                          />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="col">
                          <label>
                            ເບີໂທ <a style={{ color: "red" }}>*</a>
                          </label>
                          <div style={{ height: 10 }}></div>
                          <input
                            type="number"
                            class="form-control"
                            placeholder="-"
                            name="phone"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phone}
                          />
                          <ErrorValidationBox
                            errors={errors}
                            fieldName={"phone"}
                          />
                        </div>
                      </div>
                      <label>
                        ວັນເດືອນປີເກີດ <a style={{ color: "red" }}>*</a>
                      </label>
                      <div style={{ height: 10 }}></div>
                      <div class="form-row">
                        <div class="col">
                          <div class="form-group mb-12">
                            <input
                              type="date"
                              class="form-control col-12"
                              style={{ marginRight: 20 }}
                              type="date"
                              name="birthday"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.birthday}
                            />
                          </div>
                          <ErrorValidationBox
                            errors={errors}
                            fieldName={"birthday"}
                          />
                        </div>
                      </div>
                      <label>ເພດ</label>
                      <div style={{ height: 10 }}></div>
                      <div class="form-row">
                        <div class="col">
                          <div
                            className="col-md-8"
                            style={{
                              justifyContent: "left",
                              fontFamily: "NotoSansLao",
                              marginBottom: 10,
                              paddingBottom: 10,
                            }}
                          >
                            <div class="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="customRadioInline1"
                                name="gender"
                                class="custom-control-input"
                                checked={genderData === "MALE"}
                                onChange={() => handleChangeGender("MALE")}
                              />
                              <label
                                class="custom-control-label"
                                for="customRadioInline1"
                              >
                                ຊາຍ
                              </label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="customRadioInline2"
                                name="gender"
                                class="custom-control-input"
                                checked={genderData === "FEMALE"}
                                onChange={() => handleChangeGender("FEMALE")}
                              />
                              <label
                                class="custom-control-label"
                                for="customRadioInline2"
                              >
                                ຍິງ
                              </label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="customRadioInline3"
                                name="gender"
                                class="custom-control-input"
                                checked={genderData === "OTHER"}
                                onChange={() => handleChangeGender("OTHER")}
                              />
                              <label
                                class="custom-control-label"
                                for="customRadioInline3"
                              >
                                ເພດອື່ນ
                              </label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id="customRadioInline4"
                                name="gender"
                                class="custom-control-input"
                                checked={genderData === "NO_GENDER"}
                                onChange={() => handleChangeGender("NO_GENDER")}
                              />
                              <label
                                class="custom-control-label"
                                for="customRadioInline4"
                              >
                                ບໍ່ລະບຸ
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="col">
                          <label>
                            ສະຖານະການເຮັດວຽກ <a style={{ color: "red" }}>*</a>
                          </label>
                          <div style={{ height: 10 }}></div>
                          <select
                            id="inputState"
                            className="form-control "
                            style={{ width: "100%" }}
                            type="text"
                            name="workingStatus"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.workingStatus}
                          >
                            <option disabled={true} selected={true}>
                              -
                            </option>
                            <option value="WORK">ເຮັດວຽກ</option>
                            <option value="STUDYING">ຮຽນ</option>
                            <option value="UNEMPLOYED">ຫ່ວາງງານ</option>
                            <option value="OTHER">ອື່ນໆ</option>
                          </select>
                        </div>
                        <div class="col">
                          <label>ຄວາມບົກຜ່ອງທາງຮ່າງກາຍ(ພິການ)</label>
                          <div style={{ height: 10 }}></div>
                          <input
                            type="text"
                            class="form-control"
                            name="accessibility"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.accessibility}
                            placeholder="ປ້ອນຄວາມບົກຜ່ອງ"
                          />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="col">
                          <label>ຊົນເຜົ່າ</label>
                          <div style={{ height: 10 }}></div>
                          <input
                            type="text"
                            class="form-control"
                            name="tribe"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.tribe}
                            placeholder="ປ້ອນຊົນເຜົ່າ"
                          />
                        </div>
                        <div class="col">
                          <label>ສາດສະໜາ</label>
                          <div style={{ height: 10 }}></div>
                          <input
                            type="text"
                            class="form-control"
                            name="religion"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.religion}
                            placeholder="ປ້ອນສາດສະໜາ"
                          />
                        </div>
                      </div>
                      <label>ທີ່ຢູ່ປະຈຸບັນ</label>
                      <div class="form-row">
                        <div class="col-6">
                          <div style={{ height: 10 }}></div>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="ປ້ອນບ້ານ"
                            name="village"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.village}
                          />
                        </div>
                        <div class="col-6">
                          <div style={{ height: 10 }}></div>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="ເມືອງ"
                            name="district"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.district}
                          />
                        </div>
                      </div>
                      <label>ແຂວງປະຈຸບັນ <a style={{ color: "red" }}>*</a></label>
                      <div class="form-row">
                        <div class="col-12">
                          <div style={{ height: 10 }}></div>
                          <select
                            id="inputState"
                            className="form-control "
                            style={{ width: "100%" }}
                            type="text"
                            name="province"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.province}
                          >
                            <option value="-" disabled={true} selected={true}>ເລືອກແຂວງ</option>
                            <option value="Phongsali">ຜົ້ງສາລີ</option>
                            <option value="LuangNamtha">ຫຼວງນ້ຳທາ</option>
                            <option value="Huoaphan">ຫົວພັນ</option>
                            <option value="Borkeo">ບໍ່ແກ້ວ</option>
                            <option value="Oudomxay">ອຸດົມໄຊ</option>
                            <option value="Sayaboury">ໄຊຍະບູລີ</option>
                            <option value="Xiengkhouang">ຊຽງຂວາງ</option>
                            <option value="LuangPrabang">ຫຼວງພະບາງ</option>
                            <option value="Vientiane">ວຽງຈັນ</option>
                            <option value="VientianeCapital">
                              ນະຄອນຫຼວງວຽງຈັນ
                            </option>
                            <option value="Borikhamsay">ບໍລິຄຳໄຊ</option>
                            <option value="Khammuoan">ຄຳມ່ວນ</option>
                            <option value="Savannakhet">ສະຫວັນນະເຂດ</option>
                            <option value="Salavanh">ສາລະວັນ</option>
                            <option value="Sekong">ເຊກອງ</option>
                            <option value="Attapue">ອັດຕະປື</option>
                            <option value="Champasak">ຈຳປາສັກ</option>
                            <option value="Saisomboun">ໄຊສົມບູນ</option>
                          </select>
                          <ErrorValidationBox
                            errors={errors}
                            fieldName={"province"}
                          />
                        </div>
                        <label style={{ marginLeft: 10 }}>ແຂວງເກີດ</label>
                        <div class="col-12">
                          <div style={{ height: 10 }}></div>
                          <select
                            id="provinceBorn"
                            className="form-control "
                            style={{ width: "100%" }}
                            type="text"
                            name="provinceBorn"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.provinceBorn}
                          >
                            <option value="-" disabled={true} selected={true}>ເລືອກແຂວງເກີດ</option>
                            <option value="Phongsali">ຜົ້ງສາລີ</option>
                            <option value="LuangNamtha">ຫຼວງນ້ຳທາ</option>
                            <option value="Huoaphan">ຫົວພັນ</option>
                            <option value="Borkeo">ບໍ່ແກ້ວ</option>
                            <option value="Oudomxay">ອຸດົມໄຊ</option>
                            <option value="Sayaboury">ໄຊຍະບູລີ</option>
                            <option value="Xiengkhouang">ຊຽງຂວາງ</option>
                            <option value="LuangPrabang">ຫຼວງພະບາງ</option>
                            <option value="Vientiane">ວຽງຈັນ</option>
                            <option value="VientianeCapital">
                              ນະຄອນຫຼວງວຽງຈັນ
                            </option>
                            <option value="Borikhamsay">ບໍລິຄຳໄຊ</option>
                            <option value="Khammuoan">ຄຳມ່ວນ</option>
                            <option value="Savannakhet">ສະຫວັນນະເຂດ</option>
                            <option value="Salavanh">ສາລະວັນ</option>
                            <option value="Sekong">ເຊກອງ</option>
                            <option value="Attapue">ອັດຕະປື</option>
                            <option value="Champasak">ຈຳປາສັກ</option>
                            <option value="Saisomboun">ໄຊສົມບູນ</option>
                          </select>
                        </div>
                      </div>

                      <div style={{ height: 40 }}></div>
                      <div style={{ color: "#766AF0" }}>
                        <FontAwesomeIcon
                          icon={faUserGraduate}
                          style={{ marginRight: 10 }}
                        />
                        ຂໍ້ມູນການສຶກສາ
                      </div>
                      <div style={{ height: 10 }}></div>
                      <div class="form-row">
                        <div class="col-6">
                          <label>
                            ລະດັບການສືກສາ <a style={{ color: "red" }}>*</a>
                          </label>
                          <div style={{ height: 10 }}></div>
                          <select
                            id="inputState"
                            className="form-control "
                            style={{ width: "100%" }}
                            type="text"
                            name="educationLevel"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.educationLevel}
                          >
                            <option disabled={true} selected={true}>
                              -
                            </option>
                            <option value="PRIMARY">ປະຖົມ</option>
                            <option value="LOWER_SECONDARY">
                              ມັດທະຍົມຕົ້ນ
                            </option>
                            <option value="UPPER_SECONDARY">ມັດທະຍົມປາຍ</option>
                            <option value="FIRST_FLOOR">ຊັ້ນຕົ້ນ</option>
                            <option value="MIDDLE_CLASS">ຊັ້ນກາງ</option>
                            <option value="HIGH_CLASS">ຊັ້ນສູງ</option>
                            <option value="BACHELOR">ປະລິນຍາຕີ</option>
                            <option value="MASTER">ປະລິນຍາໂທ</option>
                            <option value="NOT_STUDYING">ບໍ່ໄດ້ຮຽນ</option>
                          </select>
                        </div>
                        <div class="col-6">
                          <label>ສາຂາຮຽນ</label>
                          <div style={{ height: 10 }}></div>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="-"
                            name="branch"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.branch}
                          />
                        </div>
                      </div>

                      <div style={{ height: 40 }}></div>
                      <div style={{ color: "#766AF0" }}>
                        <FontAwesomeIcon
                          icon={faFileAlt}
                          style={{ marginRight: 10 }}
                        />
                        ຂໍ້ມູນເພີ່ມເຕີມ
                      </div>
                      <div style={{ height: 10 }}></div>
                      <div class="form-row">
                        <div class="col-12">
                          <label>ຄຳອະທິບາຍ</label>
                          <div style={{ height: 10 }}></div>
                          <textarea
                            type="textarea"
                            class="form-control"
                            name="detail"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.detail}
                          />
                        </div>
                        <div class="col-12">
                          <label>ໝາຍເຫດ</label>
                          <div style={{ height: 10 }}></div>
                          <textarea
                            type="textarea"
                            class="form-control"
                            name="note"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.note}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div
                      className="col-md-12"
                      style={{
                        textAlign: "right",
                        paddingRight: 30,
                        marginRight: 30,
                      }}
                    >
                      <button
                        type="button"
                        class="btn btn-outline-danger col-md-2"
                        style={{
                          textAlign: "center",
                          letterSpacing: 0,
                          opacity: 1,
                          paddingRight: 10,
                          marginRight: 10,
                        }}
                        onClick={() => _userDetail()}
                      >
                        {" "}
                        ຍົກເລິກ
                      </button>
                      <button
                        type="submit"
                        class="btn btn-secondary col-md-2"
                        style={{
                          background:
                            "transparent linear-gradient(269deg, #766AF0 0%, #978EF3 100%) 0% 0% no-repeat padding-box",
                          textAlign: "center",
                          letterSpacing: 0,
                          opacity: 1,
                          paddingRight: 5,
                          marginRight: 5,
                        }}
                      >
                        ຢືນຢັນ
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
}
