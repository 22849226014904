// Catagory
export const CONSULTING_CATAGORY_LIST = "/consultingCategory";
export const CONSULTING_CATAGORY_DETAIL =
  "/consultingCategory/consultingCatagoryDetail";
export const CONSULTING_CATAGORY_ADD =
  "/consultingCategory/consultingCatagoryAdd";
export const CONSULTING_CATAGORY_EDIT =
  "/consultingCategory/consultingCatagoryEdit";
// Mentor
export const MENTOR_LIST = "/mentor";
export const MENTOR_DETAIL = "/mentor/mentorDetail";
export const MENTOR_ADD = "/mentor/mentorAdd";
export const MENTOR_EDIT = "/mentor/mentorEdit";
// Counseling
export const COUNSELING_LIST = "/counseling";
export const COUNSELING_DETAIL = "/counseling/counselingDetail";
// staff
export const STAFF_LIST = "/staff";
export const STAFF_DETAIL = "/staff/staffDetail";
export const STAFF_ADD = "/staff/staffAdd";
export const STAFF_EDIT = "/staff/staffEdit";
// dashboard
export const DASHBOARD = "/dashboard";
export const DASH_BOARD_DETAIL = "/dashBoard";
// user
export const USER_LIST = "/user";
export const USER_DETAIL = "/user/userDetail";
export const USER_ADD = "/user/userAdd";
export const USER_EDIT = "/user/userEdit";
// company
export const COMPANY_LIST = "/company";
export const COMPANY_ADD = "/company/companyAdd";
export const COMPANY_DETAIL = "/company/companyDetail";
export const COMPANY_EDIT = "/company/companyEdit";
