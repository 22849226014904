import { gql } from "apollo-boost";

export const LOGIN_USER = gql`
  mutation LoginUser($data: LoginUserInput) {
    loginUser(data: $data) {
        accessToken
      data {
        id
        userId
        role
        firstName
        lastName
        image
     }
    }
  }
`;