import React, { useState } from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import useReactRouter from "use-react-router";
// Custom import
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Login from "../pages/login/components/Login";
import Navbar from "../Layout/Navbar";
import CustomSideNav from "../Layout/SideNav";
import styled from "styled-components";
// catagory
import CatagoryList from "../pages/consultingCatagory/components/CatagoryList";
import CatagoryAdd from "../pages/consultingCatagory/components/CatagoryAdd";
import CatagoryEdit from "../pages/consultingCatagory/components/CatagoryEdit";
import CatagoryDetial from "../pages/consultingCatagory/components/CatagoryDetial";
// Staff
import StaffList from "../pages/staff/components/StaffList";
import StaffDetail from "../pages/staff/components/StaffDetail";
import StaffAdd from "../pages/staff/components/StaffAdd";
import StaffEdit from "../pages/staff/components/StaffEdit";
// user
import UserList from "../pages/user/components/UserList";
import UserDetail from "../pages/user/components/UserDetail";
import UserAdd from "../pages/user/components/UserAdd";
import UserEdit from "../pages/user/components/UserEdit";
// Counseling
import CounselingDetail from "../pages/counseling/components/CounselingDetail";
import CounselingList from "../pages/counseling/components/CounselingList";
//company
import CompanyList from "../pages/company/components/CompanyList";
import CompanyAdd from "../pages/company/components/CompanyAdd";
import CompanyEdit from "../pages/company/components/CompanyEdit";
import CompanyDetail from "../pages/company/components/CompanyDetail";
//Mentor
import MentorDetail from "../pages/mentor/components/MentorDetail";
import MentorAdd from "../pages/mentor/components/MentorAdd";
import MentorEdit from "../pages/mentor/components/MentorEdit";
import MentorList from "../pages/mentor/components/MentorList";
// dash_board
import DashBoardDetail from "../pages/dashBoard/components/";

// Import page const
import {
  //Company
  MENTOR_DETAIL,
  MENTOR_ADD,
  MENTOR_LIST,
  MENTOR_EDIT,
  // staff
  STAFF_LIST,
  STAFF_DETAIL,
  STAFF_ADD,
  STAFF_EDIT,
  //Counseling,
  COUNSELING_LIST,
  COUNSELING_DETAIL,
  //catagory
  CONSULTING_CATAGORY_LIST,
  CONSULTING_CATAGORY_DETAIL,
  CONSULTING_CATAGORY_ADD,
  CONSULTING_CATAGORY_EDIT,
  // user
  USER_LIST,
  USER_DETAIL,
  USER_ADD,
  USER_EDIT,
  // company
  COMPANY_LIST,
  COMPANY_ADD,
  COMPANY_DETAIL,
  COMPANY_EDIT,
  // DASH_BOARD_DETAIL
  DASH_BOARD_DETAIL,
} from "../consts/router";

// =======
const Main = styled.main`
  /* position: relative; */
  overflow: hidden;
  transition: all 0.15s;
  margin-left: ${(props) => (props.expanded ? 160 : 5)}px;
`;
function Routes() {
  const [expanded, setExpanded] = useState(false);
  const _onToggle = (exp) => {
    setExpanded(exp);
  };
  return (
    <Router>
      <Switch>
        {/* Before login routes */}
        <PublicRoute exact path="/" component={Login} />
        {/* After login routes (has SideNav and NavBar) */}
        <Route
          render={({ location, history }) => (
            <React.Fragment>
              {/* sidenav */}
              <Navbar />
              <CustomSideNav
                location={location}
                history={history}
                onToggle={(exp) => _onToggle(exp)}
              />
              <Main expanded={expanded}>
                <div
                  style={{
                    marginTop: 60,
                    backgroundColor: "#eee",
                    minHeight: "100vh",
                  }}
                >
                  <PrivateRoute
                    path={STAFF_LIST + "/limit/:limit/skip/:skip"}
                    exact
                    component={(props) => <StaffList />}
                  />
                  <PrivateRoute
                    path={STAFF_DETAIL + "/:id"}
                    exact
                    component={(props) => <StaffDetail />}
                  />
                  <PrivateRoute
                    path={STAFF_ADD}
                    exact
                    component={(props) => <StaffAdd />}
                  />
                  <PrivateRoute
                    path={STAFF_EDIT + "/:id"}
                    exact
                    component={(props) => <StaffEdit />}
                  />
                  <PrivateRoute
                    path={COUNSELING_LIST + "/limit/:limit/skip/:skip"}
                    exact
                    component={(props) => <CounselingList />}
                  />
                  <PrivateRoute
                    path={COUNSELING_DETAIL + "/:id"}
                    exact
                    component={(props) => <CounselingDetail />}
                  />
                  <PrivateRoute
                    path={MENTOR_LIST + "/limit/:limit/skip/:skip"}
                    exact
                    component={(props) => <MentorList />}
                  />
                  <PrivateRoute
                    path={MENTOR_EDIT + "/:id"}
                    exact
                    component={(props) => <MentorEdit />}
                  />
                  <PrivateRoute
                    path={MENTOR_ADD}
                    exact
                    component={(props) => <MentorAdd />}
                  />
                  <PrivateRoute
                    path={MENTOR_DETAIL + "/:id"}
                    exact
                    component={(props) => <MentorDetail />}
                  />
                  {/* user */}
                  <PrivateRoute
                    path={USER_LIST + "/limit/:limit/skip/:skip"}
                    exact
                    component={(props) => <UserList />}
                  />
                  <PrivateRoute
                    path={USER_DETAIL + "/:id"}
                    exact
                    component={(props) => <UserDetail />}
                  />
                  <PrivateRoute
                    path={USER_ADD}
                    exact
                    component={(props) => <UserAdd />}
                  />
                  <PrivateRoute
                    path={USER_EDIT + "/:id"}
                    exact
                    component={(props) => <UserEdit />}
                  />
                  {/* company */}
                  <PrivateRoute
                    path={COMPANY_LIST}
                    exact
                    component={(props) => <CompanyList />}
                  />
                  <PrivateRoute
                    path={COMPANY_ADD}
                    exact
                    component={(props) => <CompanyAdd />}
                  />
                  <PrivateRoute
                    path={COMPANY_DETAIL + "/:id"}
                    exact
                    component={(props) => <CompanyDetail />}
                  />
                  <PrivateRoute
                    path={COMPANY_EDIT + "/:id"}
                    exact
                    component={(props) => <CompanyEdit />}
                  />
                  {/* ConsultingCatagory */}
                  <PrivateRoute
                    path={CONSULTING_CATAGORY_LIST}
                    exact
                    component={(props) => <CatagoryList />}
                  />
                  <PrivateRoute
                    path={CONSULTING_CATAGORY_ADD}
                    exact
                    component={(props) => <CatagoryAdd />}
                  />
                  <PrivateRoute
                    path={CONSULTING_CATAGORY_DETAIL + "/:id"}
                    exact
                    component={(props) => <CatagoryDetial />}
                  />
                  <PrivateRoute
                    path={CONSULTING_CATAGORY_EDIT + "/:id"}
                    exact
                    component={(props) => <CatagoryEdit />}
                  />
                  {/* DashBoardDetail */}
                  <PrivateRoute
                    path={DASH_BOARD_DETAIL}
                    exact
                    component={(props) => <DashBoardDetail />}
                  />
                </div>
              </Main>
            </React.Fragment>
          )}
        />
      </Switch>
    </Router>
  );
}

export default Routes;
