import React from "react";
import "moment/locale/lo";
import Routes from "./Routes";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ApolloProvider } from "@apollo/react-hooks";
import ApolloClient from "apollo-boost";
import { USER_KEY } from "./consts";
export default function App() {
  const client = new ApolloClient({
    // uri: "http://54.251.220.141:7070/",
    uri: "https://stella-api.lailaolab.com/",
    request: (operation) => {
      const _resData = localStorage.getItem(USER_KEY);
      const _localJson = JSON.parse(_resData);
      if (_localJson) {
        operation.setContext({
          headers: {
            authentication: _localJson.loginUser.accessToken,
          },
        });
        return;
      }
    },
  });
  return (
    <ApolloProvider client={client}>
      <Routes />
    </ApolloProvider>
  );
}
