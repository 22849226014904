import React, { useState, useEffect } from "react"
import useReactRouter from "use-react-router"
import Image from "react-bootstrap/Image"
// import '../utils/index.css'
import ImageLogo from "../../../image/profile.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks"
import { } from "@fortawesome/free-solid-svg-icons"
import { TITLE_HEADER, HEADER, BODY, URL_FOR_SHOW_PHOTO } from "../../../consts"
import {
  CONSULTING_CATAGORY_ADD,
  CONSULTING_CATAGORY_DETAIL
} from "../../../consts/router"
import { CONSULTING_CATEGORYS, USERS } from "../apollo"
export default function CatagoryList() {
  const [allCagegoryIds, setAllCagegoryIds] = useState([]);
  const { history, location, match } = useReactRouter()
  const { loading, error, data } = useQuery(CONSULTING_CATEGORYS)
  const {
    loading: dataUserLoading,
    error: dataUserError,
    data: dataUser
  } = useQuery(USERS)
  const _CatagoryAdd = () => {
    history.push(`${CONSULTING_CATAGORY_ADD}`)
  }
  const _CatagoryDetial = (id) => {
    // history.push(`${CONSULTING_CATAGORY_DETAIL}/${id}`, id);
    history.push({
      pathname: `${CONSULTING_CATAGORY_DETAIL}/${id}`,
      state: { data: allCagegoryIds }
    });
  }
  const _renderName = (e) => {
    let res = dataUser?.users?.data?.filter((x) => x.id === e)
    if (res?.length > 0) return res[0]?.firstName + " " + res[0]?.lastName
  };
    /***
   * to get all consultings data
   */
     useEffect(() => {
      if (data?.consultingCategorys?.data?.length > 0) {
        let dataId = data?.consultingCategorys?.data.map(data => data.id);
        // console.log({data: data?.consultings?.data[200]})
        setAllCagegoryIds([...dataId]);
      }
    }, [data]);
  return (
    <div style={TITLE_HEADER}>
      {/* <h6 style={HEADER}>{/* <p>CatagoryList » </p> </h6> */}
      <div
        style={{
          marginTop: 25,
          marginLeft: 25,
          marginRight: 25,
          padding: 10,
          background: "#FFFFFF",
          borderRadius: 8,
          opacity: 1,
          width: "auto"
        }}
      >
        <div
          className="row col-md-12"
          style={{
            // boxShadow: "0px 0px 6px #F7F7F7",
            borderRadius: 8,
            padding: 5
            // width: "100%"
          }}
        >
          <div className="col-6" style={{ fontSize: "20px" }}>
            ໝວດທັງໝົດ ( {data?.consultingCategorys?.total} )
          </div>
          <div
            className="col-6"
            style={{ fontSize: "20px", float: "right", textAlign: "right" }}
          >
            {/* <div className="row " style={{ float: "right" }}> */}
            <div className="col " style={{ textAlign: "right" }}>
              <button
                type="button"
                className="btn btn-secondary btn-app "
                onClick={() => _CatagoryAdd()}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  style={{ textAlign: "left" }}
                  style={{ marginRight: 20 }}
                />
                ເພີ່ມໝວດປຶກສາ
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: 25,
          // marginLeft: 25,
          marginRight: 25,
          paddingLeft: 5,
          // background: "#FFFFFF",
          // borderRadius: 4,
          // opacity: 1,
          width: "auto"
        }}
      >
        <div class="row" style={{ paddingLeft: 20, paddingTop: 10 }}>
          {data?.consultingCategorys?.data?.map((category, index) => {
            return (
              <div class="col-sm-2">
                <div
                  style={{
                    background: "#FFFFFF",
                    boxShadow: "0px 3px 6px #00000029",
                    borderRadius: 8,
                    marginBottom: 30
                  }}
                >
                  {category?.image ? (
                    <div style={{ padding: 10 }}>
                      <Image
                        src={URL_FOR_SHOW_PHOTO + category?.image}
                        alt="stellaImage"
                        style={{
                          width: "100%",
                          height: "160px",
                          objectFit: "contain",
                          objectFit: "cover"
                        }}
                      />
                    </div>
                  ) : (
                      <div style={{ padding: 10 }}>
                        <Image
                          src={ImageLogo + category?.image}
                          alt="stellaImage"
                          style={{
                            width: "100%",
                            height: "160px",
                            objectFit: "contain",
                            objectFit: "cover"
                          }}
                        />
                      </div>
                    )}

                  <div style={{ fontSize: 12, paddingLeft: 10 }}>
                    {category?.name}
                  </div>
                  <div style={{ fontSize: 12, paddingLeft: 10 }}>
                    ສ້າງໂດຍ : {_renderName(category?.createdBy)}
                  </div>
                  <button
                    className="appColor"
                    style={{
                      width: "100%",
                      height: 30,
                      borderBottomRightRadius: 5,
                      borderBottomLeftRadius: 5,
                      border: "solid 0px"
                    }}
                    onClick={() => _CatagoryDetial(category?.id)}
                  >
                    ລາຍລະອຽດ
                  </button>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
