import React, { useState, useRef, useEffect } from "react";
import useReactRouter from "use-react-router";
import Image from "react-bootstrap/Image";
import ImageLogo from "../../../image/profile.png";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { Formik } from "formik";
import { useLocation } from "react-router-dom";
import moment from "moment";
import {
  faHistory,
  faSync,
  faUser,
  faBookmark,
  faComments,
  faPlus,
  faCalendarCheck,
  faTrashAlt,
  faTrash,
  faEdit,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

import { TITLE_HEADER1, HEADER, BODY_DSTAFF_DETAIL, USER_KEY } from "../../../consts";
import {
  COUNSELING_DETAIL,
  COUNSELING_LIST,
  USER_DETAIL,
  STAFF_DETAIL,
  MENTOR_DETAIL,
} from "../../../consts/router";
import {
  formatDate,
  formatDateTime,
  formatDateDash,
} from "../../../helper/super";
import {
  CONSULTING,
  CONSULTING_CATEGORYS,
  USERS,
  UPDATE_CONSULTING,
  CONSULTINGS,
  DELETE_CONSULTING,
  CREATE_COMMENT,
  COMMENTS,
  DELETE_COMMENT,
} from "../apollo";
export default function CounselingDetail() {
  const { history, location, match } = useReactRouter();
  const { loading, error, data } = useQuery(CONSULTING, {
    variables: { where: { id: match?.params?.id } },
  });

  const {
    loading: CommentLoading,
    error: CommentError,
    data: CommentData,
  } = useQuery(COMMENTS, {
    variables: { where: { consultingId: match?.params?.id } },
  });
  const { loading: FRuserLoading, error: FRuserError, data: FRuser } = useQuery(
    USERS,
    {
      variables: { where: { role_in: "FIRST_RESPONDER" } },
    }
  );
  const {
    loading: MENTORuserLoading,
    error: MENTORuserError,
    data: MENTORuser,
  } = useQuery(USERS, {
    variables: { where: { role_in: "MENTOR" } },
  });
  const { loading: UserLoading, error: UserError, data: User } = useQuery(
    USERS,
    {
      variables: { where: { role_in: "USER" } },
    }
  );
  const {
    loading: consultingLoading,
    error: consultingError,
    data: consultingData,
  } = useQuery(CONSULTING_CATEGORYS);
  const {
    loading: dataUserLoading,
    error: dataUserError,
    data: dataUser,
  } = useQuery(USERS);
  const [updateConsultingStatus, setUpdateConsultingStatus] = useMutation(
    UPDATE_CONSULTING
  );
  const [updateConsulting, setUpdateConsulting] = useMutation(
    UPDATE_CONSULTING
  );
  const [createComment, setCreateComment] = useMutation(CREATE_COMMENT);
  const [deleteComment, setDeleteComment] = useMutation(DELETE_COMMENT);
  const [deleteConsulting, setDeleteConsulting] = useMutation(
    DELETE_CONSULTING
  );
  const {
    loading: consultingSLoading,
    error: consultingSError,
    data: consultingSData,
  } = useQuery(CONSULTINGS, {
    variables: { where: { userId: data?.consulting?.userId } },
  });
  const [show, setShow] = useState(false);
  const [addNewMessenger, setAddNewMessec] = useState(false);
  const [updateSTTClose, setUpdateStatus] = useState(false);
  const [edit, setEdit] = useState(false);
  const [getAllConsultingsId, setGetAllConsultingsId] = useState([]);
  // editData
  const EditClose = () => setEdit(false);
  const EditShow = () => setEdit(true);
  // status
  const updateSTTC = () => setUpdateStatus(false);
  const updateSTTOK = () => setUpdateStatus(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);
  // New Messenger
  const addNMC = () => setAddNewMessec(false);
  const addNMS = () => setAddNewMessec(true);

  const [updateStatut, setUpdateStatut] = useState(false);
  const handleCloseUpdate = () => setUpdateStatut(false);
  const handleShowUpdate = () => setUpdateStatut(true);
  // history
  const _CounselingList = () => {
    history.push(`${COUNSELING_LIST}/limit/20/skip/0`);
  };
  const _userDetail = (id) => {
    history.push(`${USER_DETAIL}/${id}`, id);
  };
  const _userFR = (id) => {
    history.push(`${STAFF_DETAIL}/${id}`, id);
  };
  const _userMT = (id) => {
    history.push(`${MENTOR_DETAIL}/${id}`, id);
  };
  const _CounselingDetail = () => {
    history.push(`${COUNSELING_DETAIL}`);
  };
  const _status = (e) => {
    if (e === "ONGOING_FR") {
      return <div style={{ color: "#F29702" }}>ONGOING_FR</div>;
    } else if (e === "ONGOING_MT") {
      return <div style={{ color: "#F29702" }}>ONGOING_MT</div>;
    } else if (e === "COMPLETED") {
      return <div style={{ color: "#48BB78" }}>COMPLETED</div>;
    } else if (e === "CANCELED") {
      return <div style={{ color: "red" }}>CANCELED</div>;
    }
  };
  const _renderCategory = (e) => {
    let res = consultingData?.consultingCategorys?.data?.filter(
      (x) => x.id === e
    );
    if (res?.length > 0) return res[0]?.name;
  };
  const _renderName = (e) => {
    let res = dataUser?.users?.data?.filter((x) => x.id === e);
    if (res?.length > 0) return res[0]?.firstName + " " + res[0]?.lastName;
  };
  const _deleteComment = async (id) => {
    await deleteComment({ variables: { where: { id: id } } }).then(
      Swal.fire({
        icon: "success",
        title: "ການລຶບສຳເລັດ",
        showConfirmButton: false,
        timer: 1500,
      }).then(function () {
        history.push(
          `${COUNSELING_DETAIL}/${match?.params?.id}`,
          match?.params?.id
        );
        window.location.reload(true);
      })
    );
  };
  const _updateConsultingStatus = async (values) => {
    const resUpdateConsulting = await updateConsultingStatus({
      variables: {
        data: {
          status: values.status,
        },
        where: { id: match?.params?.id },
      },
    })
    if (resUpdateConsulting?.data?.updateConsulting?.id) {
      Swal.fire({
        icon: "success",
        title: "ການບັນທຶກສຳເລັດ",
        showConfirmButton: false,
        timer: 1500,
      }).then(function () {
        history.push(
          `${COUNSELING_DETAIL}/${match?.params?.id}`,
          match?.params?.id
        );
        window.location.reload(true);
      })
    } else {
      Swal.fire({
        icon: "failed",
        title: "ການບັນທຶກບໍ່ສຳເລັດ",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  const _updateConsulting = async (values) => {
    let mentorId = "";
    if (values.mentorId === "-") {
      mentorId = null;
    } else {
      mentorId = values.mentorId;
    }
    let firstResponderId = "";
    if (values.firstResponderId === "-") {
      firstResponderId = null;
    } else {
      firstResponderId = values.firstResponderId;
    }
    let consultingCategoryId = "";
    if (values.consultingCategoryId === "-") {
      consultingCategoryId = null;
    } else {
      consultingCategoryId = values.consultingCategoryId;
    }
    let appointmentDate = "";
    if (values.appointmentDate === "Invalid date") {
      appointmentDate = null;
    } else {
      appointmentDate = new Date(values.appointmentDate).toISOString();
    }
    const resUpdateConsulting = await updateConsulting({
      variables: {
        data: {
          firstResponderId: firstResponderId,
          mentorId: mentorId,
          appointmentDate: appointmentDate,
          appointmentAddress: values.appointmentAddress,
          detail: values.detail,
          followUp: values.followUp,
        },
        where: { id: match?.params?.id },
      },
    })
    if (resUpdateConsulting?.data?.updateConsulting?.id) {
      Swal.fire({
        icon: "success",
        title: "ການບັນທຶກສຳເລັດ",
        showConfirmButton: false,
        timer: 1500,
      }).then(function () {
        // history.push(
        //   `${COUNSELING_DETAIL}/${match?.params?.id}`,
        //   match?.params?.id
        // );
        history.push({
          pathname: `${COUNSELING_DETAIL}/${match?.params?.id}`,
          state: { data: location?.state?.data, id: match?.params?.id },
        });
        window.location.reload(true);
      })
    } else {
      Swal.fire({
        icon: "failed",
        title: "ການບັນທຶກບໍ່ສຳເລັດ",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  const _createComment = async (values) => {
    const resCreateComment = await createComment({
      variables: {
        data: {
          detail: values.detail,
          consultingId: match?.params?.id,
        },
      },
    })
    if (resCreateComment?.data?.createComment?.id) {
      Swal.fire({
        icon: "success",
        title: "ການບັນທຶກສຳເລັດ",
        showConfirmButton: false,
        timer: 1500,
      }).then(function () {
        history.push(
          `${COUNSELING_DETAIL}/${match?.params?.id}`,
          match?.params?.id
        );
        window.location.reload(true);
      })
    } else {
      Swal.fire({
        icon: "failed",
        title: "ການບັນທຶກບໍ່ສຳເລັດ",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  let time = [];
  for (let i = 0; i < 12; i++) {
    time.push(i);
  }

  let minute = [];
  for (let i = 0; i < 60; i++) {
    minute.push(i);
  }
  // confrime delete
  const _confrimeDelete = async () => {
    await deleteConsulting({
      variables: { where: { id: match?.params?.id } },
    }).then(
      Swal.fire({
        icon: "success",
        title: "ການລຶບສຳເລັດ",
        showConfirmButton: false,
        timer: 1500,
      }).then(function () {
        history.push(`${COUNSELING_LIST}/limit/20/skip/0`);
        window.location.reload(true);
      })
    );
  };
  // ===========> check Role 
  const [userData, setUserData] = useState({});
  useEffect(() => {
    const _resData = localStorage.getItem(USER_KEY);
    const _localJson = JSON.parse(_resData);
    setUserData(_localJson);
  }, []);

  useEffect(() => {
    setGetAllConsultingsId(location?.state?.data)
  }, [location]);

  const _onGoBackIds = () => {
    let currentId = match?.params?.id;
    let currentIndexInArray = getAllConsultingsId.indexOf(currentId);
    if (currentIndexInArray > 0) {
      let nextConsultingId = getAllConsultingsId[currentIndexInArray - 1];
      history.push({
        pathname: `${COUNSELING_DETAIL}/${nextConsultingId}`,
        state: { data: getAllConsultingsId }
      });
    } else {
      let nextId = getAllConsultingsId.length - 1;
      history.push({
        pathname: `${COUNSELING_DETAIL}/${getAllConsultingsId[nextId]}`,
        state: { data: getAllConsultingsId }
      });
    }
  }

  const _onGoNextIds = () => {
    let currentId = match?.params?.id;
    let currentIndexInArray = getAllConsultingsId.indexOf(currentId);
    if (currentIndexInArray < getAllConsultingsId.length) {
      let nextConsultingId = getAllConsultingsId[currentIndexInArray + 1];
      history.push({
        pathname: `${COUNSELING_DETAIL}/${nextConsultingId}`,
        state: { data: getAllConsultingsId }
      });
    } else {
      let nextId = 0;
      history.push({
        pathname: `${COUNSELING_DETAIL}/${getAllConsultingsId[nextId]}`,
        state: { data: getAllConsultingsId }
      });
    }
  }

  let check = userData?.loginUser?.data?.role;
  let checkId = userData?.loginUser?.data?.id;

  return (
    <div style={{ marginLeft: 25, marginRight: 25 }}>
      {/* Next and Back b uttons are over here */}
      <div style={{
        position: 'fixed',
        left: 100,
        zIndex: 1,
        top: "50%",
        display: location?.state?.data ? 'flex' : 'none'
      }}>
        <button onClick={() => _onGoBackIds()} className="btn btn-outline-primary btn-stella" style={{
          borderRadius: 50,
          width: 50,
          height: 50,
        }}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
      </div>
      <div style={{
        position: 'fixed',
        right: 45,
        zIndex: 1,
        top: "50%",
        display: location?.state?.data ? 'flex' : 'none'
      }}>
        <button onClick={() => _onGoNextIds()} className="btn btn-outline-primary btn-stella " style={{
          borderRadius: 50,
          width: 50,
          height: 50
        }}>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
      <h6 style={{ width: "100%", height: "100%" }}>
        <div className="container-fluid">
          <div
            className="row"
            style={{
              marginLeft: 20,
              paddingLeft: 20,
              marginTop: 20,
              paddingTop: 20,
            }}
          >
            <div className="col-6">
              <a
                href=""
                onClick={() => _CounselingList()}
                style={{ color: "#766AF0" }}
              >
                ການປືກສາທັງໝົດ »
              </a>
              ລາຍລະອຽດການປຶກສາ {_renderName(data?.consulting?.userId)}
            </div>
            <div className="col-6" style={{ textAlign: "right" }}>
              {check === "ADMIN" &&
                <button
                  type="button"
                  class="btn btn-outline-danger col-md-3 dlt"
                  onClick={handleShowDelete}
                >
                  <FontAwesomeIcon icon={faTrash} style={{ marginRight: 20 }} />{" "}
                  ລຶບ
                </button>
              }
              {checkId === data?.consulting?.firstResponderId &&
                <button
                  type="button"
                  className="btn  btn-edit col-3"
                  style={{ border: "solid 0px" }}
                  onClick={EditShow}
                >
                  {" "}
                  <FontAwesomeIcon
                    icon={faEdit}
                    style={{ marginRight: 20 }}
                  />{" "}
                  ແກ້ໄຂ
                </button>
              }
              {check === "ADMIN" &&
                <button
                  type="button"
                  className="btn  btn-edit col-3"
                  style={{ border: "solid 0px" }}
                  onClick={EditShow}
                >
                  {" "}
                  <FontAwesomeIcon
                    icon={faEdit}
                    style={{ marginRight: 20 }}
                  />{" "}
                  ແກ້ໄຂ
                </button>
              }
            </div>
          </div>
        </div>
      </h6>

      <div className="container-fluid">
        <div
          className="row"
          style={{
            marginLeft: 25,
            paddingLeft: 25,
            marginTop: 5,
            paddingTop: 5,
            paddingRight: 1,
            marginRight: 1,
          }}
        >
          <div
            className="col"
            style={{
              backgroundColor: "#fff",
              width: "50%",
              borderRadius: 8,
              padding: 32,
              margin: 5,
            }}
          >
            <div className="row" style={{ marginTop: 5, paddingTop: 5 }}>
              <div className="col-6">
                <h5>
                  <a
                    style={{
                      color: "#766AF0",
                      padding: 5,
                      margin: 5,
                    }}
                  >
                    <FontAwesomeIcon icon={faUser} />
                  </a>
                  ຂໍ້ມູນການປຶກສາ
                </h5>
              </div>
              <div className="col-6">
                <button
                  type="button"
                  class="btn btn-secondary"
                  style={{
                    backgroundColor: "#766AF0",
                    border: "solid 0px",
                    float: "right",
                    marginRight: 10,
                  }}
                  onClick={updateSTTOK}
                  disabled={checkId === data?.consulting?.firstResponderId ? false : check === "ADMIN" ? false : true}
                >
                  <a>
                    <FontAwesomeIcon icon={faSync} />
                  </a>{" "}
                  ອັບເດດສະຖານະ
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <p style={{ padding: 5, margin: 5, color: "#646464" }}>
                  ສະຖານະ
                </p>
              </div>
              <div className="col-8">
                <p style={{ padding: 5, margin: 5, color: "#F29702" }}>
                  {_status(data?.consulting?.status)}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <p style={{ padding: 5, margin: 5, color: "#646464" }}>ໝວດ</p>
              </div>
              <div className="col-8">
                <p
                  style={{
                    padding: 5,
                    margin: 5,
                    color: "#fff",
                    backgroundColor: "#EC6869",
                    borderRadius: 8,
                    width: "50%",
                    textAlign: "center",
                  }}
                >
                  {_renderCategory(data?.consulting?.consultingCategoryId)}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <p style={{ padding: 5, margin: 5, color: "#646464" }}>
                  ຜູ້ໃຊ້
                </p>
              </div>
              <a
                style={{ cursor: "pointer" }}
                onClick={() => _userDetail(data?.consulting?.userId)}
                className="col-8"
              >
                <div
                  style={{
                    padding: 5,
                    margin: 5,
                    color: "#766AF0",
                    textDecoration: "underline",
                  }}
                >
                  {_renderName(data?.consulting?.userId)}
                </div>
              </a>
            </div>

            <div className="row">
              <div className="col-4">
                <p style={{ padding: 5, margin: 5, color: "#646464" }}>
                  First Responder
                </p>
              </div>
              <a
                onClick={() => _userFR(data?.consulting?.firstResponderId)}
                className="col-8"
                style={{ cursor: "pointer", pointerEvents: checkId === data?.consulting?.firstResponderId ? "" : check === "ADMIN" ? "" : "none" }}
              >
                <div
                  style={{
                    padding: 5,
                    margin: 5,
                    color: "#766AF0",
                    textDecoration: "underline",
                  }}
                >
                  {_renderName(data?.consulting?.firstResponderId)}
                </div>
              </a>
            </div>
            <div className="row">
              <div className="col-4">
                <p style={{ padding: 5, margin: 5, color: "#646464" }}>
                  ລາຍລະອຽດການປຶກສາ
                </p>
              </div>
              <div className="col-2">
                <div
                  style={{
                    padding: 5,
                    margin: 5,
                    color: "#868686",
                    textAlign: "justify",
                    width: 350,
                  }}
                >
                  {data?.consulting?.detail}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <h5>
                  <a
                    style={{
                      color: "#766AF0",
                      padding: 5,
                      margin: 5,
                    }}
                  >
                    <FontAwesomeIcon icon={faBookmark} />
                  </a>
                  ການຕິດຕາມ
                </h5>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <p style={{ padding: 5, margin: 5, color: "#646464" }}>
                  ວັນ, ເວລາ
                </p>
              </div>
              <div className="col-8">
                <p style={{ padding: 5, margin: 5, color: "#868686" }}>
                  {formatDateTime(data?.consulting?.appointmentDate)}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <p style={{ padding: 5, margin: 5, color: "#646464" }}>
                  ສະຖານທີ່
                </p>
              </div>
              <div className="col-8">
                <p style={{ padding: 5, margin: 5, color: "#868686" }}>
                  {data?.consulting?.appointmentAddress}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <p style={{ padding: 5, margin: 5, color: "#646464" }}>
                  Mentor
                </p>
              </div>
              <a
                href=""
                onClick={() => _userMT(data?.consulting?.mentorId)}
                className="col-8"
              >
                <div
                  style={{
                    padding: 5,
                    margin: 5,
                    color: "#766AF0",
                    textDecoration: "underline",
                  }}
                >
                  {_renderName(data?.consulting?.mentorId)}
                </div>
              </a>
            </div>
            <div className="row">
              <div className="col-4">
                <p style={{ padding: 5, margin: 5, color: "#646464" }}>
                  ລາຍລະອຽດການຕິດຕາມ
                </p>
              </div>
              <div className="col-8">
                <p style={{ padding: 5, margin: 5, color: "#868686" }}>
                  {data?.consulting?.followUp}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12" style={{ textAlign: "center" }}>
                <button
                  type="button"
                  className="btn  btn-edit"
                  style={{ border: "solid 0px", width: "50%" }}
                  onClick={handleShow}
                >
                  <i style={{ float: "left" }}>
                    <FontAwesomeIcon icon={faHistory} />
                  </i>{" "}
                  ເບິ່ງປະຫວັດການປຶກສາຜ່ານມາ
                </button>
              </div>
            </div>
            <div style={{ height: 30 }}></div>
          </div>
          <div
            className="col"
            style={{
              backgroundColor: "#fff",
              width: "50%",
              borderRadius: 8,
              padding: 32,
              margin: 5,
            }}
          >
            <div className="row" style={{ marginTop: 5, paddingTop: 5 }}>
              <div className="col-6">
                <h5>
                  <a
                    style={{
                      color: "#766AF0",
                      marginRight: 10,
                      marginLeft: 10,
                    }}
                  >
                    <FontAwesomeIcon icon={faComments} />
                  </a>
                  ບັນທຶກຂໍ້ຄວາມ
                </h5>
              </div>
              <div className="col-6">
                <button
                  type="button"
                  class="btn btn-secondary btn-add"
                  style={{
                    backgroundColor: "#766AF0",
                    border: "solid 0px",
                    float: "right",
                    width: "50%",
                    marginRight: 10,
                  }}
                  onClick={addNMS}
                  disabled={checkId === data?.consulting?.firstResponderId ? false : check === "ADMIN" ? false : true}
                >
                  <a style={{ float: "left" }}>
                    <FontAwesomeIcon icon={faPlus} />
                  </a>{" "}
                  ເພີ່ມຂໍ້ຄວາມ
                </button>
              </div>
              <div style={{ height: 50 }}></div>
              {CommentData?.comments?.data?.map((item, index) => {
                return (
                  <div className="col-12" key={index}>
                    <div
                      style={{
                        color: "#1B1B1B",
                        marginRight: 10,
                        marginLeft: 10,
                        backgroundColor: "#E5E5E5",
                        padding: 20,
                        borderRadius: 5,
                      }}
                    >
                      <div style={{ color: "#646464" }}>
                        {formatDate(item?.createdAt)} -{" "}
                        {_renderName(item?.createdBy)}{" "}
                      </div>
                      <p>{item?.detail}</p>
                      <div className="row" style={{ paddingRight: 20 }}>
                        <div className="col-10"></div>
                        <button
                          type="button"
                          class="btn btn-outline-danger col-2"
                          onClick={() => _deleteComment(item?.id)}
                          disabled={checkId === data?.consulting?.firstResponderId ? false : check === "ADMIN" ? false : true}
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            style={{ marginRight: 10 }}
                          />{" "}
                          ລຶບ
                        </button>
                      </div>
                    </div>
                    <div style={{ height: 10 }}></div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/*  */}
      </div>
      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>ປະຫວັດຜ່ານມາຂອງການປຶກສາ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-14">
            <table class="table">
              <thead>
                <tr className="center">
                  <th scope="col">#</th>
                  <th scope="col">User</th>
                  <th scope="col">FirstResponder</th>
                  <th scope="col">Mentor</th>
                  <th scope="col">ໝວດ</th>
                  <th scope="col">ສະຖານະ</th>
                  <th scope="col">ມື້ນັດໝາຍ</th>
                  <th scope="col">ວັນທີ່ສ້າງ</th>
                </tr>
              </thead>
              <tbody className="center">
                {consultingSData?.consultings?.data?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{_renderName(item?.userId)}</td>
                      <td>{_renderName(item?.firstResponderId)}</td>
                      <td>{_renderName(item?.mentorId)}</td>
                      <td style={{ width: "auto" }}>
                        <label
                          key={index}
                          style={{
                            background: "#EC6869 0% 0% no-repeat padding-box",
                            borderRadius: 5,
                            border: "solid 1px",
                            opacity: 1,
                            color: "#FFFFFF",
                            marginRight: 10,
                            padding: 2,
                          }}
                        >
                          {_renderCategory(item?.consultingCategoryId)}
                        </label>
                      </td>
                      <td>{_status(item?.status)}</td>
                      <td>{formatDateTime(item?.appointmentDate)}</td>
                      <td>{formatDate(item?.createdAt)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div style={{ paddingTop: 20, paddingBottom: 30 }}></div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="btn btn-outline-danger col-2" onClick={handleClose}>
            {" "}
            ຍົກເລິກ
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="50w,50h"
        backdrop="static"
        keyboard={false}
        show={addNewMessenger}
        onHide={() => setAddNewMessec(false)}
        aria-labelledby="example-modal-sizes-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title">ເພີ່ມຂໍ້ຄວາມ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{}}
            validate={(values) => { }}
            onSubmit={(values, { setSubmitting }) => {
              _createComment(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div>
                  <div style={{ color: "#868686" }}>ພີມຂໍ້ຄວາມ</div>
                  <div class="form-group">
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      placeholder="ກະລຸນາປ້ອນ..."
                      type="text"
                      name="detail"
                      onChange={handleChange}
                      value={values.detail}
                    ></textarea>
                  </div>
                </div>
                <Form>
                  <Row>
                    <Col>
                      <Button
                        style={{ marginLeft: 300, marginRight: 10 }}
                        variant="btn btn-outline-danger col-2"
                        onClick={() => setAddNewMessec(false)}
                      >
                        {" "}
                        ຍົກເລິກ
                      </Button>
                      <Button
                        type="submit"
                        variant="btn btn-secondary btn-app col-2"
                      >
                        ບັນທຶກ
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      {/* UpdateStatus */}
      <Modal size="50w,50h" show={updateSTTClose} onHide={updateSTTC}>
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title">
            ອັບເດດສະຖານະການປຶກສາ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{}}
            validate={(values) => { }}
            onSubmit={(values, { setSubmitting }) => {
              _updateConsultingStatus(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div
                  class="row"
                  style={{ paddingBottom: 40, marginBottom: 40 }}
                >
                  <div class="col">
                    <div style={{ margin: 5, padding: 5 }}>
                      ສະຖານະ <a style={{ color: "red" }}> *</a>
                    </div>
                    <div class="input-group" style={{ margin: 0 }}>
                      <select
                        class="custom-select col-12"
                        id="status"
                        style={{
                          float: "center",
                          fontWeight: "bold",
                          margin: "5",
                        }}
                        type="text"
                        name="status"
                        onChange={handleChange}
                        value={values.status}
                      >
                        <option value="" disabled={true} selected={true}>ໝວດປຶກສາ</option>
                        <option value="ONGOING_FR">ONGOING_FR</option>
                        <option value="ONGOING_MT">ONGOING_MT</option>
                        <option value="COMPLETED">COMPLETED</option>
                        <option value="CANCELED">CANCELED</option>
                      </select>
                    </div>
                  </div>
                </div>
                <Form>
                  <Row>
                    <Col>
                      <Button
                        variant="btn btn-outline-danger col-2"
                        onClick={updateSTTC}
                        style={{ marginLeft: 300, marginRight: 10 }}
                      >
                        {" "}
                        ຍົກເລິກ
                      </Button>
                      <Button
                        type="submit"
                        variant="btn btn-secondary btn-app col-2"
                      >
                        ບັນທຶກ
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      {/* EditDATA */}
      <Modal
        size="xl"
        show={edit}
        onHide={EditClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <h5 class="modal-title" id="exampleModalLabel" textAlign="left">
              ແກ້ໄຂການປຶກສາ
            </h5>
          </Modal.Title>
        </Modal.Header>
        {/* modal edit Consultig */}
        <Modal.Body>
          <Formik
            initialValues={{
              userId: data?.consulting?.userId,
              firstResponderId: data?.consulting?.firstResponderId,
              mentorId: data?.consulting?.mentorId,
              consultingCategoryId: data?.consulting?.consultingCategoryId,
              appointmentDate: moment(data?.consulting?.appointmentDate).format(
                "YYYY-MM-DDTHH:mm"
              ),
              appointmentAddress: data?.consulting?.appointmentAddress,
              detail: data?.consulting?.detail,
              followUp: data?.consulting?.followUp,
            }}
            validate={(values) => { }}
            onSubmit={(values, { setSubmitting }) => {
              _updateConsulting(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div class="modal-body modal-90w">
                  <div>
                    <FontAwesomeIcon icon={faUser} className="text-a" />
                    <label style={{ marginLeft: 5, paddingLeft: 5 }}>
                      ຂໍ້ມູນການປຶກສາ{" "}
                    </label>
                  </div>
                  <div class="row" style={{ marginTop: 5 }}>
                    <div
                      class="col-4"
                      style={{ float: "left", paddingBottom: 20 }}
                    >
                      <div>
                        ໝວດ
                        <a style={{ color: "#F91B1B", paddingBottom: 5 }}> *</a>
                      </div>
                      <select
                        class="custom-select col-12"
                        style={{
                          float: "center",
                          fontWeight: "bold",
                          margin: "5",
                        }}
                        id="consultingCategoryId"
                        type="text"
                        name="consultingCategoryId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.consultingCategoryId}
                        disabled={true}
                      >
                        <option disabled={true} selected={true}> - </option>
                        {consultingData?.consultingCategorys?.data?.map(
                          (item, index) => {
                            return (
                              <option value={item?.id}>{item?.name}</option>
                            );
                          }
                        )}
                      </select>
                    </div>
                    <div
                      class="col-4"
                      style={{ float: "left", paddingBottom: 20 }}
                    >
                      <div>
                        ຜູ້ໃຊ້
                        <a style={{ color: "#F91B1B", paddingBottom: 5 }}> *</a>
                      </div>
                      <select
                        class="custom-select col-12"
                        style={{
                          float: "center",
                          fontWeight: "bold",
                          margin: "5",
                        }}
                        id="userId"
                        type="text"
                        name="userId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.userId}
                        disabled
                      >
                        <option disabled={true} selected={true}> - </option>
                        {User?.users?.data?.map((item, index) => {
                          return (
                            <option value={item?.id}>{item?.firstName}</option>
                          );
                        })}
                      </select>
                    </div>
                    <div
                      class="col-4"
                      style={{ float: "left", paddingBottom: 20 }}
                    >


                      <div>First Responders</div>
                      <select
                        class="custom-select col-12"
                        style={{
                          float: "center",
                          fontWeight: "bold",
                          margin: "5",
                        }}
                        id="firstResponderId"
                        type="text"
                        name="firstResponderId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstResponderId}
                        disabled={check === "ADMIN" ? false : true}
                      >
                        <option value="-" disabled={true} selected={true}> - </option>
                        {FRuser?.users?.data?.map((item, index) => {
                          return (
                            <option value={item?.id}>{item?.firstName}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      class="col-12"
                      style={{ float: "left", paddingBottom: 20 }}
                    >
                      <div style={{ paddingBottom: 5 }}>
                        ລາຍລະອຽດການປຶກສາ
                        <a style={{ color: "#F91B1B", paddingBottom: 5 }}>*</a>
                      </div>
                      <div class="form-group">
                        <textarea
                          class="form-control"
                          rows="3"
                          id="detail"
                          type="text"
                          name="detail"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.detail}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faBookmark} className="text-a" />
                    <label style={{ marginLeft: 5, paddingLeft: 5 }}>
                      ລາຍລະອຽດການຕິດຕາມ{" "}
                    </label>
                  </div>
                  <div className="row" style={{ marginTop: 5 }}>
                    <div
                      className="col-3"
                      style={{ float: "left", paddingBottom: 20 }}
                    >
                      <div>
                        ວັນທີນັດ
                        <a style={{ color: "#F91B1B", paddingBottom: 5 }}> *</a>
                      </div>
                      <input
                        type="datetime-local"
                        className="col-6"
                        type="datetime-local"
                        className="form-control empty"
                        id="appointmentDate"
                        name="appointmentDate"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.appointmentDate}
                      ></input>
                    </div>
                  </div>
                  <div class="row" style={{ marginTop: 5 }}>
                    <div
                      class="col-6"
                      style={{ float: "left", paddingBottom: 20 }}
                    >
                      <div>ສະຖານທີ່</div>
                      <input
                        type="text"
                        class="form-control empty"
                        placeholder="ກະລຸນາປ້ອນ..."
                        id="appointmentAddress"
                        name="appointmentAddress"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.appointmentAddress}
                      />
                    </div>
                  </div>
                  <div class="row" style={{ marginTop: 5 }}>
                    <div
                      class="col-6"
                      style={{ float: "left", paddingBottom: 20 }}
                    >
                      <div>
                        Mentor{" "}
                        <a style={{ color: "#F91B1B", paddingBottom: 5 }}>
                          {" "}
                          (ຖ້າຈຳເປັນ)
                        </a>{" "}
                      </div>
                      <select
                        class="custom-select col-12"
                        style={{
                          float: "center",
                          fontWeight: "bold",
                          margin: "5",
                        }}
                        id="mentorId"
                        name="mentorId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.mentorId}
                      >
                        <option value="-" disabled={true} selected={true}> - </option>
                        {MENTORuser?.users?.data?.map((item, index) => {
                          return (
                            <option value={item?.id}>{item?.firstName}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      class="col-12"
                      style={{ float: "left", paddingBottom: 20 }}
                    >
                      <div style={{ paddingBottom: 5 }}>ລາຍລະອຽດການຕິດຕາມ</div>
                      <div class="form-group">
                        <textarea
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          id="followUp"
                          name="followUp"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.followUp}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <Form>
                  <Row>
                    <Col>
                      <Button
                        style={{ marginLeft: 710, marginRight: 10 }}
                        variant="btn btn-outline-danger col-2"
                        onClick={EditClose}
                      >
                        {" "}
                        ຍົກເລິກ
                      </Button>
                      <Button
                        type="submit"
                        variant="btn btn-secondary btn-app col-2"
                      >
                        ບັນທຶກ
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      {/* delete consulting */}
      <Modal show={showDelete} onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title>ລຶບ ຂໍ້ມູນການປຶກສາ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row center">
            <div>ທ່ານຕ້ອງການລຶບຂໍ້ມູນການປຶກສາ</div>
            <div
              style={{
                color: "red",
                fontWeight: "bold",
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              {_renderName(data?.consulting?.userId)}
            </div>
            <div>ນີ້ບໍ ?</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseDelete}>
            ຍົກເລິກ
          </Button>
          <Button variant="primary btn-app" onClick={() => _confrimeDelete()}>
            ຢືນຢັນການລຶບ
          </Button>
        </Modal.Footer>
      </Modal>
    </div >
  );
}
