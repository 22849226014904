import React, { useState, useRef, useEffect } from "react";
import useReactRouter from "use-react-router";
import Image from "react-bootstrap/Image";
import ImageLogo from "../../../image/profile.png";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import axios from "axios";
import { useMutation } from "@apollo/react-hooks";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { ErrorValidationBox } from "../../../common/ErrorValidationBox";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faBookmark,
  faFileAlt,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

import {
  TITLE_HEADER,
  HEADER,
  BODY_DISCOUNT_SHOPDETAIL,
} from "../../../consts";
import { STORE, COMPANY_LIST } from "../../../consts/router";
import { PRESINGED_URL, CREATE_COMPANY } from "../apollo";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    // fontFamily:'FontAwesome',
    margin: theme.spacing(3),
  },
}));
export default function CompanyAdd() {
  const { history, location, match } = useReactRouter();
  // history
  const _CompanyList = () => {
    history.push(`${COMPANY_LIST}`);
  };
  const [uploadPhoto, setUploadPhoto] = useMutation(PRESINGED_URL);
  const [createCompany, setCreateCompany] = useMutation(CREATE_COMPANY);

  // upload pic
  const [resPhoto, setResPhoto] = useState();
  const [file, setFile] = useState("");
  const [imageLoading, setImageLoading] = useState();
  const handleUpload = async (event) => {
    setImageLoading("");
    try {
      setFile(event.target.files[0]);
      let formData = new FormData();
      let fileData = event.target.files[0].name;
      let fileDataBoole = event.target.files[0];
      const resUploadPhoto = await uploadPhoto({
        variables: { fileName: fileData },
      });
      let afterUpload = await axios({
        method: "put",
        url: resUploadPhoto.data.preSignedUrl.url,
        data: fileDataBoole,
        headers: {
          "Content-Type": " file/*; image/*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      });
      setResPhoto(afterUpload.config.data.name);
    } catch (error) { }
  };
  // lung jak upload leo pic ja ma so u nee
  const ImageThumb = ({ image }) => {
    return (
      <img
        src={URL.createObjectURL(image)}
        alt={image.name}
        style={{
          borderRadius: "5%",
          height: 250,
          width: 250,
        }}
      />
    );
  };
  const _createCompany = async (values) => {
    const resCreateCompany = await createCompany({
      variables: {
        data: {
          companyName: values.companyName,
          companyType: values.companyType,
          affiliation: values.affiliation,
          image: resPhoto,
          addressCompany: {
            province: values.province,
            district: values.district,
            village: values.village,
            detail: values.detail,
          },
          contactChannel: {
            phone: String(values.phone),
            name: values.name,
            area: values.area,
            email: values.email,
            emailCompany: values.emailCompany,
          },
          webSiteLink: values.webSiteLink,
          scholarships: values.scholarships,
          interns: values.interns,
          phoneOffice: String(values.phoneOffice),
          recrui: values.recrui,
          volunteers: values.volunteers,
          note: values.note,
        },
      },
    })
    if (resCreateCompany?.data?.createCompany?.id) {
      Swal.fire({
        icon: "success",
        title: "ການບັນທຶກສຳເລັດ",
        showConfirmButton: false,
        timer: 1500,
      }).then(function () {
        history.push(`${COMPANY_LIST}`);
        window.location.reload(true);
      });
    } else {
      Swal.fire({
        icon: "failed",
        title: "ການບັນທຶກບໍ່ສຳເລັດ",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  const CounselingSchema = Yup.object().shape({
    companyName: Yup.string().required("ກະລຸນາປ້ອນ!"),
    companyType: Yup.string().required("ກະລຸນາປ້ອນ!"),
  });
  return (
    <div style={TITLE_HEADER}>
      <h6 style={{ marginLeft: 25, marginTop: 10, marginBottom: 20 }}>
        <a
          href=""
          onClick={() => _CompanyList()}
          style={{ color: "#766AF0", paddingTop: 50 }}
        >
          ບໍລິສັດທັງໝົດ »{" "}
        </a>
        ເພີ່ມບໍລິສັດ
      </h6>
      <div
        style={{
          backgroundColor: "#FFF",
          boxShadow: "0px 0px 6px #F7F7F7",
          borderRadius: 8,
          opacity: 1,
          marginLeft: 20,
          paddingLeft: 5,
          marginBottom: 20,
          paddingBottom: 5,
          width: "97%",
        }}
      >
        <Formik
          initialValues={{}}
          validate={(values) => { }}
          validationSchema={CounselingSchema}
          onSubmit={(values, { setSubmitting }) => {
            _createCompany(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div
                className="container-fluid "
                style={{
                  paddingLeft: 25,
                  marginLeft: 25,
                  height: "100%",
                  width: "100%",
                  padding: 40,
                }}
              >
                <div className="row">
                  <h5>
                    ເພີ່ມບໍລິສັດ
                    <br />
                  </h5>
                </div>
                <div className="row">
                  <div>
                    <input
                      type="file"
                      id="file-upload"
                      onChange={handleUpload}
                      hidden
                    />
                    <label for="file-upload">
                      <div
                        style={{
                          backgroundColor: "#E4E4E4E4",
                          height: 250,
                          width: 250,
                          borderRadius: "5%",
                          cursor: "pointer",
                          display: "flex",
                        }}
                      >
                        {file ? (
                          <ImageThumb image={file} />
                        ) : (
                            <div
                              style={{
                                display: "flex",
                                height: 250,
                                width: 250,
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <p
                                style={{
                                  color: "#fff",
                                  fontSize: 80,
                                  fontWeight: "bold",
                                }}
                              >
                                +
                            </p>
                            </div>
                          )}
                      </div>
                    </label>
                    {/* progass */}
                    {imageLoading ? (
                      <div class="progress" style={{ height: 20 }}>
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style={{
                            width: `${imageLoading}%`,
                            backgroundColor: "#816aae",
                          }}
                          aria-valuenow={imageLoading}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {imageLoading}%
                        </div>
                      </div>
                    ) : (
                        <div style={{ height: 20 }} />
                      )}
                  </div>
                  <div className="col-9" style={{ marginLeft: 100 }}>
                    <div
                      className="row"
                      style={{
                        paddingTop: 5,
                        marginTop: 5,
                        marginLeft: 3,
                        color: "#766AF0",
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon
                        icon={faFileAlt}
                        style={{
                          color: "#766AF0",
                          marginRight: 10,
                          marginRight: 15,
                        }}
                      />
                      ຂໍ້ມູນທົ່ວໄປ
                    </div>

                    <div
                      className="row"
                      style={{ paddingTop: 5, marginTop: 5 }}
                    >
                      <div className="col-5">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          ຊື່ບໍລິສັດ <a style={{ color: "red" }}>*</a>
                        </label>
                        <div style={{ height: 10 }}></div>
                        <input
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="-"
                          type="text"
                          name="companyName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.companyName}
                        />
                        <ErrorValidationBox
                          errors={errors}
                          fieldName={"companyName"}
                        />
                      </div>
                      <div className="col-5">
                        <label for="inputState" className="form-label">
                          ປະເພດຂອງບໍລິສັດ <a style={{ color: "red" }}>*</a>
                        </label>
                        <div style={{ height: 10 }}></div>
                        <select
                          class="form-control"
                          id="inputGroupSelect01"
                          style={{
                            float: "right",
                            fontWeight: "bold",
                            marginRight: "5px",
                            marginLeft: "5px",
                          }}
                          type="text"
                          name="companyType"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.companyType}
                        >
                          <option value="" disabled={true} selected={true}>-</option>
                          <option value="ລັດ">ລັດ</option>
                          <option value="ເອກະຊົນ">ເອກະຊົນ</option>
                          <option value="NGO">NGO</option>
                          <option value="INGO">INGO</option>
                          <option value="ສະມາຄົມ">ສະມາຄົມ</option>
                          <option value="ວິສະຫະກິດ">ວິສະຫະກິດ</option>
                        </select>
                        <ErrorValidationBox
                          errors={errors}
                          fieldName={"companyType"}
                        />
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{ paddingTop: 5, marginTop: 5 }}
                    >
                      <div className="col-5">
                        <label
                          for="exampleFormControlInput1"
                          className="form-label"
                        >
                          ສັງກັດ
                        </label>
                        <div style={{ height: 10 }}></div>
                        <input
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="-"
                          type="text"
                          name="affiliation"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.affiliation}
                        />
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{ paddingTop: 5, marginTop: 5, marginLeft: 3 }}
                    >
                      <label for="inputState" className="form-label">
                        ທີ່ຕັ້ງບໍລິສັດ
                      </label>
                      <br />
                    </div>
                    <div
                      className="row"
                      style={{ paddingTop: 5, marginTop: 5 }}
                    >
                      <div className="col-3">
                        <input
                          type="text"
                          name="village"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.village}
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="ປ້ອນບ້ານ..."
                        />
                      </div>
                      <div className="col-3">
                        <input
                          type="text"
                          name="district"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.district}
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="ປ້ອນເມືອງ..."
                        />
                      </div>
                      <div className="col-4">
                        <select
                          id="inputState"
                          className="form-control "
                          style={{ width: "100%" }}
                          type="text"
                          name="province"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.province}
                        >
                          <option value="-" disabled={true} selected={true}>ເລືອກແຂວງ</option>
                          <option value="Phongsali">ຜົ້ງສາລີ</option>
                          <option value="LuangNamtha">ຫຼວງນ້ຳທາ</option>
                          <option value="Huoaphan">ຫົວພັນ</option>
                          <option value="Borkeo">ບໍ່ແກ້ວ</option>
                          <option value="Oudomxay">ອຸດົມໄຊ</option>
                          <option value="Sayaboury">ໄຊຍະບູລີ</option>
                          <option value="Xiengkhouang">ຊຽງຂວາງ</option>
                          <option value="LuangPrabang">ຫຼວງພະບາງ</option>
                          <option value="Vientiane">ວຽງຈັນ</option>
                          <option value="VientianeCapital">
                            ນະຄອນຫຼວງວຽງຈັນ
                          </option>
                          <option value="Borikhamsay">ບໍລິຄຳໄຊ</option>
                          <option value="Khammuoan">ຄຳມ່ວນ</option>
                          <option value="Savannakhet">ສະຫວັນນະເຂດ</option>
                          <option value="Salavanh">ສາລະວັນ</option>
                          <option value="Sekong">ເຊກອງ</option>
                          <option value="Attapue">ອັດຕະປື</option>
                          <option value="Champasak">ຈຳປາສັກ</option>
                          <option value="Saisomboun">ໄຊສົມບູນ</option>
                        </select>
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{ paddingTop: 5, marginTop: 5 }}
                    >
                      <div className="col-5">
                        <label for="inputState" className="form-label">
                          ເບິໂທຂອງບໍລິສັດ
                        </label>
                        <div style={{ height: 10 }}></div>
                        <input
                          type="number"
                          name="phoneOffice"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phoneOffice}
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="-"
                        />
                      </div>
                      <div className="col-5">
                        <label for="inputState" className="form-label">
                          ເວັບໄຊ້
                        </label>
                        <div style={{ height: 10 }}></div>
                        <input
                          type="text"
                          name="webSiteLink"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.webSiteLink}
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="-"
                        />
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{ paddingTop: 5, marginTop: 5 }}
                    >
                      <div className="col-5">
                        <label for="inputState" className="form-label">
                          ອີເມລ
                        </label>
                        <div style={{ height: 10 }}></div>
                        <input
                          type="text"
                          name="emailCompany"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.emailCompany}
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="-"
                        />
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{ paddingTop: 5, marginTop: 5 }}
                    >
                      <label
                        for="inputState"
                        className="form-label"
                        style={{
                          marginLeft: 10,
                          color: "#766AF0",
                          marginTop: 30,
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPhone}
                          style={{
                            color: "#766AF0",
                            marginRight: 10,
                          }}
                        />
                        ຊ່ອງທາງການຕິດຕໍ່ (ຜູ້ຕາງໜ້າ)
                      </label>
                    </div>
                    <div
                      className="row"
                      style={{ paddingTop: 5, marginTop: 5 }}
                    >
                      <div className="col-5">
                        <label for="inputState" className="form-label">
                          ຊື່ ແລະ ນາມສະກຸນ
                        </label>
                        <div style={{ height: 10 }}></div>
                        <input
                          type="text"
                          name="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="-"
                        />
                      </div>
                      <div className="col-5">
                        <label for="inputState" className="form-label">
                          ຕຳແໜ່ງ
                        </label>
                        <div style={{ height: 10 }}></div>
                        <input
                          type="text"
                          name="area"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.area}
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="-"
                        />
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{ paddingTop: 5, marginTop: 5 }}
                    >
                      <div className="col-5">
                        <label for="inputState" className="form-label">
                          ເບີໂທ
                        </label>
                        <div style={{ height: 10 }}></div>
                        <input
                          type="number"
                          name="phone"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone}
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="-"
                        />
                      </div>
                      <div className="col-5">
                        <label for="inputState" className="form-label">
                          ອີເມວ
                        </label>
                        <div style={{ height: 10 }}></div>
                        <input
                          type="text"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="-"
                        />
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{
                        marginTop: 40,
                        marginLeft: 3,
                        color: "#766AF0",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faBookmark}
                        style={{
                          color: "#766AF0",
                          marginRight: 15,
                        }}
                      />
                      ຂໍ້ມູນເພີ່ມເຕີມ
                    </div>
                    <div className="row" style={{ paddingTop: 5 }}>
                      <div className="col-10">
                        <label for="inputState" className="form-label">
                          ທຶນການສຶກສາ
                        </label>
                        <div style={{ height: 10 }}></div>
                        <input
                          type="text"
                          name="scholarships"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.scholarships}
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="-"
                        />
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{ paddingTop: 5, marginTop: 5 }}
                    >
                      <div className="col-10">
                        <label for="inputState" className="form-label">
                          ນັກຮຽນຝຶກຫັດ
                        </label>
                        <div style={{ height: 10 }}></div>
                        <input
                          type="text"
                          name="interns"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.interns}
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="-"
                        />
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{ paddingTop: 5, marginTop: 5 }}
                    >
                      <div className="col-10">
                        <label for="inputState" className="form-label">
                          ຮັບພະນັກງານ
                        </label>
                        <div style={{ height: 10 }}></div>
                        <input
                          type="text"
                          name="recrui"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.recrui}
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="-"
                        />
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{ paddingTop: 5, marginTop: 5 }}
                    >
                      <div className="col-10">
                        <label for="inputState" className="form-label">
                          ຮັບອາສາສະໝັກ
                        </label>
                        <div style={{ height: 10 }}></div>
                        <input
                          type="text"
                          name="volunteers"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.volunteers}
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="-"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="row"
                style={{
                  paddingTop: 10,
                  marginTop: 10,
                  paddingRight: 5,
                  marginRight: 5,
                }}
              >
                <div
                  className="col"
                  style={{
                    paddingTop: 10,
                    marginTop: 10,
                    float: "right",
                    textAlign: "right",
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-outline-danger col-2"
                    style={{ paddingRight: 5, marginRight: 5 }}
                    onClick={() => _CompanyList()}
                  >
                    ຍົກເລີກ
                  </button>
                  <button
                    type="submit"
                    className="btn btn-secondary col-2"
                    style={{
                      background:
                        "transparent linear-gradient(269deg, #766AF0 0%, #978EF3 100%) 0% 0% no-repeat padding-box",
                      textAlign: "center",
                      letterSpacing: 0,
                      opacity: 1,
                      paddingRight: 5,
                      marginRight: 5,
                    }}
                  >
                    ຢືນຢັນ
                  </button>
                </div>
                <div className="row"></div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}
