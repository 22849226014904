import React, { useState, useEffect } from "react"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import Form from "react-bootstrap/Form"
import Image from "react-bootstrap/Image"
import Dropdown from "react-bootstrap/Dropdown"
import NavDropdown from "react-bootstrap/NavDropdown"
import { USER_KEY, URL_FOR_SHOW_PHOTO } from "../consts"
import { STAFF_DETAIL } from "../consts/router"
import useReactRouter from "use-react-router"
import ImageProfile from "../image/profile.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSignOutAlt, faUser } from "@fortawesome/free-solid-svg-icons"
export default function NavBar() {
  const { history, location, match } = useReactRouter()
  const [userData, setUserData] = useState({})
  useEffect(() => {
    const _resData = localStorage.getItem(USER_KEY)
    const _localJson = JSON.parse(_resData)
    setUserData(_localJson)
  }, [])

  const _onLogout = () => {
    localStorage.clear()
    sessionStorage.clear()
    history.push(`/`)
  }

  const _onDetailProfile = () => {
    history.push(`${STAFF_DETAIL}/${userData?.loginUser?.data?.id}`, userData?.loginUser?.data?.id)
  }
  return (
    <div className="theme-red">
      <Navbar
        style={{
          backgroundColor: "#fff",
          boxShadow: "3px 0px 3px rgba(0, 0, 0, 0.16)",
          color: "#CC0000",
          width: "100%",
          height: 64,
          position: "fixed",
          zIndex: 1,
          marginLeft: 60,
          paddingRight: 80,
          zIndex: 1001
        }}
        variant="dark"
      >
        <Navbar.Brand style={{ color: "#909090" }} href="#">
          {/*	<Image src={ImageLogo} height={40} width={150} />*/}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto" />
          <Form inline>
            <Dropdown>
              <Dropdown.Toggle
                style={{ color: "#909090" }}
                variant=""
                id="dropdown-basic"
              >
                {userData
                  ? (userData?.loginUser?.data?.firstName
                    ? userData?.loginUser?.data?.firstName
                    : "") +
                  " " +
                  (userData?.loginUser?.data?.lastName
                    ? userData?.loginUser?.data?.lastName
                    : "")
                  : ""}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  style={{ color: "#909090" }}
                  onClick={() => _onDetailProfile()}
                >
                  <FontAwesomeIcon icon={faUser} style={{ color: "#766AF0" }} />
                  Profile
                </Dropdown.Item>
                <NavDropdown.Divider />
                <Dropdown.Item
                  style={{ color: "#909090" }}
                  onClick={() => _onLogout()}
                >
                  <FontAwesomeIcon
                    icon={faSignOutAlt}
                    style={{ color: "#766AF0" }}
                  />
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Image
              src={URL_FOR_SHOW_PHOTO + userData?.loginUser?.data?.image}
              width={50}
              height={50}
              roundedCircle
            />
          </Form>
        </Navbar.Collapse>
      </Navbar>
    </div>
  )
}
