import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import Image from "react-bootstrap/Image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faBookmark,
  faList,
  faUser,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
  TITLE_HEADER,
  HEADER,
  BODY,
  URL_FOR_SHOW_PHOTO,
  USER_KEY,
} from "../../../consts";
import { STAFF_DETAIL, STAFF_ADD } from "../../../consts/router";
import { USERS, CONSULTINGS } from "../apollo";
import { formatDate } from "../../../helper/super";
import profileImage from "../../../image/profile.png";

const _colorRole = (role) => {
  if (role === "ADMIN") {
    return (
      <div
        className="btn btn-secondary btn-lg btn-block btn-adm"
        style={{ fontSize: 15 }}
      >
        {" "}
        Admin{" "}
      </div>
    );
  } else if (role === "FIRST_RESPONDER") {
    return (
      <div
        className="btn"
        style={{ fontSize: 15, backgroundColor: "#FFD9D1", border: 0 }}
      >
        {" "}
        FirstResponder{" "}
      </div>
    );
  }
};

const ADMIN = "ADMIN";
const FIRST_RESPONDER = "FIRST_RESPONDER";

export default function StaffList() {
  const { history, location, match } = useReactRouter();
  const _limit = match.params.limit;
  const _skip = match.params.skip;
  const [selectedRoles, setSelectedRoles] = useState([ADMIN, FIRST_RESPONDER]);
  const [selectedPhone, setSelectedPhone] = useState();
  const [allUsersId, setAllUsersId] = useState([]);

  const { loading, error, data } = useQuery(USERS, {
    variables: {
      where: { role_in: selectedRoles, phone: selectedPhone },
      limit: parseInt(_limit),
      skip: parseInt(_skip) * parseInt(_limit),
    },
  });
  const { loading: allUsersLoading, error: allUsersError, data: allUsersData } = useQuery(USERS, {
    variables: {
      where: { role_in: selectedRoles, phone: selectedPhone },
    },
  });
  /***
 * to get all users data
 */
  useEffect(() => {
    if (allUsersData?.users?.data?.length > 0) {
      let allUsersId = allUsersData?.users?.data?.map(data => data.id);
      setAllUsersId([...allUsersId]);
    }
  }, [allUsersData]);

  useEffect(() => {
    if (data?.users?.data?.length > 0) {
      _getArrayPageNumber();
    }
  }, [data]);
  const [totalPage, setTotalPage] = useState([]);
  const _getArrayPageNumber = () => {
    let rowPage = [];
    let _total = 0;
    if (data?.users?.total % parseInt(_limit) != 0) {
      _total = parseInt(data?.users?.total) / parseInt(_limit) + 1;
    } else {
      _total = parseInt(data?.users?.total) / parseInt(_limit);
    }
    for (let i = 1; i <= _total; i++) {
      rowPage.push(i);
    }
    setTotalPage(rowPage);
  };
  const _changePage = (index) => {
    history.push("/staff/limit/" + parseInt(_limit) + "/skip/" + index);
  };
  // history
  const _staffDetail = (id) => {
    // history.push(`${STAFF_DETAIL}/${id}`, id);
    history.push({
      pathname: `${STAFF_DETAIL}/${id}`,
      state: { data: allUsersId }
    });
  };
  const _staffAdd = () => {
    history.push(`${STAFF_ADD}`);
  };

  const _onRoleChange = (e) => {
    let choosedRole = e.target.value;
    if (!choosedRole) choosedRole = [ADMIN, FIRST_RESPONDER];
    setSelectedRoles(choosedRole);
  };

  const _onPhoneChange = (e) => {
    const inputedPhone = e.target.value;
    setSelectedPhone(inputedPhone);
  };
  const {
    loading: consultingLoading,
    error: consultingError,
    data: consultingData,
  } = useQuery(CONSULTINGS, {
    variables: {
      where: {
        status: "ONGOING_FR",
      },
    },
  });
  const _renderACTIVE = (e) => {
    let active = [];
    for (let i = 0; i < consultingData?.consultings?.data?.length; i++) {
      active.push(consultingData?.consultings?.data[i]?.firstResponderId);
    }
    let res = active?.filter((x) => x === e);
    if (res?.length > 0) return res?.length;
  };
  const [userData, setUserData] = useState({});
  useEffect(() => {
    const _resData = localStorage.getItem(USER_KEY);
    const _localJson = JSON.parse(_resData);
    setUserData(_localJson);
  }, []);
  let check = userData?.loginUser?.data?.role;
  return (
    <div style={TITLE_HEADER}>
      {/* EDIT Bosly */}
      <div
        style={{
          marginTop: 25,
          marginLeft: 25,
          marginRight: 25,
          padding: 32,
          background: "#FFFFFF",
          borderRadius: 8,
          opacity: 1,
          width: "auto",
        }}
      >
        <div className="row" style={{ padding: 20 }}>
          <div className="col-md-12" style={{ fontSize: "20px" }}>
            ພະນັກງານທັງໝົດ ({data?.users?.total})
          </div>
        </div>
        <div style={{ paddingBottom: 20, marginRight: 25 }}>
          {check === "ADMIN" && (
            <button
              type="button"
              className="btn btn-secondary btn-app"
              style={{ float: "right", margin: "5" }}
              onClick={() => _staffAdd()}
            >
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: 20 }} />{" "}
              ເພີ່ມພະນັກງານ{" "}
            </button>
          )}

          <select
            onChange={(e) => _onRoleChange(e)}
            className="form-control col-2"
            id="inputGroupSelect01"
            style={{
              float: "right",
              fontWeight: "bold",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          >
            <option value="">ALL</option>
            <option value={ADMIN}>ADMIN</option>
            <option value={FIRST_RESPONDER}>FIRST_RESPONDER</option>
          </select>
          <input
            type="text"
            style={{ float: "right", marginRight: "5px", marginLeft: "5px" }}
            className="form-control col-3 empty"
            placeholder="&#xF002;  ຄົ້ນຫາຕາມເບີໂທ"
            onChange={(e) => _onPhoneChange(e)}
          />
        </div>

        <div className="col-md-12" style={{ marginTop: 30 }}>
          <table className="table table-hover">
            <thead>
              <tr className="center">
                <th scope="col">#</th>
                <th scope="col">ຮູບພາບ</th>
                <th scope="col">ຊື່</th>
                <th scope="col">Role</th>
                <th scope="col">ເບີໂທ</th>
                <th scope="col">Active FR</th>
                <th scope="col">ວັນທີ່ສ້າງ</th>
              </tr>
            </thead>
            <tbody className="center">
              {data?.users?.data.map((data, index) => {
                return (
                  <tr key={index} onClick={() => _staffDetail(data?.id)}>
                    <th scope="row">
                      {index + 1 + parseInt(_limit) * parseInt(_skip)}
                    </th>
                    {data?.image ? (
                      <td>
                        <Image
                          src={URL_FOR_SHOW_PHOTO + data?.image}
                          alt="stellaImage"
                          style={{
                            height: 40,
                            width: 40,
                            borderRadius: "50%",
                          }}
                        />
                      </td>
                    ) : (
                      <td>
                        <Image
                          src={profileImage + data?.image}
                          alt="stellaImage"
                          style={{
                            height: 40,
                            width: 40,
                            borderRadius: "50%",
                          }}
                        />
                      </td>
                    )}

                    <td>{data?.firstName ? data?.firstName : "-"}</td>
                    <td style={{ width: "50px" }}>{_colorRole(data?.role)}</td>
                    <td>{data?.phone ? data?.phone : "-"}</td>
                    <td>{_renderACTIVE(data?.id)}</td>
                    <td>
                      {formatDate(data?.createdAt ? data?.createdAt : "-")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="row center">
            {totalPage?.map((numbersPages, index) => {
              return (
                <button
                  key={index}
                  onClick={() => _changePage(index)}
                  style={{
                    width: 30,
                    height: 30,
                    border: "1px solid #9999ff",
                    borderRadius: "10%",
                    justifyContent: "center",
                    textAlign: "center",
                    marginRight: 2,
                    backgroundColor:
                      parseInt(_skip) == index ? "#9999ff" : "#fff",
                    color: parseInt(_skip) == index ? "#fff" : "#000",
                  }}
                >
                  {numbersPages}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}
