import React, { useState, useRef, useEffect } from "react";
import useReactRouter from "use-react-router";
import Image from "react-bootstrap/Image";
import ImageLogo from "../../../image/profile.png";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  CONSULTING_CATEGORY,
  UPDATE_CONSULTING_CATEGORYS,
  PRESINGED_URL,
} from "../apollo";
import * as Yup from "yup";
import { ErrorValidationBox } from "../../../common/ErrorValidationBox";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { Formik } from "formik";
import Swal from "sweetalert2";
import axios from "axios";
import { TITLE_HEADER, HEADER, URL_FOR_SHOW_PHOTO } from "../../../consts";
import {
  CONSULTING_CATAGORY_LIST,
  CONSULTING_CATAGORY_DETAIL,
} from "../../../consts/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));
export default function CatagoryAdd() {
  const { history, location, match } = useReactRouter();
  const [newData, setNewData] = useState();
  const [updateCategory, setUpdateCategory] = useMutation(
    UPDATE_CONSULTING_CATEGORYS
  );
  const [uploadPhoto, setUploadPhoto] = useMutation(PRESINGED_URL);

  const [getData, { loading, called, data }] = useLazyQuery(
    CONSULTING_CATEGORY,
    {
      variables: { where: { id: match?.params?.id } },
    }
  );

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (data?.consultingCategory) {
      setNewData(data.consultingCategory);
    }
  }, [data]);

  const classes = useStyles();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // history
  const _CatagoryList = () => {
    history.push(`${CONSULTING_CATAGORY_LIST}`);
  };
  const _CatagoryDetail = (id) => {
    // history.push(`${CONSULTING_CATAGORY_DETAIL}/${id}`, id);
    history.push({
      pathname: `${CONSULTING_CATAGORY_DETAIL}/${id}`,
      state: { data: location?.state?.data }
    });
  };
  // =====================>>>>>>>>>>>>>
  // updateCategory
  const _updateCategory = async (values) => {
    const resUpdateCategory = await updateCategory({
      variables: {
        data: {
          name: values.name,
          image: resPhoto,
          detail: values.detail,
          note: values.note,
        },
        where: { id: location.state.id },
      },
    })
    if (resUpdateCategory?.data?.updateConsultingCategory?.id) {
      Swal.fire({
        icon: "success",
        title: "ການອັບເດດສຳເລັດ",
        showConfirmButton: false,
        timer: 1500,
      }).then(function () {
        // history.push(`${CONSULTING_CATAGORY_DETAIL}/${match?.params?.id}`, match?.params?.id);
        history.push({
          pathname: `${CONSULTING_CATAGORY_DETAIL}/${match?.params?.id}`,
          state: { data: location?.state?.data }
        });
        window.location.reload();
      });
    } else {
      Swal.fire({
        icon: "failed",
        title: "ການອັບເດດລົ້ມເຫລວ",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  // upload pic
  const [resPhoto, setResPhoto] = useState();
  const [file, setFile] = useState("");
  const [imageLoading, setImageLoading] = useState();
  const handleUpload = async (event) => {
    setImageLoading("");
    try {
      setFile(event.target.files[0]);
      let formData = new FormData();
      let fileData = event.target.files[0].name;
      let fileDataBoole = event.target.files[0];
      const resUploadPhoto = await uploadPhoto({
        variables: { fileName: fileData },
      });
      let afterUpload = await axios({
        method: "put",
        url: resUploadPhoto.data.preSignedUrl.url,
        data: fileDataBoole,
        headers: {
          "Content-Type": " file/*; image/*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      });
      setResPhoto(afterUpload.config.data.name);
    } catch (error) { }
  };
  // lung jak upload leo pic ja ma so u nee
  const ImageThumb = ({ image }) => {
    return (
      <img
        src={URL.createObjectURL(image)}
        alt={image.name}
        style={{
          borderRadius: "5%",
          height: 300,
          width: 300,
        }}
      />
    );
  };
  const CounselingSchema = Yup.object().shape({
    name: Yup.string().required("ກະລຸນາປ້ອນ!"),
  });
  return (
    <div style={TITLE_HEADER}>
      <h6 style={{ marginLeft: 25, marginBottom: 25 }}>
        <a
          href=""
          onClick={() => _CatagoryDetail(location.state.id)}
          style={{ color: "#766AF0", paddingTop: 50 }}
        >
          ໝວດການປືກສາ | <i class="fa fa-list" aria-hidden="true"></i> » ລາຍຊື່
          ໝວດການປືກສາ »
        </a>
        ແກ້ໄຂ ໝວດການປືກສາ
      </h6>
      <div
        style={{
          backgroundColor: "#FFF",
          boxShadow: "0px 0px 6px #F7F7F7",
          borderRadius: 8,
          opacity: 1,
          marginLeft: 20,
          paddingLeft: 5,
          marginBottom: 20,
          paddingBottom: 5,
          width: "97%",
        }}
      >
        <div
          className="container-fluid "
          style={{
            paddingLeft: 25,
            marginLeft: 25,
            height: "100%",
            width: "100%",
            padding: 10,
          }}
        >
          <div className="row">
            <h5>
              ແກ້ໄຂ ໝວດການປືກສາ
              <br />
            </h5>
          </div>
          <div className="row">
            <input
              type="file"
              id="file-upload"
              onChange={handleUpload}
              hidden
            />
            <label for="file-upload">
              <div
                style={{
                  backgroundColor: "#E4E4E4E4",
                  height: 300,
                  width: 300,
                  borderRadius: "5%",
                  cursor: "pointer",
                  display: "flex",
                }}
              >
                {file ? (
                  <ImageThumb image={file} />
                ) : (
                  <Image
                    src={URL_FOR_SHOW_PHOTO + newData?.image}
                    alt="stellaIcon"
                    width="100"
                    height="100"
                    style={{
                      height: 300,
                      width: 300,
                      borderRadius: "5%",
                      cursor: "pointer",
                      display: "flex",
                    }}
                  />
                )}
              </div>
            </label>
            {/* progass */}
            {imageLoading ? (
              <div class="progress" style={{ height: 20 }}>
                <div
                  class="progress-bar"
                  role="progressbar"
                  style={{
                    width: `${imageLoading}%`,
                    backgroundColor: "#816aae",
                  }}
                  aria-valuenow={imageLoading}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {imageLoading}%
                </div>
              </div>
            ) : (
              <div style={{ height: 20 }} />
            )}
          </div>

          {newData && (
            <Formik
              initialValues={{
                name: newData?.name || "",
                detail: newData?.detail,
                note: newData?.note,
              }}
              validate={(values) => { }}
              validationSchema={CounselingSchema}
              onSubmit={(values, { setSubmitting }) => {
                _updateCategory(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row" style={{ paddingTop: 5, marginTop: 5 }}>
                    <div className="col-6">
                      <label for="exampleFormControlInput1" class="form-label">
                        ຊື່ຄຳປືກສາ <a style={{ color: "red" }}>*</a>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="-"
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                      />
                      <ErrorValidationBox errors={errors} fieldName={"name"} />
                    </div>
                  </div>
                  <div className="row" style={{ paddingTop: 5, marginTop: 5 }}>
                    <div className="col-6">
                      <label for="exampleFormControlInput1" class="form-label">
                        ລາຍລະອຽດ
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="-"
                        name="detail"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.detail}
                      />
                    </div>
                  </div>
                  <div className="row" style={{ paddingTop: 5, marginTop: 5 }}>
                    <div className="col-6">
                      <label for="exampleFormControlInput1" class="form-label">
                        ໝາຍເຫດ
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="-"
                        name="note"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.note}
                      />
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      paddingTop: 10,
                      marginTop: 10,
                      paddingRight: 5,
                      marginRight: 5,
                    }}
                  >
                    <div
                      className="col"
                      style={{
                        paddingTop: 10,
                        marginTop: 10,
                        float: "right",
                        textAlign: "right",
                      }}
                    >
                      <button
                        type="button"
                        className="btn btn-outline-danger col-2"
                        style={{
                          paddingRight: 5,
                          marginRight: 5,
                        }}
                        onClick={() => _CatagoryDetail(location.state.id)}
                      >
                        ຍົກເລິກ
                      </button>
                      <button
                        type="submit"
                        className="btn btn-secondary col-2"
                        style={{
                          background:
                            "transparent linear-gradient(269deg, #766AF0 0%, #978EF3 100%) 0% 0% no-repeat padding-box",
                          textAlign: "center",
                          letterSpacing: 0,
                          opacity: 1,
                          paddingRight: 5,
                          marginRight: 5,
                        }}
                      >
                        ບັນທຶກ
                      </button>
                    </div>
                  </div>
                  <div style={{ marginBottom: 20 }}></div>
                </form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
}
