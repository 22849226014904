import React, { useState, useRef, useEffect } from "react";
import useReactRouter from "use-react-router";
import Image from "react-bootstrap/Image";
import ImageLogo from "../../../image/profile.png";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import { useMutation } from "@apollo/react-hooks";
import Swal from "sweetalert2";
import axios from "axios";
import * as Yup from "yup";
import { ErrorValidationBox } from "../../../common/ErrorValidationBox";

import { TITLE_HEADER, HEADER } from "../../../consts";
import { CONSULTING_CATAGORY_LIST } from "../../../consts/router";
import { CREATE_CONSULTING_CATEGORYS, PRESINGED_URL } from "../apollo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));
export default function CatagoryAdd() {
  const { history, location, match } = useReactRouter();
  const classes = useStyles();
  const [show, setShow] = useState(false);

  const [createCategory, setCreateCategory] = useMutation(
    CREATE_CONSULTING_CATEGORYS
  );
  const [uploadPhoto, setUploadPhoto] = useMutation(PRESINGED_URL);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // history
  const _CatagoryList = () => {
    history.push(`${CONSULTING_CATAGORY_LIST}`);
  };
  const _createCategory = async (data) => {
    const resCreateCategory = await createCategory({
      variables: { data },
    })
    if (resCreateCategory?.data?.createConsultingCategory?.id) {
      Swal.fire({
        icon: "success",
        title: "ການບັນທຶກສຳເລັດ",
        showConfirmButton: false,
        timer: 1500,
      }).then(function () {
        history.push(`${CONSULTING_CATAGORY_LIST}`);
        window.location.reload(true);
      })
    } else {
      Swal.fire({
        icon: "failed",
        title: "ການບັນທຶກບໍ່ສຳເລັດ",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  const CounselingSchema = Yup.object().shape({
    name: Yup.string().required("ກະລຸນາປ້ອນ!"),
  });

  // =======================>>>>>>>>>>>
  // upload pic
  const [resPhoto, setResPhoto] = useState();
  const [file, setFile] = useState("");
  const [imageLoading, setImageLoading] = useState();
  const handleUpload = async (event) => {
    setImageLoading("");
    try {
      setFile(event.target.files[0]);
      let formData = new FormData();
      let fileData = event.target.files[0].name;
      let fileDataBoole = event.target.files[0];
      const resUploadPhoto = await uploadPhoto({
        variables: { fileName: fileData },
      });
      let afterUpload = await axios({
        method: "put",
        url: resUploadPhoto.data.preSignedUrl.url,
        data: fileDataBoole,
        headers: {
          "Content-Type": " file/*; image/*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      });
      setResPhoto(afterUpload.config.data.name);
    } catch (error) { }
  };
  // lung jak upload leo pic ja ma so u nee
  const ImageThumb = ({ image }) => {
    return (
      <img
        src={URL.createObjectURL(image)}
        alt={image.name}
        style={{
          borderRadius: "5%",
          height: 300,
          width: 300,
        }}
      />
    );
  };
  return (
    <div style={TITLE_HEADER}>
      <h6 style={{ marginBottom: 25, marginLeft: 25 }}>
        <a
          href="#"
          onClick={() => _CatagoryList()}
          style={{ color: "#766AF0" }}
        >
          ໝວດການປືກສາ | <i class="fa fa-list" aria-hidden="true"></i> » ລາຍຊື່
          ໝວດການປືກສາ »
        </a>
        ເພີ່ມ ໝວດການປືກສາ
      </h6>
      <div
        style={{
          backgroundColor: "#FFF",
          boxShadow: "0px 0px 6px #F7F7F7",
          borderRadius: 8,
          opacity: 1,
          marginLeft: 20,
          paddingLeft: 5,
          marginBottom: 20,
          paddingBottom: 5,
          width: "97%",
        }}
      >
        <div
          className="container-fluid "
          style={{
            padding: 32,
            height: "100%",
            width: "100%",
            marginLeft: 22,
          }}
        >
          <h5>
            ເພີ່ມ ໝວດການປືກສາ
            <br />
          </h5>
          <div className="row">
            <input
              type="file"
              id="file-upload"
              onChange={handleUpload}
              hidden
            />
            <label for="file-upload">
              <div
                style={{
                  backgroundColor: "#E4E4E4E4",
                  height: 300,
                  width: 300,
                  borderRadius: "5%",
                  cursor: "pointer",
                  display: "flex",
                }}
              >
                {file ? (
                  <ImageThumb image={file} />
                ) : (
                    <div
                      style={{
                        display: "flex",
                        height: 300,
                        width: 300,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          color: "#fff",
                          fontSize: 80,
                          fontWeight: "bold",
                        }}
                      >
                        +
                    </p>
                    </div>
                  )}
              </div>
            </label>
            {/* progass */}
            {imageLoading ? (
              <div class="progress" style={{ height: 20 }}>
                <div
                  class="progress-bar"
                  role="progressbar"
                  style={{
                    width: `${imageLoading}%`,
                    backgroundColor: "#816aae",
                  }}
                  aria-valuenow={imageLoading}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {imageLoading}%
                </div>
              </div>
            ) : (
                <div style={{ height: 20 }} />
              )}
          </div>
          <Formik
            initialValues={{
              name: "",
              detail: "",
              note: "",
            }}
            validationSchema={CounselingSchema}
            onSubmit={(values, { setSubmitting }) => {
              let data = {
                name: values.name,
                detail: values.detail,
                image: resPhoto,
                note: values.note,
              };
              _createCategory(data);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="row" style={{ paddingTop: 5, marginTop: 5 }}>
                  <div className="col-6">
                    <label for="exampleFormControlInput1" class="form-label">
                      ຊື່ຄຳປືກສາ <a style={{ color: "red" }}>*</a>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="-"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    <ErrorValidationBox errors={errors} fieldName={"name"} />
                  </div>
                </div>
                <div className="row" style={{ paddingTop: 5, marginTop: 5 }}>
                  <div className="col-6">
                    <label for="exampleFormControlInput1" class="form-label">
                      ລາຍລະອຽດ
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="-"
                      name="detail"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.detail}
                    >
                      {" "}
                    </textarea>
                  </div>
                </div>
                <div className="row" style={{ paddingTop: 5, marginTop: 5 }}>
                  <div className="col-6">
                    <label for="exampleFormControlInput1" class="form-label">
                      ໝາຍເຫດ
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="-"
                      name="note"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.note}
                    />
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    paddingTop: 10,
                    marginTop: 10,
                    paddingRight: 5,
                    marginRight: 5,
                  }}
                >
                  <div
                    className="col"
                    style={{
                      paddingTop: 10,
                      marginTop: 10,
                      float: "right",
                      textAlign: "right",
                    }}
                  >
                    <button
                      type="button"
                      className="btn btn-outline-danger col-2"
                      style={{
                        paddingRight: 5,
                        marginRight: 5,
                      }}
                      onClick={() => _CatagoryList()}
                    >
                      ຍົກເລິກ
                    </button>
                    <button
                      type="submit"
                      className="btn btn-secondary col-2"
                      style={{
                        background:
                          "transparent linear-gradient(269deg, #766AF0 0%, #978EF3 100%) 0% 0% no-repeat padding-box",
                        textAlign: "center",
                        letterSpacing: 0,
                        opacity: 1,
                        paddingRight: 5,
                        marginRight: 5,
                      }}
                    // onClick={handleShow}
                    >
                      ເພີ່ມໝວດປຶກສາ
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
          <div style={{ height: 40 }}></div>
        </div>
        <Modal
          size="sm"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header
            closeButton
            style={{ backgroundColor: "#48BB78", textAlign: "center" }}
          >
            <Modal.Title style={{}}>
              <div style={{ textAlign: "center" }}>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{ color: "#FFFFFF", backgroundColor: "#48BB78" }}
                ></FontAwesomeIcon>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: "center" }}>
            <h3>Success!</h3>
            <h6>xxxxxxxxxxxxxxxxxxxxxxxxxx</h6>
          </Modal.Body>
          <Modal.Footer centered={true}>
            <Button
              variant="success"
              centered={true}
              onClick={handleClose}
              style={{ width: "50%", textAlign: "center" }}
            >
              ຕົກລົງ
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}
