import React, { useState, useRef, useEffect } from "react";
import useReactRouter from "use-react-router";
import Image from "react-bootstrap/Image";
import ImageLogo from "../../../image/profile.png";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEdit,
  faFileAlt,
  faTrashAlt,
  faUser,
  faUserGraduate,
} from "@fortawesome/free-solid-svg-icons";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import * as Yup from "yup";

import { CONSULTING_CATEGORYS, CREATE_USER, PRESINGED_URL } from "../apollo";
import Swal from "sweetalert2";
import axios from "axios";
import {
  TITLE_HEADER,
  HEADER,
  BODY_DISCOUNT_SHOPDETAIL,
} from "../../../consts";
import { STAFF_LIST, STORE, STAFF_ADD } from "../../../consts/router";

import { ErrorValidationBox } from "../../../common/ErrorValidationBox";

export default function StaffAdd() {
  const { history, location, match } = useReactRouter();
  const { loading, error, data } = useQuery(CONSULTING_CATEGORYS);
  const [createUser, setCreateUser] = useMutation(CREATE_USER);
  const [uploadPhoto, setUploadPhoto] = useMutation(PRESINGED_URL);
  // useState
  const [canCounseling, setCanCounseling] = useState("");
  const [arrayCanCounseling, setArrayCanCounseling] = useState([]);

  // history
  const _dealerList = () => {
    history.push(`${STAFF_LIST}/limit/20/skip/0`);
  };
  const _staffList = () => {
    history.push(`${STAFF_LIST}/limit/20/skip/0`);
  };

  const [cateId, setCateId] = useState("");
  // upload pic
  const [resPhoto, setResPhoto] = useState();
  const [file, setFile] = useState("");
  const [imageLoading, setImageLoading] = useState();
  const [getNewRole, setGetNewRole] = useState("");

  const handleUpload = async (event) => {
    setImageLoading("");
    try {
      setFile(event.target.files[0]);
      let formData = new FormData();
      let fileData = event.target.files[0].name;
      let fileDataBoole = event.target.files[0];
      const resUploadPhoto = await uploadPhoto({
        variables: { fileName: fileData },
      });
      let afterUpload = await axios({
        method: "put",
        url: resUploadPhoto.data.preSignedUrl.url,
        data: fileDataBoole,
        headers: {
          "Content-Type": " file/*; image/*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      });
      setResPhoto(afterUpload.config.data.name);
    } catch (error) { }
  };
  // lung jak upload leo pic ja ma so u nee
  const ImageThumb = ({ image }) => {
    return (
      <img
        src={URL.createObjectURL(image)}
        alt={image.name}
        style={{
          borderRadius: "50%",
          height: 300,
          width: 300,
        }}
      />
    );
  };
  // useState
  const [genderData, setGenderData] = useState("NO_GENDER");
  const [saveCategory, setSaveCategory] = useState();
  const _selectCategory = (id) => {
    setSaveCategory(id);
  };
  // get gender
  const handleChangeGender = async (gender) => {
    setGenderData(gender);
  };
  // create user
  const _createUser = async (data) => {
    try {
      const userData = {
        userId: data.userId,
        password: data.password,
        role: data.role,
        firstName: data.firstName,
        lastName: data.lastName,
        phone: String(data.phone),
        email: data.email,
        birthday: data.birthday,
        gender: genderData,
        tribe: data.tribe,
        image: resPhoto,
        address: {
          village: data.village,
          district: data.district,
          province: data.province,
        },
        province: data.province,
        // provinceBorn: data.provinceBorn,
        accessibility: data.accessibility,
        educationLevel: data.educationLevel,
        canCounseling: null,
        branch: data.branch,
        religion: data.religion,
        detail: data.detail,
        note: data.note,
      };

      const resCreateUser = await createUser({
        variables: {
          data: userData,
        },
      });
      if (resCreateUser?.data?.createUser?.id) {
        Swal.fire({
          icon: "success",
          title: "ການບັນທຶກສຳເລັດ",
          showConfirmButton: false,
          timer: 1500,
        }).then(function () {
          history.push(`${STAFF_LIST}/limit/20/skip/0`);
          window.location.reload(true);
        });
      } else {
        Swal.fire({
          icon: "failed",
          title: "ການບັນທຶກບໍ່ສຳເລັດ",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (errors) {
      if (errors?.graphQLErrors[0]?.message === "USERID_IS_NOT_READY") {
        Swal.fire({
          icon: "error",
          text: "ມີໄອດີນີ້ໃນລະບົບແລ້ວ",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };
  let categoryLog = [];
  const _arrayCategory = (data) => {
    setCateId(data.target.value);
  };
  const _addRole = () => {
    let _newRole = [];
    let _total = _newRole.push(cateId);
  };
  const _onChangeArrayCategory = (e) => {
    setCanCounseling(e.target.value);
  };
  // Add to ArrayCanCounseling
  const _onAddArrayCategory = async () => {
    let _arrayCanCounseling = [...arrayCanCounseling];
    let countArray = 0;
    for (var i = 0; i < _arrayCanCounseling.length; i++) {
      if (_arrayCanCounseling[i] === canCounseling) {
        countArray++;
      }
    }
    if (countArray > 0) {
      alert("Warning", "Mee leo hum noy");
    } else {
      _arrayCanCounseling.push(canCounseling);
    }
    setArrayCanCounseling(_arrayCanCounseling);
    setCanCounseling("");
  };
  // Delete from ArrayCanCounseling
  const _onDeleteArrayCanCounseling = async (item) => {
    const filterArray = await arrayCanCounseling.filter((x) => x != item);
    setArrayCanCounseling(filterArray);
  };
  const _renderCategory = (e) => {
    let res = data?.consultingCategorys?.data?.filter((x) => x.id === e);
    if (res?.length > 0) return res[0]?.name;
  };

  const StaffSchema = Yup.object().shape({
    firstName: Yup.string().required("ກະລຸນາປ້ອນ!"),
    userId: Yup.string().required("ກະລຸນາປ້ອນ!"),
    password: Yup.string().required("ກະລຸນາປ້ອນ!"),
    role: Yup.string().required("ກະລຸນາເລືອກ!"),
  });

  return (
    <div style={TITLE_HEADER}>
      {/* edit bosly */}
      <div
        style={{
          marginLeft: 35,
          marginRight: 25,
          paddingBottom: 32,
          paddingTop: 10,
        }}
      >
        <a href="" onClick={() => _dealerList()} style={{ color: "#766AF0" }}>
          ພະນັກງານທັງໝົດ »{" "}
        </a>
        ເພີ່ມ
      </div>
      <Formik
        initialValues={{
          firstName: "",
          userId: "",
          password: "",
          role: "",
        }}
        validationSchema={StaffSchema}
        onSubmit={(values, { setSubmitting }) => {
          _createUser(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div style={BODY_DISCOUNT_SHOPDETAIL}>
              <div style={{ padding: 32 }} className="bod-box-show">
                <div style={{ fontSize: 18, color: "#766AF0" }}>
                  ເພີ່ມພະນັກງານ
                </div>
                <div className="row" style={{ padding: 20 }}>
                  <div className="col-md-4">
                    <div className="row center">
                      <input
                        type="file"
                        id="file-upload"
                        onChange={handleUpload}
                        hidden
                      />
                      <label for="file-upload">
                        <div
                          style={{
                            backgroundColor: "#E4E4E4E4",
                            height: 300,
                            width: 300,
                            borderRadius: "50%",
                            cursor: "pointer",
                            display: "flex",
                          }}
                        >
                          {file ? (
                            <ImageThumb image={file} />
                          ) : (
                              <div
                                style={{
                                  display: "flex",
                                  height: 300,
                                  width: 300,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "50%",
                                }}
                              >
                                <p
                                  style={{
                                    color: "#fff",
                                    fontSize: 80,
                                    fontWeight: "bold",
                                  }}
                                >
                                  +
                              </p>
                              </div>
                            )}
                        </div>
                      </label>
                      {/* progass */}
                      {imageLoading ? (
                        <div class="progress" style={{ height: 20 }}>
                          <div
                            class="progress-bar"
                            role="progressbar"
                            style={{
                              width: `${imageLoading}%`,
                              backgroundColor: "#816aae",
                            }}
                            aria-valuenow={imageLoading}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            {imageLoading}%
                          </div>
                        </div>
                      ) : (
                          <div style={{ height: 20 }} />
                        )}
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div
                      style={{
                        fontWeight: "bold",
                        color: "#766AF0",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faUser}
                        style={{ color: "#766AF0" }}
                      />{" "}
                      ຂໍ້ມູນສ່ວນໂຕ
                    </div>
                    <div style={{ height: 10 }}></div>
                    <div className="row">
                      <div className="col-6">
                        <div
                          style={{
                            paddingTop: 10,
                            textAlign: "left",
                            letterSpacing: 0,
                            opacity: 1,
                          }}
                        >
                          ຊື່ <a style={{ color: "#F91B1B" }}>*</a>
                        </div>
                        <div style={{ paddingTop: 10 }}></div>
                        <input
                          className="form-control btn-block"
                          type="text"
                          name="firstName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.firstName}
                        />
                        <ErrorValidationBox
                          errors={errors}
                          fieldName={"firstName"}
                        />
                      </div>
                      <div className="col-6">
                        <div style={{ paddingTop: 10 }}>ນາມສະກຸນ</div>
                        <div style={{ paddingTop: 10 }}></div>
                        <input
                          className="form-control btn-block"
                          type="text"
                          name="lastName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.lastName}
                        />
                      </div>
                    </div>
                    <div style={{ paddingTop: 20 }}></div>
                    <div className="row">
                      <div className="col-6">
                        <div
                          style={{
                            paddingTop: 10,
                            textAlign: "left",
                            letterSpacing: 0,
                            opacity: 1,
                          }}
                        >
                          ໄອດີ <a style={{ color: "#F91B1B" }}>*</a>
                        </div>
                        <div style={{ paddingTop: 10 }}></div>
                        <input
                          className="form-control btn-block"
                          type="text"
                          name="userId"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.userId}
                        />
                        <ErrorValidationBox
                          errors={errors}
                          fieldName={"userId"}
                        />
                      </div>
                      <div className="col-6">
                        <div style={{ paddingTop: 10 }}>
                          ລະຫັດຜ່ານ <a style={{ color: "#F91B1B" }}>*</a>
                        </div>
                        <div style={{ paddingTop: 10 }}></div>
                        <input
                          className="form-control btn-block"
                          type="password"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                        />
                        <ErrorValidationBox
                          errors={errors}
                          fieldName={"password"}
                        />
                      </div>
                      <div className="col-6">
                        <div style={{ paddingTop: 10 }}>ເບີໂທ</div>
                        <div style={{ paddingTop: 10 }}></div>
                        <input
                          className="form-control btn-block"
                          type="number"
                          name="phone"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone}
                        />
                      </div>
                    </div>
                    <div style={{ paddingTop: 10, textAlign: "left" }}>
                      ເລືອກ Role <a style={{ color: "#F91B1B" }}>*</a>
                    </div>
                    <div style={{ paddingTop: 10 }}></div>
                    <div className="col-12 row">
                      <select
                        class="form-control col-6"
                        type="text"
                        name="role"
                        onChange={handleChange}
                        // onChange={(e) => _onChangeRole(e)}
                        value={values.role}
                      >
                        <option value="" disabled={true} selected={true}>
                          -
                        </option>
                        <option value="ADMIN">Admin</option>
                        <option value="FIRST_RESPONDER">FristResponder</option>
                      </select>
                    </div>
                    <ErrorValidationBox errors={errors} fieldName={"role"} />
                    <div style={{ height: 10 }}></div>
                    <div style={{ height: 10 }}></div>
                    <div style={{ paddingTop: 10, textAlign: "left" }}>
                      ວັນເດືອນປີເກີດ
                    </div>
                    <div style={{ height: 10 }}></div>
                    <div class="form-inline">
                      <div class="form-group mb-12">
                        <input
                          type="date"
                          class="form-control col-12"
                          style={{ marginRight: 20 }}
                          type="date"
                          name="birthday"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.birthday}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="col-md-8"
                        style={{ marginTop: 10, paddingTop: 10 }}
                      >
                        ເພດ
                        <br />{" "}
                      </div>
                      <div
                        className="col-md-8"
                        style={{
                          justifyContent: "left",
                          fontFamily: "NotoSansLao",
                          marginBottom: 10,
                          paddingBottom: 10,
                        }}
                      >
                        <div class="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            id="customRadioInline1"
                            name="gender"
                            class="custom-control-input"
                            onChange={() => handleChangeGender("MALE")}
                          />
                          <label
                            class="custom-control-label"
                            for="customRadioInline1"
                          >
                            ຊາຍ
                          </label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            id="customRadioInline2"
                            name="gender"
                            class="custom-control-input"
                            onChange={() => handleChangeGender("FEMALE")}
                          />
                          <label
                            class="custom-control-label"
                            for="customRadioInline2"
                          >
                            ຍິງ
                          </label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            id="customRadioInline3"
                            name="gender"
                            class="custom-control-input"
                            onChange={() => handleChangeGender("OTHER")}
                          />
                          <label
                            class="custom-control-label"
                            for="customRadioInline3"
                          >
                            ເພດອື່ນ
                          </label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                          <input
                            type="radio"
                            id="customRadioInline4"
                            name="gender"
                            class="custom-control-input"
                            onChange={() => handleChangeGender("NO_GENDER")}
                            defaultChecked
                          />
                          <label
                            class="custom-control-label"
                            for="customRadioInline4"
                          >
                            ບໍ່ລະບຸ
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div
                          style={{
                            paddingTop: 10,
                            textAlign: "left",
                            letterSpacing: 0,
                            opacity: 1,
                          }}
                        >
                          ສະຖານະການເຮັດວຽກ
                        </div>
                        <div style={{ paddingTop: 10 }}></div>
                        <select
                          id="inputState"
                          className="form-control "
                          style={{ width: "100%" }}
                          type="text"
                          name="workingStatus"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.workingStatus}
                        >
                          <option value="" disabled={true} selected={true}>-</option>
                          <option value="WORK">ເຮັດວຽກ</option>
                          <option value="STUDYING">ຮຽນ</option>
                          <option value="UNEMPLOYED">ຫ່ວາງງານ</option>
                          <option value="OTHER">ອື່ນໆ</option>
                        </select>
                      </div>
                      <div className="col-6">
                        <div
                          style={{
                            paddingTop: 10,
                            textAlign: "left",
                            letterSpacing: 0,
                            opacity: 1,
                          }}
                        >
                          ຄວາມບົກຜ່ອງທາງຮ່າງກາຍ(ພິການ){" "}
                        </div>
                        <div style={{ paddingTop: 10 }}></div>
                        <input
                          className="form-control btn-block"
                          placeholder="-"
                          type="text"
                          name="accessibility"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.accessibility}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div
                          style={{
                            paddingTop: 10,
                            textAlign: "left",
                            letterSpacing: 0,
                            opacity: 1,
                          }}
                        >
                          ຊົນເຜົ່າ
                        </div>
                        <div style={{ paddingTop: 10 }}></div>
                        <input
                          className="form-control btn-block"
                          placeholder="-"
                          type="text"
                          name="tribe"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.tribe}
                        />
                      </div>
                      <div className="col-6">
                        <div
                          style={{
                            paddingTop: 10,
                            textAlign: "left",
                            letterSpacing: 0,
                            opacity: 1,
                          }}
                        >
                          ສາດສະໜາ
                        </div>
                        <div style={{ paddingTop: 10 }}></div>
                        <input
                          className="form-control btn-block"
                          placeholder="-"
                          type="text"
                          name="religion"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.religion}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div
                          style={{
                            paddingTop: 10,
                            textAlign: "left",
                            letterSpacing: 0,
                            opacity: 1,
                          }}
                        >
                          ບ້ານປະຈຸບັນ
                        </div>
                        <input
                          className="form-control btn-block"
                          name="VillageBorn"
                          placeholder="-"
                          type="text"
                          name="village"
                          onChange={handleChange}
                          value={values.village}
                        />
                        <ErrorValidationBox
                          errors={errors}
                          fieldName={"village"}
                        />
                      </div>
                      <div className="col-6">
                        <div
                          style={{
                            paddingTop: 10,
                            textAlign: "left",
                            letterSpacing: 0,
                            opacity: 1,
                          }}
                        >
                          ເມືອງປະຈຸບັນ
                        </div>
                        <input
                          className="form-control btn-block"
                          name="DisrictBorn"
                          placeholder="-"
                          type="text"
                          name="district"
                          onChange={handleChange}
                          value={values.district}
                        />
                      </div>
                      <div className="col-6">
                        <div
                          style={{
                            paddingTop: 10,
                            textAlign: "left",
                            letterSpacing: 0,
                            opacity: 1,
                          }}
                        >
                          ແຂວງປະຈຸບັນ
                        </div>
                        <select
                          id="inputState"
                          className="form-control "
                          style={{ width: "100%" }}
                          type="text"
                          name="province"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.province}
                        >
                          <option value="-" disabled={true} selected={true}>ເລືອກແຂວງ</option>
                          <option value="Phongsali">ຜົ້ງສາລີ</option>
                          <option value="LuangNamtha">ຫຼວງນ້ຳທາ</option>
                          <option value="Huoaphan">ຫົວພັນ</option>
                          <option value="Borkeo">ບໍ່ແກ້ວ</option>
                          <option value="Oudomxay">ອຸດົມໄຊ</option>
                          <option value="Sayaboury">ໄຊຍະບູລີ</option>
                          <option value="Xiengkhouang">ຊຽງຂວາງ</option>
                          <option value="LuangPrabang">ຫຼວງພະບາງ</option>
                          <option value="Vientiane">ວຽງຈັນ</option>
                          <option value="VientianeCapital">
                            ນະຄອນຫຼວງວຽງຈັນ
                          </option>
                          <option value="Borikhamsay">ບໍລິຄຳໄຊ</option>
                          <option value="Khammuoan">ຄຳມ່ວນ</option>
                          <option value="Savannakhet">ສະຫວັນນະເຂດ</option>
                          <option value="Salavanh">ສາລະວັນ</option>
                          <option value="Sekong">ເຊກອງ</option>
                          <option value="Attapue">ອັດຕະປື</option>
                          <option value="Champasak">ຈຳປາສັກ</option>
                          <option value="Saisomboun">ໄຊສົມບູນ</option>
                        </select>
                      </div>
                      <div className="col-6">
                        <div
                          style={{
                            paddingTop: 10,
                            textAlign: "left",
                            letterSpacing: 0,
                            opacity: 1,
                          }}
                        ></div>
                      </div>
                    </div>
                    <div style={{ height: 40 }}></div>
                    <div style={{ color: "#766AF0", fontWeight: "bold" }}>
                      <FontAwesomeIcon
                        icon={faUserGraduate}
                        style={{ marginRight: 10 }}
                      />{" "}
                      ຂໍ້ມູນການສຶກສາ
                    </div>
                    <div style={{ height: 10 }}></div>
                    <div className="row">
                      <div className="col-6">
                        <div
                          style={{
                            paddingTop: 10,
                            textAlign: "left",
                            letterSpacing: 0,
                            opacity: 1,
                          }}
                        >
                          ລະດັບການສືກສາ
                        </div>
                        <div style={{ paddingTop: 10 }}></div>
                        <select
                          id="inputState"
                          className="form-control "
                          style={{ width: "100%" }}
                          type="text"
                          name="educationLevel"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.educationLevel}
                        >
                          <option value="" disabled={true} selected={true}>-</option>
                          <option value="PRIMARY">ປະຖົມ</option>
                          <option value="LOWER_SECONDARY">ມັດທະຍົມຕົ້ນ</option>
                          <option value="UPPER_SECONDARY">ມັດທະຍົມປາຍ</option>
                          <option value="FIRST_FLOOR">ຊັ້ນຕົ້ນ</option>
                          <option value="MIDDLE_CLASS">ຊັ້ນກາງ</option>
                          <option value="HIGH_CLASS">ຊັ້ນສູງ</option>
                          <option value="BACHELOR">ປະລິນຍາຕີ</option>
                          <option value="MASTER">ປະລິນຍາໂທ</option>
                          <option value="NOT_STUDYING">ບໍ່ໄດ້ຮຽນ</option>
                        </select>
                      </div>
                      <div className="col-6">
                        <div
                          style={{
                            paddingTop: 10,
                            textAlign: "left",
                            letterSpacing: 0,
                            opacity: 1,
                          }}
                        >
                          ສາຂາຮຽນ{" "}
                        </div>
                        <div style={{ paddingTop: 10 }}></div>
                        <input
                          className="form-control btn-block"
                          placeholder="-"
                          type="text"
                          name="branch"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.branch}
                        />
                      </div>
                    </div>
                    <div style={{ height: 40 }}></div>
                    <div style={{ color: "#766AF0", fontWeight: "bold" }}>
                      <FontAwesomeIcon
                        icon={faFileAlt}
                        style={{ marginRight: 10 }}
                      />{" "}
                      ຂໍ້ມູນເພີ່ມເຕີມ
                    </div>
                    <div style={{ height: 10 }}></div>
                    <div class="form-row">
                      <div class="col-12">
                        <label>ຄຳອະທິບາຍ</label>
                        <div style={{ height: 10 }}></div>
                        <textarea
                          type="textarea"
                          class="form-control"
                          type="text"
                          name="detail"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.detail}
                        />
                      </div>
                      <div class="col-12">
                        <label>ໝາຍເຫດ</label>
                        <div style={{ height: 10 }}></div>
                        <textarea
                          type="textarea"
                          class="form-control"
                          type="text"
                          name="note"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.note}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div
                    className="col-md-12"
                    style={{
                      textAlign: "right",
                      paddingRight: 30,
                      marginRight: 30,
                    }}
                  >
                    <button
                      type="button"
                      class="btn btn-outline-danger col-md-2"
                      style={{
                        textAlign: "center",
                        letterSpacing: 0,
                        opacity: 1,
                        paddingRight: 10,
                        marginRight: 10,
                      }}
                      onClick={() => _staffList()}
                    >
                      {" "}
                      ຍົກເລິກ
                    </button>
                    <button
                      type="submit"
                      class="btn btn-secondary col-md-2"
                      style={{
                        background:
                          "transparent linear-gradient(269deg, #766AF0 0%, #978EF3 100%) 0% 0% no-repeat padding-box",
                        textAlign: "center",
                        letterSpacing: 0,
                        opacity: 1,
                        paddingRight: 5,
                        marginRight: 5,
                      }}
                    >
                      ຢືນຢັນ
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}
