import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import Swal from "sweetalert2";
import * as Yup from "yup";

import { COUNSELING_DETAIL, COUNSELING_LIST } from "../../../consts/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faBookmark,
  faList,
  faUser,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
  CONSULTINGS,
  CONSULTING_CATEGORYS,
  USERS,
  CREATE_CONSULTING,
  USER,
  GENDER_FLOWCHART,
} from "../apollo";
import { formatDate, formatDateTime } from "../../../helper/super";
import { TITLE_HEADER } from "../../../consts";
import { ErrorValidationBox } from "../../../common/ErrorValidationBox";

const STATUS = [
  { code: "ONGOING_FR", show: "ONGOING_FR" },
  { code: "ONGOING_MT", show: "ONGOING_MT" },
  { code: "COMPLETED", show: "COMPLETED" },
  { code: "CANCELED", show: "CANCELED" },
];

export default function CounselingList() {
  const { history, location, match } = useReactRouter();
  const _limit = match.params.limit;
  const _skip = match.params.skip;
  const [selectedUserName, setSelectedUserName] = useState();
  const [cateList, setCateList] = useState([]);
  const [selectedCateId, setSelectedCateId] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedStartDate, setSelectedStartDate] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [getAllConsultingsId, setGetAllConsultingsId] = useState([]);

  const { loading, error, data } = useQuery(CONSULTINGS, {
    variables: {
      where: {
        userName: selectedUserName,
        status: selectedStatus,
        consultingCategoryId: selectedCateId,
        startDate: selectedStartDate,
        endDate: selectedEndDate,
      },
      limit: parseInt(_limit),
      skip: parseInt(_skip) * parseInt(_limit),
    },
  });
  const { loading: allConsultingsLoading, error: allConsultingsError, data: allConsultingsData } = useQuery(CONSULTINGS, {
    variables: {
      where: {
        userName: selectedUserName,
        status: selectedStatus,
        consultingCategoryId: selectedCateId,
        startDate: selectedStartDate,
        endDate: selectedEndDate,
      },
    },
  });

  const { loading: cateLoading, error: cateError, data: cateData } = useQuery(
    CONSULTING_CATEGORYS
  );
  const {
    loading: dataUserLoading,
    error: dataUserError,
    data: dataUser,
  } = useQuery(USERS);
  const { loading: UserLoading, error: UserError, data: User } = useQuery(
    USERS,
    {
      variables: {
        where: { role_in: "USER" },
      },
    }
  );
  const { loading: FRuserLoading, error: FRuserError, data: FRuser } = useQuery(
    USERS,
    {
      variables: { where: { role_in: "FIRST_RESPONDER" } },
    }
  );
  const {
    loading: MENTORuserLoading,
    error: MENTORuserError,
    data: MENTORuser,
  } = useQuery(USERS, {
    variables: { where: { role_in: "MENTOR" } },
  });
  const {
    loading: checkoutRoleLoading,
    error: checkoutRoleError,
    data: checkoutRoleData,
  } = useQuery(USER, {
    variables: { where: { id: location.state } },
  });
  const [createConsulting, setCreateConsulting] = useMutation(
    CREATE_CONSULTING
  );
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (cateData) {
      let cateList = cateData?.consultingCategorys?.data;
      setCateList(cateList);
    }
    if (location.state) {
      setShow(true);
    }
  }, [cateData]);
  useEffect(() => {
    if (data?.consultings?.total > 0) {
      _getArrayPageNumber();
    }
  }, [data]);

  /***
   * to get all consultings data
   */
  useEffect(() => {
    if (allConsultingsData?.consultings?.data?.length > 0) {
      let allConsultingsDataId = allConsultingsData?.consultings?.data.map(data => data.id);
      // console.log({data: allConsultingsData?.consultings?.data[200]})
      setGetAllConsultingsId([...allConsultingsDataId]);
    }
  }, [allConsultingsData]);

  const _CounselingDetail = (id) => {
    // history.push(`${COUNSELING_DETAIL}/${id}`, {id});
    history.push({
      pathname: `${COUNSELING_DETAIL}/${id}`,
      state: { data: getAllConsultingsId }
    });
  };

  const _onUserNameChange = (e) => {
    let userName = e.target.value;
    if (!userName) userName = undefined;
    setSelectedUserName(userName);
  };

  const _onCateChange = (e) => {
    let choosedCateId = e.target.value;
    if (!choosedCateId) choosedCateId = undefined;
    setSelectedCateId(choosedCateId);
  };

  const _onStatusChange = (e) => {
    let status = e.target.value;
    if (!status) status = undefined;
    setSelectedStatus(status);
  };

  const _onStartDateChange = (e) => {
    let startDate = e.target.value;
    if (!startDate) startDate = undefined;
    setSelectedStartDate(startDate);
  };

  const _onEndDateChange = (e) => {
    let endDate = e.target.value;
    if (!endDate) endDate = undefined;
    setSelectedEndDate(endDate);
  };

  const _renderCategory = (e) => {
    let res = cateData?.consultingCategorys?.data?.filter((x) => x.id === e);
    if (res?.length > 0) return res[0]?.name;
  };
  const _renderName = (e) => {
    let res = dataUser?.users?.data?.filter((x) => x.id === e);
    if (res?.length > 0) return res[0]?.firstName + " " + res[0]?.lastName;
  };
  const _renderNameAUTO = (e) => {
    let res = dataUser?.users?.data?.filter((x) => x.id === e);
    if (res?.length > 0) return res[0]?.firstName + " " + res[0]?.lastName;
  };
  const _status = (e) => {
    if (e === "ONGOING_FR") {
      return <div style={{ color: "#F29702" }}>ONGOING_FR</div>;
    } else if (e === "ONGOING_MT") {
      return <div style={{ color: "#F29702" }}>ONGOING_MT</div>;
    } else if (e === "COMPLETED") {
      return <div style={{ color: "#48BB78" }}>COMPLETED</div>;
    } else if (e === "CANCELED") {
      return <div style={{ color: "red" }}>CANCELED</div>;
    }
  };
  const _createConsulting = async (values) => {
    let data = {
      userId: values.userId,
      userName: _renderNameAUTO(values.userId),
      firstResponderId: values.FirstResponderId,
      mentorId: values.mentorId,
      consultingCategoryId: values.consultingCategoryId,
      appointmentDate: new Date(values.appointmentDate).toISOString(),
      appointmentAddress: values.appointmentAddress,
      detail: values.detail,
      followUp: values.followUp,
    };
    const resCreateUser = await createConsulting({
      variables: {
        data,
      },
    })
    if (resCreateUser?.data?.createConsulting?.id) {
      Swal.fire({
        icon: "success",
        title: "ການບັນທຶກສຳເລັດ",
        showConfirmButton: false,
        timer: 1500,
      }).then(function () {
        history.push(`${COUNSELING_LIST}/limit/20/skip/0`);
        window.location.reload(true);
      });
    } else {
      Swal.fire({
        icon: "failed",
        title: "ການບັນທຶກບໍ່ສຳເລັດ",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  let time = [];
  for (let i = 0; i < 12; i++) {
    time.push(i);
  }

  let minute = [];
  for (let i = 0; i < 60; i++) {
    minute.push(i);
  }

  const CounselingSchema = Yup.object().shape({
    detail: Yup.string().required("ກະລຸນາປ້ອນ!"),
    userId: Yup.string().required("ກະລຸນາປ້ອນ!"),
    consultingCategoryId: Yup.string().required("ກະລຸນາປ້ອນ!"),
    appointmentDate: Yup.string().required("ກະລຸນາປ້ອນ!"),
  });
  const [totalPage, setTotalPage] = useState([]);
  const _getArrayPageNumber = () => {
    let rowPage = [];
    let _total = 0;
    if (data?.consultings?.total % parseInt(_limit) != 0) {
      _total = parseInt(data?.consultings?.total) / parseInt(_limit) + 1;
    } else {
      _total = parseInt(data?.consultings?.total) / parseInt(_limit);
    }
    for (let i = 1; i <= _total; i++) {
      rowPage.push(i);
    }
    setTotalPage(rowPage);
  };
  const _changePage = (index) => {
    history.push("/counseling/limit/" + parseInt(_limit) + "/skip/" + index);
  };
  return (
    <div style={TITLE_HEADER}>
      {/* EDIT Bosly */}
      <div
        style={{
          marginTop: 25,
          marginLeft: 25,
          marginRight: 25,
          padding: 32,
          background: "#FFFFFF",
          borderRadius: 8,
          opacity: 1,
          width: "auto",
        }}
      >
        <div className="row" style={{ padding: 10 }}>
          <div
            className="col-md-12"
            style={{ fontSize: "20px", paddingBottom: 20 }}
          >
            ຄົ້ນຫາ
          </div>
        </div>
        <div className="row" style={{ padding: 20 }}>
          <div
            className="col-4"
            style={{
              paddingBottom: 20,
              float: "left",
              marginRight: "5px",
              marginLeft: "0px",
            }}
          >
            <input
              type="text"
              style={{ float: "center" }}
              className="form-control empty"
              placeholder="&#xF002;  ປ້ອນ User"
              onChange={(e) => _onUserNameChange(e)}
            />
          </div>
          <div className="col">
            <select
              onChange={(e) => _onCateChange(e)}
              className="custom-select col-12"
              id="inputGroupSelect01"
              style={{ float: "center", fontWeight: "bold", margin: "5" }}
            >
              <option value="">ໝວດປຶກສາ</option>
              {cateList.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col">
            <select
              onChange={(e) => _onStatusChange(e)}
              className="custom-select col-8"
              id="inputGroupSelect01"
              style={{ float: "center", fontWeight: "bold", margin: "5" }}
            >
              <option value="">ສະຖານະ</option>
              {STATUS.map((st, index) => (
                <option key={index} value={st.code}>
                  {st.show}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row" style={{ padding: 20 }}>
          <div className="col-3" style={{ paddingBottom: 20 }}>
            <div>ວັນທີນັດ</div>
            <input
              onChange={(e) => _onStartDateChange(e)}
              type="date"
              className="form-control empty"
            />
          </div>
          <div>
            <div style={{ float: "center", margin: 5, padding: 5 }}></div>
            <div style={{ float: "center", margin: 5, padding: 5 }}>~</div>
          </div>
          <div className="col-3" style={{ float: "left", paddingBottom: 20 }}>
            <div>ຫາວັນທີ</div>
            <input
              onChange={(e) => _onEndDateChange(e)}
              type="date"
              className="form-control empty"
            />
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: 25,
          marginLeft: 25,
          marginRight: 25,
          padding: 32,
          background: "#FFFFFF",
          borderRadius: 8,
          opacity: 1,
          width: "auto",
        }}
      >
        <div className="row" style={{ padding: 10 }}>
          <div className="col" style={{ fontSize: "20px", paddingBottom: 20 }}>
            ການປືກສາທັງໝົດ ({data?.consultings?.total})
          </div>
          <div className="col" style={{ fontSize: "20px", paddingBottom: 20 }}>
            <button
              type="button"
              className="btn btn-secondary btn-app col-3"
              style={{ float: "right", margin: "5", border: 0 }}
              onClick={handleShow}
            >
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: 20 }} />
              ເພີ່ມການປຶກສາ
            </button>
          </div>
        </div>
        <div className="col-md-12">
          <table className="table table-hover ">
            <thead>
              <tr className="center">
                <th scope="col">#</th>
                <th scope="col">User</th>
                <th scope="col">FirstResponder</th>
                <th scope="col">Mentor</th>
                <th scope="col">ໝວດ</th>
                <th scope="col">ສະຖານະ</th>
                <th scope="col">ມື້ນັດໝາຍ</th>
                <th scope="col">ວັນທີ່ສ້າງ</th>
              </tr>
            </thead>
            <tbody className="center">
              {data?.consultings?.data?.map((item, index) => {
                return (
                  <tr key={index} onClick={() => _CounselingDetail(item?.id)}>
                    <th scope="row">
                      {index + 1 + parseInt(_limit) * parseInt(_skip)}
                    </th>
                    <td>{item?.userName}</td>
                    <td>{_renderName(item?.firstResponderId)}</td>
                    <td>{_renderName(item?.mentorId)}</td>
                    <td style={{ width: "auto" }}>
                      <label
                        key={index}
                        style={{
                          background: "#EC6869 0% 0% no-repeat padding-box",
                          borderRadius: 5,
                          border: "solid 1px",
                          opacity: 1,
                          color: "#FFFFFF",
                          marginRight: 10,
                          padding: 2,
                        }}
                      >
                        {_renderCategory(item?.consultingCategoryId)}
                      </label>
                    </td>
                    <td>{_status(item?.status)}</td>
                    <td>{formatDateTime(item?.appointmentDate)}</td>
                    <td>{formatDate(item?.createdAt)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="row center">
            {totalPage?.map((numbersPages, index) => {
              return (
                <button
                  key={index}
                  onClick={() => _changePage(index)}
                  style={{
                    width: 30,
                    height: 30,
                    border: "1px solid #9999ff",
                    borderRadius: "10%",
                    justifyContent: "center",
                    textAlign: "center",
                    marginRight: 2,
                    backgroundColor:
                      parseInt(_skip) == index ? "#9999ff" : "#fff",
                    color: parseInt(_skip) == index ? "#fff" : "#000",
                  }}
                >
                  {numbersPages}
                </button>
              );
            })}
          </div>
          <div style={{ paddingTop: 20, paddingBottom: 30 }}></div>
        </div>
      </div>
      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <h5 className="modal-title" id="exampleModalLabel" textAlign="left">
              ເພີ່ມການປຶກສາ
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={
              checkoutRoleData?.user?.role === "FIRST_RESPONDER"
                ? { FirstResponderId: location.state }
                : checkoutRoleData?.user?.role === "USER"
                  ? { userId: location.state }
                  : checkoutRoleData?.user?.role === "MENTOR"
                    ? { mentorId: location.state }
                    : {}
            }
            validationSchema={CounselingSchema}
            onSubmit={(values, { validate }) => {
              _createConsulting(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              validateForm,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="modal-body modal-90w">
                  <div>
                    <FontAwesomeIcon icon={faUser} className="text-a" />
                    <label style={{ marginLeft: 5, paddingLeft: 5 }}>
                      ຂໍ້ມູນການປຶກສາ{" "}
                    </label>
                  </div>
                  <div className="row" style={{ marginTop: 5 }}>
                    <div
                      className="col-4"
                      style={{ float: "left", paddingBottom: 20 }}
                    >
                      <div>
                        ໝວດ
                        <a style={{ color: "#F91B1B", paddingBottom: 5 }}> *</a>
                      </div>
                      <select
                        className="custom-select col-12"
                        style={{
                          float: "center",
                          fontWeight: "bold",
                          margin: "5",
                        }}
                        id="consultingCategoryId"
                        type="text"
                        name="consultingCategoryId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.consultingCategoryId}
                      >
                        <option disabled={true} selected={true}>
                          {" "}
                          -{" "}
                        </option>
                        {cateData?.consultingCategorys?.data?.map(
                          (item, index) => {
                            return (
                              <option key={index} value={item?.id}>
                                {item?.name}
                              </option>
                            );
                          }
                        )}
                      </select>
                      <ErrorValidationBox
                        errors={errors}
                        fieldName={"consultingCategoryId"}
                      />
                    </div>
                    <div
                      className="col-4"
                      style={{ float: "left", paddingBottom: 20 }}
                    >
                      <div>
                        ຜູ້ໃຊ້ບໍລິການ
                        <a style={{ color: "#F91B1B", paddingBottom: 5 }}> *</a>
                      </div>
                      <select
                        className="custom-select col-12"
                        style={{
                          float: "center",
                          fontWeight: "bold",
                          margin: "5",
                        }}
                        id="userId"
                        type="text"
                        name="userId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.userId}
                      >
                        <option disabled={true} selected={true}>
                          {" "}
                          -{" "}
                        </option>
                        {User?.users?.data?.map((item, index) => {
                          return (
                            <option key={index} value={item?.id}>
                              {item?.firstName}
                            </option>
                          );
                        })}
                      </select>
                      <ErrorValidationBox
                        errors={errors}
                        fieldName={"userId"}
                      />
                    </div>
                    <div
                      className="col-4"
                      style={{ float: "left", paddingBottom: 20 }}
                    >
                      <div>First Responder</div>
                      <select
                        className="custom-select col-12"
                        style={{
                          float: "center",
                          fontWeight: "bold",
                          margin: "5",
                        }}
                        id="FirstResponderId"
                        type="text"
                        name="FirstResponderId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.FirstResponderId}
                      >
                        <option disabled={true} selected={true}>
                          {" "}
                          -{" "}
                        </option>
                        {FRuser?.users?.data?.map((item, index) => {
                          return (
                            <option key={index} value={item?.id} disabled={item?.workingStatus === "STOPPED" ? true : false}>
                              {item?.firstName} {item?.workingStatus === "STOPPED" && ("(ຢຸດຊົ່ວຄາວ)")}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-12"
                      style={{ float: "left", paddingBottom: 20 }}
                    >
                      <div style={{ paddingBottom: 5 }}>
                        ລາຍລະອຽດການປຶກສາ
                        <a style={{ color: "#F91B1B", paddingBottom: 5 }}>*</a>
                      </div>
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          rows="3"
                          id="detail"
                          type="text"
                          name="detail"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.detail}
                        ></textarea>
                        <ErrorValidationBox
                          errors={errors}
                          fieldName={"detail"}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faBookmark} className="text-a" />
                    <label style={{ marginLeft: 5, paddingLeft: 5 }}>
                      ລາຍລະອຽດການຕິດຕາມ{" "}
                    </label>
                  </div>
                  <div className="row" style={{ marginTop: 5 }}>
                    <div
                      className="col-3"
                      style={{ float: "left", paddingBottom: 20 }}
                    >
                      <div>
                        ວັນທີນັດ
                        <a style={{ color: "#F91B1B", paddingBottom: 5 }}> *</a>
                      </div>
                      <input
                        type="datetime-local"
                        className="col-6"
                        type="datetime-local"
                        className="form-control empty"
                        id="appointmentDate"
                        name="appointmentDate"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.appointmentDate}
                      ></input>
                      <ErrorValidationBox
                        errors={errors}
                        fieldName={"appointmentDate"}
                      />
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: 5 }}>
                    <div
                      className="col-6"
                      style={{ float: "left", paddingBottom: 20 }}
                    >
                      <div>ສະຖານທີ່</div>
                      <input
                        type="text"
                        className="form-control empty"
                        placeholder="ກະລຸນາປ້ອນ..."
                        id="appointmentAddress"
                        name="appointmentAddress"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.appointmentAddress}
                      />
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: 5 }}>
                    <div
                      className="col-6"
                      style={{ float: "left", paddingBottom: 20 }}
                    >
                      <div>
                        Mentor{" "}
                        <a style={{ color: "#F91B1B", paddingBottom: 5 }}>
                          {" "}
                          (ຖ້າຈຳເປັນ)
                        </a>{" "}
                      </div>
                      <select
                        className="custom-select col-12"
                        style={{
                          float: "center",
                          fontWeight: "bold",
                          margin: "5",
                        }}
                        id="mentorId"
                        name="mentorId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.mentorId}
                      >
                        <option disabled={true} selected={true}> - </option>
                        {MENTORuser?.users?.data?.map((item, index) => {
                          return (
                            <option key={index} value={item?.id}>
                              {item?.firstName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-12"
                      style={{ float: "left", paddingBottom: 20 }}
                    >
                      <div style={{ paddingBottom: 5 }}>
                        ລາຍລະອຽດການຕິດຕາມ
                        {/* <a style={{ color: "#F91B1B", paddingBottom: 5 }}>*</a> */}
                      </div>
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          id="followUp"
                          name="followUp"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.followUp}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <Form>
                  <Row>
                    <Col>
                      <Button
                        variant="btn btn-outline-danger col-2"
                        onClick={handleClose}
                        style={{ marginLeft: 710, marginRight: 10 }}
                      >
                        {" "}
                        ຍົກເລິກ
                      </Button>
                      <Button
                        type="submit"
                        variant="btn btn-secondary btn-app col-2"
                      >
                        ບັນທຶກ
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
}
