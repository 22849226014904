import React, { useState, useRef, useEffect } from "react";
import useReactRouter from "use-react-router";
import Image from "react-bootstrap/Image";
import ImageLogo from "../../../image/profile.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faEdit,
  faEnvelope,
  faFileAlt,
  faTrash,
  faPhone,
  faInfoCircle,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { faWeebly, faWordpress } from "@fortawesome/free-brands-svg-icons";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import Modal from "react-bootstrap/Modal";
import { Button, Container } from "react-bootstrap";
import Swal from "sweetalert2";

import {
  TITLE_HEADER,
  HEADER,
  BODY_DSTAFF_DETAIL,
  URL_FOR_SHOW_PHOTO,
} from "../../../consts";
import {
  COMPANY_ADD,
  COMPANY_LIST,
  COMPANY_EDIT,
  COMPANY_DETAIL,
} from "../../../consts/router";
import { COMPANY, DELETE_COMPANY } from "../apollo";

export default function CompanyDetail() {
  const { history, location, match } = useReactRouter();
  const { loading, error, data } = useQuery(COMPANY, {
    variables: { where: { id: match?.params?.id } },
  });
  const [deleteCompany, setDeleteCompany] = useMutation(DELETE_COMPANY);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // history
  const _CompanyList = () => {
    history.push(`${COMPANY_LIST}`);
  };
  const _CompanyEdit = () => {
    // history.push(`${COMPANY_EDIT}/${match?.params?.id}`, match?.params?.id);
    history.push({
      pathname: `${COMPANY_EDIT}/${match?.params?.id}`,
      state: { data: location?.state?.data, id: match?.params?.id },
    });
  };
  const _CmpanyAdd = () => {
    history.push(`${COMPANY_ADD}`);
  };
  const _confrimeDelete = async () => {
    await deleteCompany({
      variables: { where: { id: match?.params?.id } },
    }).then(
      Swal.fire({
        icon: "success",
        title: "ການລຶບສຳເລັດ",
        showConfirmButton: false,
        timer: 1500,
      }).then(function () {
        history.push(`${COMPANY_LIST}`);
        window.location.reload(true);
      })
    );
  };

  const _onGoBackIds = () => {
    let currentId = match?.params?.id;
    let allIds = location?.state?.data;
    let currentIndexInArray = allIds.indexOf(currentId);
    if (currentIndexInArray > 0) {
      let nextConsultingId = allIds[currentIndexInArray - 1];
      history.push({
        pathname: `${COMPANY_DETAIL}/${nextConsultingId}`,
        state: { data: allIds }
      });
    } else {
      let nextId = allIds.length - 1;
      history.push({
        pathname: `${COMPANY_DETAIL}/${allIds[nextId]}`,
        state: { data: allIds }
      });
    }
  }

  const _onGoNextIds = () => {
    let currentId = match?.params?.id;
    let allIds = location?.state?.data;
    let currentIndexInArray = allIds.indexOf(currentId);
    if ((currentIndexInArray + 1) < allIds.length) {
      let nextConsultingId = allIds[currentIndexInArray + 1];
      history.push({
        pathname: `${COMPANY_DETAIL}/${nextConsultingId}`,
        state: { data: allIds }
      });
    } else {
      let nextId = 0;
      history.push({
        pathname: `${COMPANY_DETAIL}/${allIds[nextId]}`,
        state: { data: allIds }
      });
    }
  }

  // modal
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        paddingLeft: 50,
        boxShadow: "0px 0px 6px #F7F7F7",
        borderRadius: 8,
      }}
    >
      {/* Next and Back b uttons are over here */}
      <div style={{
        position: 'fixed',
        left: 100,
        zIndex: 1,
        top: "50%",
        display: location?.state?.data ? 'flex' : 'none'

      }}>
        <button onClick={() => _onGoBackIds()} className="btn btn-outline-primary btn-stella" style={{
          borderRadius: 50,
          width: 50,
          height: 50
        }}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
      </div>
      <div style={{
        position: 'fixed',
        right: 45,
        zIndex: 1,
        top: "50%",
        display: location?.state?.data ? 'flex' : 'none'

      }}>
        <button onClick={() => _onGoNextIds()} className="btn btn-outline-primary btn-stella" style={{
          borderRadius: 50,
          width: 50,
          height: 50
        }}>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
      <div
        className="row"
        style={{
          marginLeft: 20,
          marginRight: 28,
          paddingLeft: 20,
          marginTop: 20,
          paddingTop: 20,
        }}
      >
        <div className="col-6">
          <a
            href=""
            onClick={() => _CompanyList()}
            style={{ color: "#766AF0" }}
          >
            {" "}
            ບໍລິສັດທັງໝົດ »{" "}
          </a>
          ລາຍລະອຽດບໍລິສັດ
        </div>
        <div className="col-6" style={{ textAlign: "right" }}>
          <button
            type="button"
            class="btn btn-outline-danger col-md-3 dlt"
            onClick={handleShow}
          >
            <FontAwesomeIcon icon={faTrash} style={{ marginRight: 10 }} />
            ລຶບ
          </button>
          <button
            type="button"
            className="btn  btn-edit col-md-3 dlt"
            style={{ border: "solid 0px", marginRight: "2%" }}
            onClick={() => _CompanyEdit()}
          >
            <FontAwesomeIcon icon={faEdit} style={{ marginRight: 10 }} /> ແກ້ໄຂ
          </button>
        </div>
      </div>
      <div
        style={{
          marginLeft: 50,
          marginRight: 50,
          paddingLeft: 20,
          boxShadow: "0px 3px 6px #00000029",
          borderRadius: 8,
          opacity: 1,
          marginBottom: 20,
          paddingBottom: 5,
          width: "95%",
          backgroundColor: "#FFF",
        }}
      >
        <div
          style={{
            paddingLeft: 25,
            marginLeft: 25,
            height: "100%",
            width: "100%",
            marginBottom: 20,
            paddingBottom: 5,
          }}
        >
          <div className="row" style={{ marginTop: 10, paddingTop: 10 }}>
            <h5 style={{ padding: 20 }}>
              ລາຍລະອຽດບໍລິສັດ
              <br />
            </h5>
          </div>
          <div className="row">
            {data?.company?.image ? (
              <div className="col-4" style={{ padding: 30 }}>
                <Image
                  src={URL_FOR_SHOW_PHOTO + data?.company?.image}
                  alt="stellaIcon"
                  width="100"
                  height="100"
                  style={{
                    height: 300,
                    width: 300,
                    float: "left",
                    borderRadius: 5,
                  }}
                />
              </div>
            ) : (
              <div className="col-4" style={{ padding: 30 }}>
                <Image
                  src={ImageLogo + data?.company?.image}
                  alt="stellaIcon"
                  width="100"
                  height="100"
                  style={{
                    height: 300,
                    width: 300,
                    float: "left",
                    borderRadius: 5,
                  }}
                />
              </div>
            )}

            <div className="col" style={{ paddingTop: 5, marginTop: 5 }}>
              <h6
                className="row"
                style={{ paddingTop: 5, marginTop: 5, fontWeight: "bold" }}
              >
                <FontAwesomeIcon
                  icon={faFileAlt}
                  style={{ color: "#766AF0", marginRight: 10, marginRight: 15 }}
                />
                ຂໍ້ມູນທົ່ວໄປ
              </h6>
              <div className="row" style={{ paddingTop: 5, marginTop: 5 }}>
                <div className="col-3">ຊື່ບໍລິສັດ :</div>
                <div className="col-4" style={{ color: "#868686" }}>
                  {data?.company?.companyName
                    ? data?.company?.companyName
                    : "-"}
                </div>
              </div>
              <div className="row" style={{ paddingTop: 5, marginTop: 5 }}>
                <div className="col-3">ປະເພດຂອງບໍລິສັດ :</div>
                <div className="col-4" style={{ color: "#868686" }}>
                  {data?.company?.companyType
                    ? data?.company?.companyType
                    : "-"}
                </div>
              </div>
              <div className="row" style={{ paddingTop: 5, marginTop: 5 }}>
                <div className="col-3">ສັງກັດ :</div>
                <div className="col-4" style={{ color: "#868686" }}>
                  {data?.company?.affiliation
                    ? data?.company?.affiliation
                    : "-"}
                </div>
              </div>
              <div className="row" style={{ paddingTop: 5, marginTop: 5 }}>
                <div className="col-3">ສະຖານທີ່ຢູ່ບໍລິສັດ :</div>
                <div className="col-4" style={{ color: "#868686" }}>
                  {data?.company?.addressCompany?.village +
                    " " +
                    data?.company?.addressCompany?.district +
                    " " +
                    data?.company?.addressCompany?.province}
                </div>
              </div>
              <div className="row" style={{ paddingTop: 5, marginTop: 5 }}>
                <div className="col-3"> ວັບໄຊສ :</div>
                <div className="col-4" style={{ color: "#868686" }}>
                  {data?.company?.webSiteLink
                    ? data?.company?.webSiteLink
                    : "-"}
                </div>
              </div>
              <div className="row" style={{ paddingTop: 5, marginTop: 5 }}>
                <div className="col-3"> ອີເມວ :</div>
                <div className="col-4" style={{ color: "#868686" }}>
                  {data?.company?.contactChannel?.emailCompany
                    ? data?.company?.contactChannel?.emailCompany
                    : "-"}
                </div>
              </div>
              <h6
                className="row"
                style={{ paddingTop: 10, marginTop: 10, fontWeight: "bold" }}
              >
                <FontAwesomeIcon
                  icon={faPhone}
                  style={{ color: "#766AF0", marginRight: 10, marginRight: 15 }}
                />
                ຊ່ອງທາງການຕິດຕໍ່ (ຜູ້ຕາງໜ້າ)
              </h6>
              <div className="row" style={{ paddingTop: 5, marginTop: 5 }}>
                <div className="col-3"> ຊື່ ແລະ ນາມສະກຸນ :</div>
                <div className="col-4" style={{ color: "#868686" }}>
                  {data?.company?.contactChannel?.name
                    ? data?.company?.contactChannel?.name
                    : "-"}
                </div>
              </div>
              <div className="row" style={{ paddingTop: 5, marginTop: 5 }}>
                <div className="col-3"> ຕຳແໜ່ງ :</div>
                <div className="col-4" style={{ color: "#868686" }}>
                  {data?.company?.contactChannel?.area
                    ? data?.company?.contactChannel?.area
                    : "-"}
                </div>
              </div>
              <div className="row" style={{ paddingTop: 5, marginTop: 5 }}>
                <div className="col-3"> ເບີໂທ :</div>
                <div className="col-4" style={{ color: "#868686" }}>
                  {data?.company?.contactChannel?.phone
                    ? data?.company?.contactChannel?.phone
                    : "-"}
                </div>
              </div>
              <div className="row" style={{ paddingTop: 5, marginTop: 5 }}>
                <div className="col-3"> ອີເມວ :</div>
                <div className="col-4" style={{ color: "#868686" }}>
                  {data?.company?.contactChannel?.email
                    ? data?.company?.contactChannel?.email
                    : "-"}
                </div>
              </div>
              <h6
                className="row"
                style={{
                  paddingTop: 10,
                  marginTop: 10,
                  marginRight: 10,
                  marginRight: 15,
                  fontWeight: "bold",
                }}
              >
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  style={{ color: "#766AF0", marginRight: 10, marginRight: 15 }}
                />
                ຂໍ້ມູນເພີ່ມເຕີມ
              </h6>
              <div className="row" style={{ paddingTop: 5, marginTop: 5 }}>
                <div className="col-3"> ທຶນການສຶກສາ :</div>
                <div className="col-4" style={{ color: "#868686" }}>
                  {data?.company?.scholarships
                    ? data?.company?.scholarships
                    : "-"}
                </div>
              </div>
              <div className="row" style={{ paddingTop: 5, marginTop: 5 }}>
                <div className="col-3"> ຮັບນັກຮຽນຝຶກງານ :</div>
                <div className="col-4" style={{ color: "#868686" }}>
                  {data?.company?.interns ? data?.company?.interns : "-"}
                </div>
              </div>
              <div className="row" style={{ paddingTop: 5, marginTop: 5 }}>
                <div className="col-3"> ຮັບພະນັກງານ :</div>
                <div className="col-4" style={{ color: "#868686" }}>
                  {data?.company?.recrui ? data?.company?.recrui : "-"}
                </div>
              </div>
              <div className="row" style={{ paddingTop: 5, marginTop: 5 }}>
                <div className="col-3"> ຮັບອາສາສະໝັກ :</div>
                <div className="col-4" style={{ color: "#868686" }}>
                  {data?.company?.volunteers ? data?.company?.volunteers : "-"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>ລຶບບໍລິສັດ</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row center">
              <div>ທ່ານຕ້ອງການລຶບ</div>
              <div
                style={{
                  color: "red",
                  fontWeight: "bold",
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                {data?.company?.companyName}
              </div>
              <div>ນີ້ບໍ ?</div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              ຍົກເລິກ
            </Button>
            <Button variant="btn btn-app" onClick={() => _confrimeDelete()}>
              ຢືນຢັນການລຶບ
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}
