import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Bar, HorizontalBar } from "react-chartjs-2";
import {
  faClipboard,
  faDivide,
  faHeart,
  faUsers,
  faMale,
  faFemale,
  faVenusMars,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import Plot from "react-plotly.js";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { TITLE_HEADER, HEADER, BODY } from "../../../consts";
import Chart from "chart.js";
import {
  USERS,
  CONSULTINGS,
  USERS_PRICE,
  CONSULTING_CATEGORYS,
  FLOWCHART_CONSULTINGS,
  PROVINE_FLOWCHART,
  SATISFACTION,
  GENDER_FLOWCHART,
  AGE_FLOWCHART,
  TEST_GENDER_CHART,
} from "../apollo";
import { _provincelevel } from "../../../helper/super";
export default function DashBoardDetail() {
  const [selectedStartDate, setSelectedStartDate] = useState("2020-01-01");
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [newData, { loading, error, data }] = useLazyQuery(USERS, {
    variables: { where: { role_in: "USER", startDate: selectedStartDate, endDate: selectedEndDate } },
  });
  const [
    newConsultingData,
    {
      loading: ConsultingLoading,
      error: ConsultingError,
      data: ConsultingData,
    },
  ] = useLazyQuery(CONSULTINGS, {
    variables: { where: { startDate: selectedStartDate, endDate: selectedEndDate } }
  });
  const [
    newConsulting_priceData,
    {
      loading: Consulting_priceLoading,
      error: Consulting_priceError,
      data: Consulting_priceData,
    },
  ] = useLazyQuery(USERS_PRICE, {
    variables: { where: { startDate: selectedStartDate, endDate: selectedEndDate } },
  });


  const [
    newConsulting_categoryspriceData,
    {
      loading: Consulting_categorysLoading,
      error: Consulting_categoryspriceError,
      data: Consulting_categoryspriceData,
    },
  ] = useLazyQuery(CONSULTING_CATEGORYS);

  const [
    newflowchartdata,
    { loading: flowchartLaoding, error: flowchartError, data: flowchartdata },
  ] = useLazyQuery(FLOWCHART_CONSULTINGS, {
    variables: { where: { startDate: selectedStartDate, endDate: selectedEndDate } },
  });

  const [
    newflowchartProvinedata,
    {
      loading: flowchartProvineLaoding,
      error: flowchartProvineError,
      data: flowchartProvinedata,
    },
  ] = useLazyQuery(PROVINE_FLOWCHART, {
    variables: { where: { startDate: selectedStartDate, endDate: selectedEndDate } },
  });

  const [
    newmainCategorydata,
    {
      loading: mainCategoryLaoding,
      error: mainCategoryError,
      data: mainCategorydata,
    },
  ] = useLazyQuery(SATISFACTION);

  // ===============++++++++============>>
  const [fliterGender, setFliterGender] = useState(null);
  const [
    leloadFlitterGender,
    { loading: genderLaoding, error: genderError, data: genderdata },
  ] = useLazyQuery(GENDER_FLOWCHART, {
    variables: { gender: fliterGender, where: { startDate: selectedStartDate, endDate: selectedEndDate } },
  });
  // ===================++++++++===========+++>
  const [fliterAge, setFliterAge] = useState(null);
  const [
    leloadFlitterAge,
    { loading: AgeLaoding, error: AgeError, data: Agedata },
  ] = useLazyQuery(AGE_FLOWCHART, {
    variables: { age: parseInt(fliterAge), where: { startDate: selectedStartDate, endDate: selectedEndDate } },
  });
  useEffect(() => {
    newData();
    newConsultingData();
    newConsulting_priceData();
    newConsulting_categoryspriceData();
    newflowchartdata();
    newflowchartProvinedata();
    newmainCategorydata();
  }, []);
  useEffect(() => {
    leloadFlitterGender();
  }, [fliterGender]);

  useEffect(() => {
    leloadFlitterAge();
  }, [fliterAge]);
  // +++++++++++===========+++++++++++
  let provinChart = [];
  let provinChartCount = [];
  for (let w = 0; w < flowchartProvinedata?.province_Flowchart?.length; w++) {
    provinChart.push(
      _provincelevel(flowchartProvinedata?.province_Flowchart[w]?._id)
    );
    provinChartCount.push(flowchartProvinedata?.province_Flowchart[w]?.count);
  }
  const state = {
    labels: provinChart,
    datasets: [
      {
        label: "ຈໍານວນຄົນ",
        backgroundColor: "#766AF0",
        borderWidth: 2,
        data: provinChartCount,
      },
    ],
  };
  const _renderCategory = (e) => {
    let res = Consulting_categoryspriceData?.consultingCategorys?.data?.filter(
      (x) => x.id === e
    );
    if (res?.length > 0) return res[0]?.name;
  };
  // ==== chart flitter category ========>>>
  let category = [];
  let categoryCount = [];
  for (let i = 0; i < flowchartdata?.flowchart_consultings?.length; i++) {
    category.push(
      _renderCategory(flowchartdata?.flowchart_consultings[i]?._id)
    );
    categoryCount.push(flowchartdata?.flowchart_consultings[i]?.count);
  }
  // ==== chart flitter Gender ========>>>
  let genderTotalCategory = [];
  let genderTotalCount = [];
  for (
    let k = 0;
    k < genderdata?.consulting_and_Gender_Flowchart?.length;
    k++
  ) {
    genderTotalCategory.push(
      _renderCategory(
        genderdata?.consulting_and_Gender_Flowchart[k]?.consultingCategoryId
      ) +
      " / " +
      _provincelevel(genderdata?.consulting_and_Gender_Flowchart[k]?.province)
    );
    genderTotalCount.push(
      genderdata?.consulting_and_Gender_Flowchart[k]?.count
    );
  }
  // ==== chart flitter age ========>>>
  let AgeTotalCategory = [];
  let AgeTotalCount = [];
  for (let k = 0; k < Agedata?.consulting_and_Age_Flowchart?.length; k++) {
    AgeTotalCategory.push(
      _renderCategory(
        Agedata?.consulting_and_Age_Flowchart[k]?.consultingCategoryId
      ) +
      " / " +
      _provincelevel(Agedata?.consulting_and_Age_Flowchart[k]?.province)
    );
    AgeTotalCount.push(Agedata?.consulting_and_Age_Flowchart[k]?.count);
  }
  // =============++++++++++++============>
  const stateThree = {
    labels: genderTotalCategory,
    datasets: [
      {
        label: "ຈໍານວນຄົນ",
        backgroundColor: "#766AF0",
        borderWidth: 2,
        borderRadius: 8,
        data: genderTotalCount,
      },
    ],
  };
  const statetwo = {
    labels: AgeTotalCategory,
    datasets: [
      {
        label: "ຈໍານວນຄົນ",
        backgroundColor: "#766AF0",
        borderWidth: 2,
        borderRadius: 8,
        data: AgeTotalCount,
      },
    ],
  };
  // session / ຄົນ
  const [session, setSession] = useState();
  // prie shape
  const [genderF, setGenderF] = useState();
  const [genderM, setGenderM] = useState();
  const [genderO, setGenderO] = useState();
  const [genderD, setGenderN] = useState();
  const [satisfactionAll, setSatisfactionAll] = useState();
  useEffect(() => {
    //satisfaction
    let status = [];
    for (
      let j = 0;
      j < mainCategorydata?.satisfaction?.mainStatus?.length;
      j++
    ) {
      if (mainCategorydata?.satisfaction?.mainStatus[j]?._id === "SATISFIED") {
        status.push(mainCategorydata?.satisfaction?.mainStatus[j]?.count);
      }
    }
    setSatisfactionAll(status[0] / mainCategorydata?.satisfaction?.countAll);

    let sumSession = ConsultingData?.consultings?.total / data?.users?.total;
    let sumGenderF =
      Consulting_priceData?.users_Price?.FeMaleTotal /
      Consulting_priceData?.users_Price?.AllTotal;

    let sumGenderM =
      Consulting_priceData?.users_Price?.MaleTotal /
      Consulting_priceData?.users_Price?.AllTotal;

    let sumGenderO =
      Consulting_priceData?.users_Price?.OtherTotal /
      Consulting_priceData?.users_Price?.AllTotal;

    let sumGenderN =
      Consulting_priceData?.users_Price?.No_genderTotal /
      Consulting_priceData?.users_Price?.AllTotal;

    setGenderF(sumGenderF * 100);
    setGenderM(sumGenderM * 100);
    setGenderO(sumGenderO * 100);
    setGenderN(sumGenderN * 100);
    setSession(sumSession);

    const ctx = document.getElementById("myChart");
    new Chart(ctx, {
      type: "pie",
      data: {
        labels: ["ຍີງ", "ຊາຍ", "ອື່ນໆ", "ບໍ່ລະບຸ"],
        datasets: [
          {
            data: [
              new Intl.NumberFormat("en-IN", {
                maximumSignificantDigits: 3,
              }).format(genderF),
              new Intl.NumberFormat("en-IN", {
                maximumSignificantDigits: 3,
              }).format(genderM),
              new Intl.NumberFormat("en-IN", {
                maximumSignificantDigits: 3,
              }).format(genderO),
              new Intl.NumberFormat("en-IN", {
                maximumSignificantDigits: 3,
              }).format(genderD),
            ],
            labels: ["Red", "Yellow", "Blue", "Blue"],
            backgroundColor: ["#c05cd7", "#766AF0", "#5caed7", "#5c89d7"],
            borderWidth: 0.5,
          },
        ],
      },
    });
  });
  const dataHorBar = {
    labels: category,
    datasets: [
      {
        label: "ຈໍານວນຄົນ",
        backgroundColor: "#766AF0",
        borderColor: "#766AF0",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(123, 119, 246,0.7)",
        hoverBorderColor: "rgba(123, 119, 246,1)",
        borderRadius: 50,
        data: categoryCount,
      },
    ],
  };
  let years = [];
  for (let i = 10; i < 70; i++) {
    years.push(i);
  }
  const _fliterGender = (e) => {
    if (e?.target?.value === "null") {
      setFliterGender(null);
    } else {
      setFliterGender(e?.target?.value);
    }
  };
  const _fliterAge = (e) => {
    setFliterAge(e?.target?.value);
  };


  /**
   * on date change functions
   * 
   */
  const _onStartDateChange = (e) => {
    let startDate = e.target.value;
    // console.log(e.target.value);
    if (!startDate) startDate = undefined;
    setSelectedStartDate(startDate);
  };

  const _onEndDateChange = (e) => {
    let endDate = e.target.value;
    if (!endDate) endDate = undefined;
    setSelectedEndDate(endDate);
  };
  /**
   * filter the dates
   * 
   */
  useEffect(() => {
    let todayDate = new Date();

    let year = (todayDate.getFullYear());
    let month = (todayDate.getMonth() + 1);
    let date = (todayDate.getDate());
    setSelectedEndDate(`${year}-${month}-${date}`);


  }, []);


  // =============================== retrun ==================
  return (
    <div style={TITLE_HEADER}>
      <div style={{ marginLeft: -20, marginRight: 55 }}>
        <div
          className="col"
          style={{
            backgroundColor: "#ffff",
            padding: 32,
            marginLeft: 43,
            borderRadius: 8,
            marginRight: 50,
          }}
        >
          <h6 style={{
            fontWeight: 'bold'
          }}>ສະແດງຕາມເວລາ</h6>
          <div className="row" style={{
            paddingLeft: 20,
            paddingTop: 20
          }}>
            <div className="col-3">
              <label>ຕັ້ງແຕ່ ວັນທີ</label>
              <input
                value={selectedStartDate}
                onChange={(e) => _onStartDateChange(e)}
                type="date"
                className="form-control empty"
              />
            </div>

            <div>
              <div style={{ float: "center", margin: 5, padding: 10 }}></div>
              <div style={{ float: "center", margin: 5, padding: 10 }}>~</div>
            </div>
            <div className="col-3">
              <label>ເຖີງ ວັນທີ</label>
              <input
                value={selectedEndDate}
                onChange={(e) => _onEndDateChange(e)}
                type="date"
                className="form-control empty"
              />
            </div>
          </div>
        </div>
      </div>
      <h6
        style={{
          marginLeft: 25,
        }}
      >
        <p>ສະຖິຕິລວມ » </p>
      </h6>
      <div style={{ marginLeft: 55, marginRight: 55 }}>
        <div className="row">
          <div
            className="col-md-3 row center"
            style={{
              backgroundColor: "#FFFFFF",
              padding: 10,
              borderRadius: 5,
              height: 100,
            }}
          >
            <div className="col-6 center">
              <FontAwesomeIcon
                icon={faUsers}
                style={{ color: "#766AF0", fontSize: 40 }}
              />
            </div>
            <div className="col-6 center">
              <div style={{ color: "#1B1B1B", fontWeight: "bold" }}>
                {data?.users?.total}
              </div>
              <div style={{ color: "#868686" }}>ຜູ້ໃຊ້ບໍລິການທັງໝົດ</div>
            </div>
          </div>
          <div style={{ width: 40 }}></div>
          <div
            className="col-md-3 row center"
            style={{ backgroundColor: "#FFFFFF", padding: 10, borderRadius: 5 }}
          >
            <div className="col-6 btn center">
              <FontAwesomeIcon
                icon={faClipboard}
                style={{ color: "#766AF0", fontSize: 40 }}
              />
            </div>
            <div className="col-6 center">
              <div style={{ color: "#1B1B1B", fontWeight: "bold" }}>
                {ConsultingData?.consultings?.total}
              </div>
              <div style={{ color: "#868686" }}>Session ທັງໝົດ</div>
            </div>
          </div>
          <div style={{ width: 40 }}></div>
          <div
            className="col-md-3 row center"
            style={{ backgroundColor: "#FFFFFF", padding: 10, borderRadius: 5 }}
          >
            <div className="col-6 btn center">
              <FontAwesomeIcon
                icon={faDivide}
                style={{ color: "#766AF0", fontSize: 40 }}
              />
            </div>
            <div className="col-6 center">
              <div style={{ color: "#1B1B1B", fontWeight: "bold" }}>
                {new Intl.NumberFormat("en-IN", {
                  maximumSignificantDigits: 3,
                }).format(session) === "NaN"
                  ? "0 "
                  : new Intl.NumberFormat("en-IN", {
                    maximumSignificantDigits: 3,
                  }).format(session)}
              </div>
              <div style={{ color: "#868686" }}>Session/ຄົນ</div>
            </div>
          </div>
          <div style={{ width: 40 }}></div>
          <div
            className="col-md-3 row center"
            style={{ backgroundColor: "#FFFFFF", padding: 10, borderRadius: 5 }}
          >
            <div className="col-6 btn center">
              <FontAwesomeIcon
                icon={faHeart}
                style={{ color: "#766AF0", fontSize: 40 }}
              />
            </div>
            <div className="col-6 center">
              <div style={{ color: "#1B1B1B", fontWeight: "bold" }}>
                {new Intl.NumberFormat("en-IN", {
                  maximumSignificantDigits: 3,
                }).format(satisfactionAll * 100) === "NaN"
                  ? "0 "
                  : new Intl.NumberFormat("en-IN", {
                    maximumSignificantDigits: 3,
                  }).format(satisfactionAll * 100)}
                %
              </div>
              <div style={{ color: "#868686" }}>ຄວາມພຶງພໍໃຈ</div>
            </div>
          </div>
        </div>
      </div>
      <h6
        style={{
          marginLeft: 25,
          marginTop: 25,
          marginBottom: 25,
        }}
      >
        <p>ສະຖິຕິຜູ້ໃຊ້ບໍລິການ </p>
      </h6>
      <div className="row">
        <div
          className="col-md-7"
          style={{
            backgroundColor: "#ffff",
            padding: 32,
            marginLeft: 43,
            borderRadius: 8,
          }}
        >
          <div>ຕາມແຂວງຢູ່ປະຈຸບັນ</div>
          {/* <canvas id="myChart2" width="10" height="10"></canvas> */}
          <Bar
            data={state}
            options={{
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
            }}
          />
        </div>
        <div style={{ width: 50 }}></div>
        <div
          className="col-4"
          style={{ backgroundColor: "#ffff", padding: 32, borderRadius: 8 }}
        >
          <div>ເພດ</div>
          <div style={{ padding: 20 }}>
            <canvas id="myChart" width="10" height="10"></canvas>
            <div style={{ height: 20 }}></div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  backgroundColor: "#c05cd7",
                  width: 25,
                  borderRadius: "100%",
                  marginRight: 25,
                  marginBottom: 10,
                  color: "#c05cd7",
                }}
              >
                .
              </div>
              <FontAwesomeIcon
                icon={faFemale}
                style={{
                  marginRight: 10,
                  marginTop: 5,
                  color: "#646464",
                }}
              />{" "}
              ຍິງ{" "}
              {new Intl.NumberFormat("en-IN", {
                maximumSignificantDigits: 3,
              }).format(genderF) === "NaN"
                ? "0 "
                : new Intl.NumberFormat("en-IN", {
                  maximumSignificantDigits: 3,
                }).format(genderF)}{" "}
              % ({Consulting_priceData?.users_Price?.FeMaleTotal} ຄົນ)
            </div>
            <div style={{ display: "flex" }}>
              <p
                style={{
                  backgroundColor: "#766AF0",
                  width: 25,
                  borderRadius: "100%",
                  marginRight: 25,
                  color: "#766AF0",
                }}
              >
                .
              </p>
              <FontAwesomeIcon
                icon={faMale}
                style={{ marginRight: 10, marginTop: 5, color: "#646464" }}
              />{" "}
              ຊາຍ:{" "}
              {new Intl.NumberFormat("en-IN", {
                maximumSignificantDigits: 3,
              }).format(genderM) === "NaN"
                ? "0 "
                : new Intl.NumberFormat("en-IN", {
                  maximumSignificantDigits: 3,
                }).format(genderM)}{" "}
              % ({Consulting_priceData?.users_Price?.MaleTotal} ຄົນ)
            </div>
            <div style={{ display: "flex" }}>
              <p
                style={{
                  backgroundColor: "#5caed7",
                  width: 25,
                  borderRadius: "100%",
                  marginRight: 25,
                  color: "#5caed7",
                }}
              >
                .
              </p>
              <FontAwesomeIcon
                icon={faVenusMars}
                style={{ marginRight: 10, marginTop: 5, color: "#646464" }}
              />{" "}
              ອື່ນໆ: {""}
              {new Intl.NumberFormat("en-IN", {
                maximumSignificantDigits: 3,
              }).format(genderO) === "NaN"
                ? "0 "
                : new Intl.NumberFormat("en-IN", {
                  maximumSignificantDigits: 3,
                }).format(genderO)}{" "}
              % ({Consulting_priceData?.users_Price?.OtherTotal} ຄົນ)
            </div>
            <div style={{ display: "flex" }}>
              <p
                style={{
                  backgroundColor: "#5c89d7",
                  width: 25,
                  borderRadius: "100%",
                  marginRight: 25,
                  color: "#5c89d7",
                }}
              >
                .
              </p>
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ marginRight: 10, marginTop: 5, color: "#707070" }}
              />{" "}
              ບໍ່ລະບຸ:{" "}
              {new Intl.NumberFormat("en-IN", {
                maximumSignificantDigits: 3,
              }).format(genderD) === "NaN"
                ? "0 "
                : new Intl.NumberFormat("en-IN", {
                  maximumSignificantDigits: 3,
                }).format(genderD)}{" "}
              % ({Consulting_priceData?.users_Price?.No_genderTotal} ຄົນ)
            </div>
          </div>
        </div>
        <h6
          style={{
            marginLeft: 43,
            marginTop: 25,
            marginBottom: 25,
          }}
        >
          <p>ສະຖິຕິການປຶກສາ </p>
        </h6>
        <div
          className="col"
          style={{
            backgroundColor: "#ffff",
            padding: 32,
            marginLeft: 43,
            borderRadius: 8,
            marginRight: 50,
          }}
        >
          <div>10 ໝວດການປຶກສາທີ່ນິຍົມ</div>

          <HorizontalBar
            data={dataHorBar}
            options={{
              scales: {
                xAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
            }}
          />
        </div>
        <div
          className="col"
          style={{
            backgroundColor: "#ffff",
            padding: 32,
            marginLeft: 43,
            borderRadius: 8,
            marginRight: 50,
            marginTop: 50,
          }}
        >
          <select
            className="custom-select col-2"
            onChange={(e) => _fliterAge(e)}
          >
            <option value="null">ເລືອກອາຍຸ</option>
            {years?.map((item, index) => {
              return <option value={item}>{item}</option>;
            })}
          </select>
          <Bar
            data={statetwo}
            options={{
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
            }}
          />
        </div>
        <div
          className="col"
          style={{
            backgroundColor: "#ffff",
            padding: 32,
            marginLeft: 43,
            borderRadius: 8,
            marginRight: 50,
            marginTop: 50,
          }}
        >
          <select
            className="custom-select col-2"
            onChange={(e) => _fliterGender(e)}
          >
            <option value="null">ເລືອກເພດ</option>
            <option value="MALE">ຊາຍ</option>
            <option value="FEMALE">ຍິງ</option>
            <option value="OTHER">ອື່ນໆ</option>
            <option value="NO_GENDER">ບໍ່ລະບຸ</option>
          </select>
          <Bar
            data={stateThree}
            options={{
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}
