import { gql } from "apollo-boost";
export const UPDATE_USER = gql`
  mutation UpdateConsultingCategory(
    $data: UserWhereInput
    $where: UserWhereInputId!
  ) {
    updateUser(data: $data, where: $where) {
      id
    }
  }
`;
export const UPDATE_MAINCONSOULTING = gql`
  mutation UpdateMainConsouting(
    $data: MainConsoutingWhereInput
    $where: MainConsoutingWhereInputId!
  ) {
    updateMainConsouting(data: $data, where: $where) {
      id
    }
  }
`;
export const USERS = gql`
  query Users($where: UserWhereInput, $limit: Int, $skip: Int) {
    users(where: $where, limit: $limit, skip: $skip) {
      total
      data {
        id
        firstName
        lastName
        fixAge
        workingStatus
        status
        phone
        note
        image
        createdAt
      }
    }
  }
`;
export const USER = gql`
  query user($where: UserWhereInputId!) {
    user(where: $where) {
      id
      userId
      firstName
      lastName
      phone
      role
      birthday
      gender
      religion
      tribe
      fixAge
      image
      status
      address {
        province
        district
        village
      }
      canCounseling {
        id
      }
      provinceBorn
      accessibility
      educationLevel
      branch
      workingStatus
      company
      detail
      note
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const CREATE_USER = gql`
  mutation CreateUser($data: UserWhereInput) {
    createUser(data: $data) {
      id
    }
  }
`;
export const PRESINGED_URL = gql`
  mutation PreSignedUrl($fileName: String) {
    preSignedUrl(fileName: $fileName) {
      url
    }
  }
`;
export const DELETE_USER = gql`
  mutation DeleteUser($where: UserWhereInputId!) {
    deleteUser(where: $where) {
      id
    }
  }
`;

export const CONSULTINGS = gql`
  query consultings($where: ConsultingWhereInput, $skip: Int, $limit: Int) {
    consultings(where: $where, skip: $skip, limit: $limit) {
      total
      data {
        id
        userId
        firstResponderId
        mentorId
        consultingCategoryId
        mainConsultingId
        status
        appointmentDate
        appointmentAddress
        followUp
        detail
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const CONSULTING_CATEGORYS = gql`
  query ConsultingCategorys($where: ConsultingCategorysWhereInput, $skip: Int) {
    consultingCategorys(where: $where, skip: $skip) {
      total
      data {
        id
        name
        detail
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const UPDATE_STATUS_USER = gql`
  mutation UpdateConsultingCategory(
    $data: UserWhereInput
    $where: UserWhereInputId!
  ) {
    updateUser(data: $data, where: $where) {
      id
    }
  }
`;
