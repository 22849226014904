import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";
import Image from "react-bootstrap/Image";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faBookmark,
  faList,
  faUser,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
  TITLE_HEADER,
  URL_FOR_SHOW_PHOTO,
  USER_KEY
} from "../../../consts";
import { MENTOR_DETAIL, MENTOR_ADD } from "../../../consts/router";
import profileImage from "../../../image/profile.png";
import { formatDate } from "../../../helper/super";
import { USERS, CONSULTING_CATEGORYS, CONSULTINGS } from "../apollo";
const MENTOR = "MENTOR";
export default function MentorList() {
  const { history, location, match } = useReactRouter();
  const [selectedPhone, setSelectedPhone] = useState();
  const [cateList, setCateList] = useState([]);
  const [selectedCateId, setSelectedCateId] = useState();
  const _limit = match.params.limit;
  const _skip = match.params.skip;
  const { loading, error, data } = useQuery(USERS, {
    variables: {
      where: {
        role_in: MENTOR,
        phone: selectedPhone,
        canCounseling: selectedCateId
          ? [
            {
              id: selectedCateId,
            },
          ]
          : undefined,
      },
      limit: parseInt(_limit),
      skip: parseInt(_skip) * parseInt(_limit),
    },
  });
  useEffect(() => {
    if (data?.users?.data?.length > 0) {
      _getArrayPageNumber();
    }
  }, [data]);

  const [totalPage, setTotalPage] = useState([]);
  const _getArrayPageNumber = () => {
    let rowPage = [];
    let _total = 0;
    if (data?.users?.total % parseInt(_limit) != 0) {
      _total = parseInt(data?.users?.total) / parseInt(_limit) + 1;
    } else {
      _total = parseInt(data?.users?.total) / parseInt(_limit);
    }
    for (let i = 1; i <= _total; i++) {
      rowPage.push(i);
    }
    setTotalPage(rowPage);
  };
  const _changePage = (index) => {
    history.push("/mentor/limit/" + parseInt(_limit) + "/skip/" + index);
  };
  const {
    loading: consultingLoading,
    error: consultingError,
    data: consultingData,
  } = useQuery(CONSULTINGS, {
    variables: {
      where: {
        status: "ONGOING_MT",
      },
    },
  });
  const { loading: cateLoading, error: cateError, data: cateData } = useQuery(
    CONSULTING_CATEGORYS
  );
  useEffect(() => {
    if (cateData) {
      let cateList = cateData?.consultingCategorys?.data;
      setCateList(cateList);
    }
  }, [cateData]);

  const _MentorDetail = (id) => {
    // history.push(`${MENTOR_DETAIL}/${id}`, id);
    history.push({
      pathname: `${MENTOR_DETAIL}/${id}`,
      state: { data: allMentorIds }
    });
  };
  const _MentorAdd = () => {
    history.push(`${MENTOR_ADD}`);
  };
  const _renderCategory = (e) => {
    let res = cateData?.consultingCategorys?.data?.filter((x) => x.id === e);
    if (res?.length > 0) return res[0]?.name ? res[0]?.name : "-";
  };

  const _onPhoneChange = (e) => {
    const inputedPhone = e.target.value;
    setSelectedPhone(inputedPhone);
  };

  const _renderACTIVE = (e) => {
    let active = [];
    for (let i = 0; i < consultingData?.consultings?.data?.length; i++) {
      active.push(consultingData?.consultings?.data[i]?.mentorId);
    }
    let res = active?.filter((x) => x === e);
    if (res?.length > 0) return res?.length;
  };

  const _onCateChange = (e) => {
    let choosedCateId = e.target.value;
    if (!choosedCateId) choosedCateId = undefined;
    setSelectedCateId(choosedCateId);
  };
  const [userData, setUserData] = useState({});
  useEffect(() => {
    const _resData = localStorage.getItem(USER_KEY);
    const _localJson = JSON.parse(_resData);
    setUserData(_localJson);
  }, []);
  let check = userData?.loginUser?.data?.role;

  /***
  * to get all consultings data
  */
  const [allMentorIds, setAllMentorIds] = useState([]);
  useEffect(() => {
    if (data?.users?.data?.length > 0) {
      let dataId = data?.users?.data?.map(data => data.id);
      // console.log({data: data?.consultings?.data[200]})
      setAllMentorIds([...dataId]);
    }
  }, [data]);
  return (
    <div style={TITLE_HEADER}>
      <div
        style={{
          marginTop: 25,
          marginLeft: 25,
          marginRight: 25,
          padding: 32,
          background: "#FFFFFF",
          borderRadius: 4,
          opacity: 1,
          width: "auto",
        }}
      >
        <div className="row" style={{ borderRadius: 4 }}>
          <div className="col-md-12" style={{ fontSize: "20px" }}>
            Mentor ({data?.users?.total})
          </div>
        </div>
        <div style={{ paddingBottom: 20 }}>
          {check === "ADMIN" &&
            <button
              type="button"
              className="btn btn-secondary btn-app"
              style={{ float: "right", margin: "5" }}
              onClick={() => _MentorAdd()}
            >
              {" "}
              <FontAwesomeIcon
                icon={faPlus}
                style={{ textAlign: "left" }}
                style={{ marginRight: 20 }}
              />{" "}
              ເພີ່ມ Mentor{" "}
            </button>
          }
          <select
            onChange={(e) => _onCateChange(e)}
            className="form-control col-2"
            id="inputGroupSelect01"
            style={{
              float: "right",
              fontWeight: "bold",
              margin: "5",
              marginRight: "5px",
            }}
          >
            <option value="">ໝວດປຶກສາ</option>
            {cateList.map((cate, index) => (
              <option key={index} value={cate.id}>
                {cate.name}
              </option>
            ))}
          </select>

          <input
            onChange={(e) => _onPhoneChange(e)}
            type="text"
            style={{ float: "right", marginRight: "5px", marginLeft: "5px" }}
            className="form-control col-3 empty"
            placeholder="&#xF002;  ຄົ້ນຫາຕາມເບີໂທ"
          />
        </div>
        <div className="row" style={{ paddingLeft: 30 }}>
          <p style={{ color: "#5C5C5C" }}></p>
        </div>

        <div style={{ height: 30 }}></div>
        <div className="col-md-14">
          <table className="table">
            <thead>
              <tr className="center">
                <th scope="col">#</th>
                <th scope="col">ຮູບພາບ</th>
                <th scope="col">ຊື່</th>
                <th scope="col">ໝວດ</th>
                <th scope="col">ເບີໂທ</th>
                <th scope="col">Active User</th>
                <th scope="col">ວັນທີ່ສ້າງ</th>
              </tr>
            </thead>
            <tbody style={{ border: "1px solid #F7F7F7", opacity: 1 }}>
              {data?.users?.data.map((data, index) => {
                return (
                  <tr
                    key={index}
                    onClick={() => _MentorDetail(data?.id)}
                    className="center"
                  >
                    <th>{index + 1 + parseInt(_limit) * parseInt(_skip)}</th>
                    {data?.image ? (
                      <td>
                        <Image
                          src={URL_FOR_SHOW_PHOTO + data?.image}
                          alt="stellaImage"
                          style={{
                            height: 40,
                            width: 40,
                            borderRadius: "50%",
                          }}
                        />
                      </td>
                    ) : (
                      <td>
                        <Image
                          src={profileImage + data?.image}
                          alt="stellaImage"
                          style={{
                            height: 40,
                            width: 40,
                            borderRadius: "50%",
                          }}
                        />
                      </td>
                    )}
                    <td>{data?.firstName}</td>
                    <td>
                      {data?.canCounseling?.map((item, index) => {
                        if (index < 3) {
                          return (
                            <label
                              key={index}
                              style={{
                                background:
                                  "#EC6869 0% 0% no-repeat padding-box",
                                borderRadius: 5,
                                border: "solid 1px",
                                opacity: 1,
                                color: "#FFFFFF",
                                marginRight: 10,
                                padding: 2,
                              }}
                            >
                              {_renderCategory(item?.id)}
                            </label>
                          );
                        }
                      })}
                      {data?.canCounseling.length > 3 && (
                        <span style={{ fontWeight: "bold" }}>.....</span>
                      )}
                    </td>
                    <td>{data?.phone ? data?.phone : "-"}</td>
                    <td>{_renderACTIVE(data?.id)}</td>
                    <td>{formatDate(data?.createdAt)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="row center">
            {totalPage?.map((numbersPages, index) => {
              return (
                <button
                  key={index}
                  onClick={() => _changePage(index)}
                  style={{
                    width: 30,
                    height: 30,
                    border: "1px solid #9999ff",
                    borderRadius: "10%",
                    justifyContent: "center",
                    textAlign: "center",
                    marginRight: 2,
                    backgroundColor:
                      parseInt(_skip) == index ? "#9999ff" : "#fff",
                    color: parseInt(_skip) == index ? "#fff" : "#000",
                  }}
                >
                  {numbersPages}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}
