import { gql } from "apollo-boost";
export const CREATE_CONSULTING = gql`
  mutation CreateConsulting($data: ConsultingWhereInput) {
    createConsulting(data: $data) {
      id
      userId
      firstResponderId
      mentorId
    }
  }
`;
export const CREATE_COMMENT = gql`
  mutation CreateComment($data: CommentWhereInput) {
    createComment(data: $data) {
      id
    }
  }
`;
export const UPDATE_CONSULTING = gql`
  mutation UpdateConsulting(
    $data: ConsultingWhereInput
    $where: ConsultingWhereInputId!
  ) {
    updateConsulting(data: $data, where: $where) {
      id
      userId
      firstResponderId
      mentorId
    }
  }
`;

export const CONSULTINGS = gql`
  query consultings($where: ConsultingWhereInput, $skip: Int, $limit: Int) {
    consultings(where: $where, skip: $skip, limit: $limit) {
      total
      data {
        id
        userId
        userName
        firstResponderId
        mentorId
        consultingCategoryId
        mainConsultingId
        status
        appointmentDate
        appointmentAddress
        followUp
        detail
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const COMMENTS = gql`
  query comments($where: CommentWhereInput, $skip: Int, $limit: Int) {
    comments(where: $where, skip: $skip, limit: $limit) {
      total
      data {
        id
        consultingId
        detail
        createdAt
        createdBy
        updatedAt
        updatedBy
        note
      }
    }
  }
`;
export const CONSULTING = gql`
  query Consulting($where: ConsultingWhereInputId!) {
    consulting(where: $where) {
      id
      userId
      firstResponderId
      mentorId
      consultingCategoryId
      mainConsultingId
      status
      appointmentDate
      appointmentAddress
      followUp
      detail
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const CONSULTING_CATEGORYS = gql`
  query ConsultingCategorys($where: ConsultingCategorysWhereInput, $skip: Int) {
    consultingCategorys(where: $where, skip: $skip) {
      total
      data {
        id
        name
        detail
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const USERS = gql`
  query Users($where: UserWhereInput, $limit: Int, $skip: Int) {
    users(where: $where, limit: $limit, skip: $skip) {
      data {
        id
        firstName
        lastName
        status
        workingStatus
      }
    }
  }
`;
export const USER = gql`
  query User($where: UserWhereInputId!) {
    user(where: $where) {
      id
      role
      firstName
      lastName
    }
  }
`;
export const DELETE_COMMENT = gql`
  mutation DeleteComment($where: CommentWhereInputId!) {
    deleteComment(where: $where) {
      id
    }
  }
`;
export const DELETE_CONSULTING = gql`
  mutation DeleteConsulting($where: ConsultingWhereInputId!) {
    deleteConsulting(where: $where) {
      id
    }
  }
`;
