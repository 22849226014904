import React, { useState, useRef, useEffect } from "react";
import useReactRouter from "use-react-router";
import Image from "react-bootstrap/Image";
import ImageLogo from "../../../image/profile.png";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import {
  USER,
  CONSULTING_CATEGORYS,
  USERS,
  COMPANYS,
  DELETE_USER,
  CONSULTINGS,
} from "../apollo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import {
  faHistory,
  faUser,
  faUserGraduate,
  faUserTie,
  faFileAlt,
  faEdit,
  faTrash,
  faUsers,
  faPlus,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  TITLE_HEADER,
  HEADER,
  BODY_DSTAFF_DETAIL,
  URL_FOR_SHOW_PHOTO,
  USER_KEY
} from "../../../consts";
import {
  MENTOR_LIST,
  MENTOR_EDIT,
  COUNSELING_LIST,
  COUNSELING_DETAIL,
  MENTOR_DETAIL,
} from "../../../consts/router";
import {
  formatDate,
  setGenders,
  educationLevel,
  workingStatus,
  formatDateTime,
  _provincelevel,
} from "../../../helper/super";

export default function MentorDetail() {
  const { history, location, match } = useReactRouter();
  const { loading, error, data } = useQuery(USER, {
    variables: { where: { id: match?.params?.id } },
  });
  const {
    loading: companyLoading,
    error: companyError,
    data: companyData,
  } = useQuery(COMPANYS);
  const {
    loading: consultingLoading,
    error: consultingError,
    data: consultingData,
  } = useQuery(CONSULTING_CATEGORYS);
  const {
    loading: dataUserLoading,
    error: dataUserError,
    data: dataUser,
  } = useQuery(USERS);
  const {
    loading: consultingSLoading,
    error: consultingSError,
    data: consultingSData,
  } = useQuery(CONSULTINGS, {
    variables: { where: { mentorId: match?.params?.id } },
  });
  const [deteleUser, setDeteleUser] = useMutation(DELETE_USER);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [updateStatut, setUpdateStatut] = useState(false);
  const handleCloseUpdate = () => setUpdateStatut(false);
  const handleShowUpdate = () => setUpdateStatut(true);
  // history
  const _MentorList = () => {
    history.push(`${MENTOR_LIST}/limit/20/skip/0`);
  };
  const _MentorEdit = () => {
    // history.push(`${MENTOR_EDIT}/${match?.params?.id}`, match?.params?.id);
    history.push({
      pathname: `${MENTOR_EDIT}/${match?.params?.id}`,
      state: { data: location?.state?.data }
    });
  };
  const _MentorAdd = () => {
    history.push(`${COUNSELING_LIST}/limit/20/skip/0`, match?.params?.id);
  };
  const _conseliDetail = (id) => {
    history.push(`${COUNSELING_DETAIL}/${id}`, id);
  };
  const _renderCategory = (e) => {
    let res = consultingData?.consultingCategorys?.data?.filter(
      (x) => x.id === e
    );
    if (res?.length > 0) return res[0]?.name;
  };
  const _renderName = (e) => {
    let res = dataUser?.users?.data?.filter((x) => x.id === e);
    if (res?.length > 0) {
      return res[0]?.firstName + " " + res[0]?.lastName;
    } else {
      return "-";
    }
  };
  const _renderCompany = (e) => {
    let res = companyData?.companys?.data?.filter((x) => x.id === e);
    if (res?.length > 0) {
      return res[0]?.companyName;
    } else {
      return "-";
    }
  };
  const _confrimeDelete = async () => {
    await deteleUser({ variables: { where: { id: match?.params?.id } } }).then(
      Swal.fire({
        icon: "success",
        title: "ການລຶບສຳເລັດ",
        showConfirmButton: false,
        timer: 1500,
      }).then(function () {
        history.push(`${MENTOR_LIST}/limit/20/skip/0`);
        window.location.reload(true);
      })
    );
  };
  const _status = (e) => {
    if (e === "ONGOING_FR") {
      return <div style={{ color: "#F29702" }}>ONGOING_FR</div>;
    } else if (e === "ONGOING_MT") {
      return <div style={{ color: "#F29702" }}>ONGOING_MT</div>;
    } else if (e === "COMPLETED") {
      return <div style={{ color: "#48BB78" }}>COMPLETED</div>;
    } else if (e === "CANCELED") {
      return <div style={{ color: "red" }}>CANCELED</div>;
    }
  };
  const [userData, setUserData] = useState({});
  useEffect(() => {
    const _resData = localStorage.getItem(USER_KEY);
    const _localJson = JSON.parse(_resData);
    setUserData(_localJson);
  }, []);
  let check = userData?.loginUser?.data?.role;


  const _onGoBackIds = () => {
    let currentId = match?.params?.id;
    let allIds = location?.state?.data;
    let currentIndexInArray = allIds.indexOf(currentId);
    if (currentIndexInArray > 0) {
      let nextConsultingId = allIds[currentIndexInArray - 1];
      history.push({
        pathname: `${MENTOR_DETAIL}/${nextConsultingId}`,
        state: { data: allIds }
      });
    } else {
      let nextId = allIds.length - 1;
      history.push({
        pathname: `${MENTOR_DETAIL}/${allIds[nextId]}`,
        state: { data: allIds }
      });
    }
  }

  const _onGoNextIds = () => {
    let currentId = match?.params?.id;
    let allIds = location?.state?.data;
    let currentIndexInArray = allIds.indexOf(currentId);
    if ((currentIndexInArray + 1)  < allIds.length) {
      let nextConsultingId = allIds[currentIndexInArray + 1];
      history.push({
        pathname: `${MENTOR_DETAIL}/${nextConsultingId}`,
        state: { data: allIds }
      });
    } else {
      let nextId = 0;
      history.push({
        pathname: `${MENTOR_DETAIL}/${allIds[nextId]}`,
        state: { data: allIds }
      });
    }
  }
  return (
    <div
      style={{
        paddingRight: 10,
        paddingLeft: 50,
        backgroundColor: "#F9F9F9",
        width: "100%",
        height: "100vh",
        overflowY: "scroll",
      }}
    >
      {/* Next and Back b uttons are over here */}
      <div style={{
        position: 'fixed',
        left: 100,
        zIndex: 1,
        top: "50%",
        display: location?.state?.data ? 'flex' :'none'
      }}>
        <button onClick={() => _onGoBackIds()} className="btn btn-outline-primary btn-stella" style={{
          borderRadius: 50,
          width: 50,
          height: 50
        }}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
      </div>
      <div style={{
        position: 'fixed',
        right: 45,
        zIndex: 1,
        top: "50%",
        display: location?.state?.data ? 'flex' :'none'
      }}>
        <button onClick={() => _onGoNextIds()} className="btn btn-outline-primary btn-stella" style={{
          borderRadius: 50,
          width: 50,
          height: 50
        }}>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
      <h6
        style={{
          color: "#5C5C5C",
          backgroundColor: "#F9F9F9",
          height: 60,
          width: "100%",
          paddingTop: 30,
          paddingLeft: 35,
          //fontSize: FONT_HEADER_SIZE,
        }}
      >
        <div className="container-fluid">
          <div className="row" style={{ marginRight: 8 }}>
            <div className="col-md-6">
              <a
                href=""
                onClick={() => _MentorList()}
                style={{ color: "#766AF0" }}
              >
                ລາຍຊື່ Mentor »{" "}
              </a>
              ຂໍ້ມູນ Mentor »{" "}
              {data?.user?.firstName ? data?.user?.firstName : "-"}{" "}
              {data?.user?.lastName ? data?.user?.lastName : "-"}
            </div>
            <div className="col-md-6" style={{ textAlign: "right" }}>
              {check === "ADMIN" &&
                <button
                  type="button"
                  class="btn btn-outline-danger col-md-3 dlt"
                  onClick={handleShow}
                >
                  <FontAwesomeIcon icon={faTrash} style={{ marginRight: 10 }} />{" "}
                  ລຶບ
                </button>
              }
              {check === "ADMIN" &&
                <button
                  type="button"
                  className="btn  btn-edit appcolor col-md-3"
                  style={{ border: "solid 0px" }}
                  onClick={() => _MentorEdit()}
                >
                  <FontAwesomeIcon icon={faEdit} style={{ marginRight: 10 }} />{" "}
                  ແກ້ໄຂ
                </button>
              }
              {check === "ADMIN" &&
                <button
                  type="button"
                  class="btn btn-secondary"
                  style={{ backgroundColor: "#766AF0", border: "solid 0px" }}
                  onClick={() => _MentorAdd()}
                >
                  <FontAwesomeIcon icon={faPlus} style={{ marginRight: 10 }} />{" "}
                  ເພີ່ມການປຶກສາ
                </button>
              }
            </div>
          </div>
        </div>
      </h6>

      <div
        className="row"
        style={{ paddingTop: 20, paddingLeft: 50, width: "100%" }}
      >
        <div
          style={{
            backgroundColor: "#FFFFFF",
            height: 300,
            width: "51%",
            borderRadius: 8,
            marginLeft: 19,
          }}
        >
          <p style={{ padding: 20, marginLeft: 50 }}>ຂໍ້ມູນ Mentor</p>
          <div className="row">
            {data?.user?.image ? (
              <div className="col-md-5 center">
                <Image
                  src={URL_FOR_SHOW_PHOTO + data?.user?.image}
                  alt="stellaIcon"
                  style={{
                    height: 200,
                    width: 200,
                    padding: 5,
                    border: "solid 1px #766AF0",
                    borderRadius: "50%",
                  }}
                />
              </div>
            ) : (
              <div className="col-md-5 center">
                <Image
                  src={ImageLogo + data?.user?.image}
                  alt="stellaIcon"
                  style={{
                    height: 200,
                    width: 200,
                    padding: 5,
                    border: "solid 1px #766AF0",
                    borderRadius: "50%",
                  }}
                />
              </div>
            )}

            <div className="col-md-7">
              <div className="row">
                <div className="col-6" style={{ color: "#646464" }}>
                  ຊື່ ແລະ ນາມສະກຸນ
                </div>
                <div className="col-6" style={{ color: "#868686" }}>
                  {data?.user?.firstName ? data?.user?.firstName : "-"}{" "}
                  {data?.user?.lastName ? data?.user?.lastName : "-"}
                </div>
              </div>
              <div style={{ height: 10 }}></div>
              <div className="row">
                <div className="col-6" style={{ color: "#646464" }}>
                  ເບີໂທ:
                </div>
                <div className="col-6" style={{ color: "#868686" }}>
                  {data?.user?.phone ? data?.user?.phone : "-"}
                </div>
              </div>
              <div style={{ height: 10 }}></div>
              <div className="row">
                <div className="col-12" style={{ color: "#646464" }}>
                  ໝວດໃຫ້ປຶກສາ:
                  <div
                    style={{
                      display: "flex",
                      fload: "right",
                    }}
                  >
                    {data?.user?.canCounseling?.map((data, index) => {
                      return (
                        <div
                          style={{
                            padding: 5,
                            backgroundColor: "#EC6869",
                            color: "#FFFF",
                            borderRadius: 8,
                            float: "right",
                            marginRight: 7,
                          }}
                        >
                          {_renderCategory(data?.id)}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ margin: 8 }}></div>
            <div
              style={{
                paddingTop: 40,

                width: "96%",
              }}
            >
              <div
                style={{
                  backgroundColor: "#FFFF",
                  padding: 20,
                  borderRadius: 8,
                }}
              >
                <div className="row">
                  <div
                    className="col-12"
                    style={{
                      color: "#646464",
                      fontWeight: "bold",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{ marginRight: 10, color: "#766AF0" }}
                    />
                    ຂໍ້ມູນທົ່ວໄປ
                  </div>
                </div>
                <div style={{ height: 10 }}></div>
                <div style={{ paddingLeft: 30 }}>
                  <div className="row">
                    <div className="col-6" style={{ color: "#646464" }}>
                      ວັນເກີດ
                    </div>
                    <div className="col-6" style={{ color: "#868686" }}>
                      {formatDate(data?.user?.birthday)} ( {data?.user?.fixAge} )
                    </div>
                  </div>
                  <div style={{ height: 20 }}></div>
                  <div className="row">
                    <div className="col-6" style={{ color: "#646464" }}>
                      ເພດ
                    </div>
                    <div className="col-6" style={{ color: "#868686" }}>
                      {setGenders(data?.user?.gender)}
                    </div>
                  </div>
                  <div style={{ height: 20 }}></div>
                  <div className="row">
                    <div className="col-6" style={{ color: "#646464" }}>
                      ຊົນເຜົ່າ
                    </div>
                    <div className="col-6" style={{ color: "#868686" }}>
                      {data?.user?.tribe}
                    </div>
                  </div>
                  <div style={{ height: 20 }}></div>
                  <div className="row">
                    <div className="col-6" style={{ color: "#646464" }}>
                      ສາດສະໜາ
                    </div>
                    <div className="col-6" style={{ color: "#868686" }}>
                      {data?.user?.religion}
                    </div>
                  </div>
                  <div style={{ height: 20 }}></div>
                  <div className="row">
                    <div className="col-6" style={{ color: "#646464" }}>
                      ທີ່ຢູ່ປະຈຸບັນ
                    </div>
                    <div className="col-6" style={{ color: "#868686" }}>
                      {data?.user?.address?.village +
                        " " +
                        data?.user?.address?.district +
                        " " +
                        _provincelevel(data?.user?.address?.province)}
                    </div>
                  </div>
                  <div style={{ height: 20 }}></div>
                  <div className="row">
                    {/* <div className="col-6" style={{ color: "#646464" }}>
                        ແຂວງເກີດ
                      </div>
                      <div className="col-6" style={{ color: "#868686" }}>
                        {_provincelevel(data?.user?.provinceBorn)}
                      </div> */}
                  </div>
                </div>
                <div style={{ height: 20 }}></div>
                <div className="row">
                  <div
                    className="col-12"
                    style={{ color: "#646464", fontWeight: "bolder" }}
                  >
                    <FontAwesomeIcon
                      icon={faUserGraduate}
                      style={{ marginRight: 10, color: "#766AF0" }}
                    />
                    ຂໍ້ມູນການສຶກສາ
                  </div>
                </div>
                <div style={{ height: 10 }}></div>
                <div style={{ paddingLeft: 30 }}>
                  <div className="row">
                    <div className="col-6" style={{ color: "#646464" }}>
                      ລະດັບການສຶກສາ
                    </div>
                    <div className="col-6" style={{ color: "#868686" }}>
                      {educationLevel(data?.user?.educationLevel)}
                    </div>
                  </div>
                  <div style={{ height: 20 }}></div>
                  <div className="row">
                    <div className="col-6" style={{ color: "#646464" }}>
                      ສາຂາຮຽນ
                    </div>
                    <div className="col-6" style={{ color: "#868686" }}>
                      {data?.user?.branch}
                    </div>
                  </div>
                </div>
                <div style={{ height: 20 }}></div>
                <div className="row">
                  <div
                    className="col-12"
                    style={{ color: "#646464", fontWeight: "bolder" }}
                  >
                    <FontAwesomeIcon
                      icon={faUserTie}
                      style={{ marginRight: 10, color: "#766AF0" }}
                    />
                    ຂໍ້ມູນການເຮັດວຽກ
                  </div>
                </div>
                <div style={{ height: 10 }}></div>
                <div style={{ paddingLeft: 30 }}>
                  <div className="row">
                    <div className="col-6" style={{ color: "#646464" }}>
                      ສະຖານະ
                    </div>
                    <div className="col-6" style={{ color: "#868686" }}>
                      {workingStatus(data?.user?.workingStatus)}
                    </div>
                  </div>
                  <div style={{ height: 20 }}></div>
                  <div className="row">
                    <div className="col-6" style={{ color: "#646464" }}>
                      ບໍລິສັດ
                    </div>
                    <div className="col-6" style={{ color: "#766AF0" }}>
                      {_renderCompany(data?.user?.company)}
                    </div>
                  </div>
                </div>
                <div style={{ height: 20 }}></div>
                <div className="row">
                  <div
                    className="col-12"
                    style={{ color: "#646464", fontWeight: "bolder" }}
                  >
                    <FontAwesomeIcon
                      icon={faFileAlt}
                      style={{ marginRight: 10, color: "#766AF0" }}
                    />
                    ຂໍ້ມູນເພີ່ມເຕີມ
                  </div>
                </div>
                <div style={{ height: 10 }}></div>
                <div style={{ paddingLeft: 30 }}>
                  <div className="row">
                    <div className="col-6" style={{ color: "#646464" }}>
                      ຄຳອະທິບາຍ
                    </div>
                    <div className="col-6" style={{ color: "#868686" }}>
                      {data?.user?.detail}
                    </div>
                  </div>
                  <div style={{ height: 20 }}></div>
                  <div className="row">
                    <div className="col-6" style={{ color: "#646464" }}>
                      ໝາຍເຫດ
                    </div>
                    <div className="col-6" style={{ color: "#766AF0" }}>
                      {data?.user?.note}
                    </div>
                  </div>
                  <div style={{ height: 30 }}></div>
                  <div className="row">
                    <div className="col-6" style={{ color: "#646464" }}>
                      ສ້າງໂດຍ
                    </div>
                    <div className="col-6" style={{ color: "#868686" }}>
                      {_renderName(data?.user?.createdBy)}
                    </div>
                  </div>
                  <div style={{ height: 20 }}></div>
                  <div className="row">
                    <div className="col-6" style={{ color: "#646464" }}>
                      ແກ້ໄຂໂດຍ
                    </div>
                    <div className="col-6" style={{ color: "#868686" }}>
                      {_renderName(data?.user?.updatedBy)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: 100, width: "2%" }}></div>
        <div
          style={{ backgroundColor: "#FFFFFF", width: "45%", borderRadius: 8 }}
        >
          <div className="row">
            <div
              className="col-sm-7"
              style={{ marginLeft: 20, paddingTop: 20 }}
            >
              {" "}
              <FontAwesomeIcon
                icon={faHistory}
                style={{ marginRight: 10, color: "#766AF0" }}
              />
              ປະຫວັດການປືກສາ
            </div>
          </div>
          <div className="col-md-12" style={{ marginTop: 30 }}>
            <table class="table" style={{ color: "#646464" }}>
              <thead>
                <tr>
                  <th>User</th>
                  <th>FirstResponder</th>
                  <th>ໝວດ</th>
                  <th>ສະຖານະ</th>
                  <th>ມື້ນັດໝາຍ</th>
                </tr>
              </thead>
              <tbody>
                {consultingSData?.consultings?.data?.map((item, index) => {
                  return (
                    <tr onClick={() => _conseliDetail(item?.id)}>
                      <th>{_renderName(item?.userId)}</th>
                      <td> {_renderName(item?.firstResponderId)} </td>
                      <td>
                        <div
                          style={{
                            border: "solid 1px",
                            backgroundColor: "#EC6869",
                            color: "#FFFF",
                            textAlign: "center",
                            borderRadius: 5,
                          }}
                        >
                          {_renderCategory(item?.consultingCategoryId)}
                        </div>
                      </td>
                      <td style={{ color: "#F29702" }}>
                        {_status(item?.status)}
                      </td>
                      <td>{formatDateTime(item?.appointmentDate)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>ລຶບ Mentor</Modal.Header>
        <Modal.Body>
          <div className="row center">
            <div>ທ່ານຕ້ອງການລຶບ Mentor </div>
            <div style={{ color: "red", paddingLeft: 10, paddingRight: 10 }}>
              {data?.user?.firstName}
            </div>
            <div>ນິ້ບໍ ?</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            ຍົກເລິກ
          </Button>
          <Button
            style={{ backgroundColor: "#766AF0" }}
            onClick={() => _confrimeDelete()}
          >
            ຢຶນຢັນການລຶບ
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
